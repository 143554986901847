// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicFormTextTableCell,
  DefaultFormTextTableCellProps
} from "./plasmic/imbas_23_fpre/PlasmicFormTextTableCell";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface FormTextTableCellProps extends DefaultFormTextTableCellProps {}

function FormTextTableCell_(
  props: FormTextTableCellProps,
  ref: HTMLElementRefOf<"div">
) {
  return <PlasmicFormTextTableCell root={{ ref }} {...props} />;
}

const FormTextTableCell = React.forwardRef(FormTextTableCell_);
export default FormTextTableCell;
