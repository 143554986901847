import * as React from "react";
import { DefaultButtonProps, PlasmicButton } from "./plasmic/imbas_23_fpre/PlasmicButton";
import { ButtonRef } from "@plasmicapp/react-web";
import { getLoadingButton } from "../utils";

export interface ButtonProps extends DefaultButtonProps {
  loaderColor?: string;
}

function Button_({ loaderColor, ...props }: ButtonProps, ref: ButtonRef) {

  const { plasmicProps } = PlasmicButton.useBehavior<ButtonProps>({
    ...props
  }, ref);

  if (props.isLoading) {
    return <PlasmicButton
      {...plasmicProps}
      loaderIconContainer={getLoadingButton("loading...", loaderColor)}
      isDisabled={props.isLoading ? true : false}
    />;
  }
  return <PlasmicButton {...plasmicProps} />;
}

const Button = React.forwardRef(Button_);

export default Object.assign(Button, {
  __plumeType: "button"
});
