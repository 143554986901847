// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DocFolderIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DocFolderIcon(props: DocFolderIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.333 5.333h-12A.667.667 0 002.667 6v7.333a.667.667 0 11-1.334 0V2H4.31l1.133 1.703A.667.667 0 006 4h6.667v.667H14V3.333a.666.666 0 00-.667-.666H6.357L5.223.963a.667.667 0 00-.556-.296h-4A.667.667 0 000 1.333v12a2.008 2.008 0 002 2h12a2.008 2.008 0 002-2V6a.666.666 0 00-.667-.667z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default DocFolderIcon;
/* prettier-ignore-end */
