import * as React from "react";
import {
  PlasmicAdminPage,
  DefaultAdminPageProps
} from "./plasmic/imbas_23_fpre/PlasmicAdminPage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface AdminPageProps extends DefaultAdminPageProps {}

function AdminPage_(props: AdminPageProps, ref: HTMLElementRefOf<"div">) {

  return (
      <PlasmicAdminPage 
        root={{ ref }} 
        {...props }
      />    
  );
}

const AdminPage = React.forwardRef(AdminPage_);
export default AdminPage;

