import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
}
  from "react";
import useMatchAny from "../hooks/useMatchAny";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { getObjectInfo, ObjectInfo } from "../api/endpoints/objectInfo";

export const ObjectInfoContext = createContext<{
  objectInfo: ObjectInfo | undefined,
  setObjectInfo: Dispatch<SetStateAction<ObjectInfo | undefined>>,
}>({
  objectInfo: undefined,
  setObjectInfo: () => undefined
});

export const useObjectInfoContext = () => useContext(ObjectInfoContext);

export const ObjectInfoProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [objectInfo, setObjectInfo] = useState<ObjectInfo | undefined>(undefined);

  const paths = useMemo(() => ApplicationRoutes
    .map((route) => route.path)
    .filter((path) => path.includes(":objectId")), []);
  const objectId = useMatchAny(paths)?.params.objectId;

  useEffect(() => {
    if (objectId) {
      getObjectInfo(objectId).then(({ data }) => setObjectInfo(data));
    } else {
      setObjectInfo(undefined);
    }
  }, [objectId]);

  return (
    <ObjectInfoContext.Provider value={{ objectInfo, setObjectInfo }}>
      {children}
    </ObjectInfoContext.Provider>
  );
};

export default ObjectInfoContext;
