import * as React from "react";
import {
  PlasmicDynamicRenovationList,
  DefaultDynamicRenovationListProps
} from "./plasmic/imbas_23_fpre/PlasmicDynamicRenovationList";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import DynamicRenovationListItem from "./DynamicRenovationListItem";
import { useFieldArray, useFormContext } from "react-hook-form";

export type RenovationCategory = "GEBREN" | "REN";

export interface DynamicRenovationListProps 
  extends DefaultDynamicRenovationListProps {
    renovationCategory: RenovationCategory,
    renovationFactory: (value: number) => object    
  }

function DynamicRenovationList_(
    { renovationCategory, renovationFactory, ...rest }: DynamicRenovationListProps,  
    ref: HTMLElementRefOf<"div">) 
{
  const maxItems = 10;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
                                                      control,
                                                      name: `${renovationCategory}s`
                                                    });

  return ( 
  <PlasmicDynamicRenovationList 
    root={{ ref }} 
    {...rest}
    renovationItems={{
      children: fields.map((item, index) => 
      {
        const currentKey = index;    
        return (   
          <DynamicRenovationListItem 
            key={item.id}
            index={currentKey}
            renoType={renovationCategory}
            onDelete={() => remove(index)}
          />
        )
      })
    }}

    isEmpty={fields.length === 0}

    itemsCounter= {{
      maxItemsReached:  fields.length >= maxItems,
      counterText: `${fields.length }/${maxItems}`
    }}    

    addRenovation={{
      name: `${renovationCategory}A`,
      isDisabled: fields.length >= maxItems,
      onChange: (value) => {    
        const newRenovation = renovationFactory(value as number); 
        append(newRenovation);        
      }
    }}
  />
  );
}

const DynamicRenovationList = React.forwardRef(DynamicRenovationList_);
export default DynamicRenovationList;
