import React, { useEffect, useState } from "react";
import {
  DefaultMarktwertTableRowProps,
  PlasmicMarktwertTableRow
} from "./plasmic/imbas_23_fpre/PlasmicMarktwertTableRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useDataStore } from "../store/DataStoreProvider";
import { get } from "lodash";

export interface MarktwertTableRowProps extends DefaultMarktwertTableRowProps {
}

function MarktwertTableRow_(
  props: MarktwertTableRowProps,
  ref: HTMLElementRefOf<"div">
) {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const { dataStore } = useDataStore();

  useEffect(() => {
    if (!dataStore || !props.visibilityFieldName) return;

    setIsVisible(get(dataStore, props.visibilityFieldName, true));
  }, [dataStore, props.visibilityFieldName]);

  return (
    <>
      {isVisible && <PlasmicMarktwertTableRow root={{ ref }} {...props} />}
    </>
  );
}

const MarktwertTableRow = React.forwardRef(MarktwertTableRow_);
export default MarktwertTableRow;
