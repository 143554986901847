import React, { createContext, PropsWithChildren, useCallback, useContext, useState, } from "react";
import { AxiosResponse } from "axios";
import { useDebouncedCallback } from "use-debounce";
import { DataStoreName, JSONSerializable, useDataStore, } from "./DataStoreProvider";
import { createHOC } from "../utils";

const AUTO_SAVE_DELAY_MS = 1000;

export enum AutoSaveState {
  SAVED = "SAVED",
  DIRTY = "DIRTY",
  SAVING = "SAVING",
  ERROR = "ERROR",
}

interface AutosaveProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiCall: (data: JSONSerializable) => Promise<AxiosResponse<any>>;
  autosaveDataStore?: DataStoreName;
  userInputDataStore?: DataStoreName;
}

interface AutosaveContextValue {
  persist: (data: JSONSerializable) => void;
  state: AutoSaveState;
}

export const AutosaveContext = createContext<AutosaveContextValue>(
  {} as AutosaveContextValue
);

export const useAutosaveContext = () => useContext(AutosaveContext);

const AutosaveProvider: React.FC<PropsWithChildren<AutosaveProviderProps>> = (
  { apiCall, autosaveDataStore, userInputDataStore, children }
) => {
  const [state, setState] = useState(AutoSaveState.SAVED);
  const { setDataStore } = useDataStore();

  const debouncedAutosave = useDebouncedCallback(
    (data: JSONSerializable) => {
      setState(AutoSaveState.SAVING);
      apiCall(data)
        .then((response) => {
          setDataStore((prevState) => ({
            ...prevState,
            ...(autosaveDataStore ? { [autosaveDataStore]: response.data } : {}),
            ...(userInputDataStore ? { [userInputDataStore]: data } : {}),
          }));
          setState(AutoSaveState.SAVED);
        })
        .catch(() => setState(AutoSaveState.ERROR));
    },
    AUTO_SAVE_DELAY_MS
  );

  const persist = useCallback(
    (data: JSONSerializable) => {
      setState(AutoSaveState.DIRTY);
      debouncedAutosave(data);
    },
    [debouncedAutosave]
  );

  return (
    <AutosaveContext.Provider value={{ persist, state }}>
      {children}
    </AutosaveContext.Provider>
  );
}

export default AutosaveProvider;

export const withAutosaveProvider = createHOC(AutosaveProvider);
