import React, { useEffect, useRef, useState } from "react";
import {
  DefaultAutocompleteTextInputProps,
  PlasmicAutocompleteTextInput
} from "./plasmic/imbas_23_fpre/PlasmicAutocompleteTextInput";
import { HTMLElementRefOf, TextInputRefValue } from "@plasmicapp/react-web";
import AutocompleteResult from "./AutocompleteResult";

export type AutocompleteResultDTO = { label: string, onClick: () => void, isRegion?: boolean };

export interface AutocompleteTextInputProps extends Omit<DefaultAutocompleteTextInputProps, "results"> {
  results: Array<AutocompleteResultDTO>;
  value: string | null;
  onChange: (value: AutocompleteTextInputProps["value"]) => void;
}

function AutocompleteTextInput_(
  { onChange, value, results, ...props }: AutocompleteTextInputProps,
  ref: HTMLElementRefOf<"div">
) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDropdownResult, setSelectedDropdownResult] = useState<number | null>(null);
  const textInputRef = useRef<TextInputRefValue>(null);

  useEffect(() => {
    setIsOpen(results.length > 0)
    setSelectedDropdownResult(null);
  }, [results]);

  const handleDropdownSelection = (result: AutocompleteResultDTO): void => {
    setIsOpen(false);
    textInputRef.current?.blur();
    result.onClick();
  };

  const handleDropdownNavigation = (e: React.KeyboardEvent): void => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedDropdownResult((prev) =>
        results.length > 0
          ? (prev === null || prev === results.length - 1) ? 0 : prev + 1
          : null
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedDropdownResult((prev) =>
        results.length > 0
          ? (prev === null || prev === 0) ? results.length - 1 : prev - 1
          : null
      );
    } else if (e.key === "Enter") {
      e.preventDefault();
      selectedDropdownResult !== null && handleDropdownSelection(results[selectedDropdownResult]);
    }
  };

  const autocompleteResults = results.map((result, idx) => (
    <AutocompleteResult
      key={idx}
      isSelected={selectedDropdownResult === idx}
      onMouseMove={() => setSelectedDropdownResult(idx)}
      onClick={() => handleDropdownSelection(result)}
      isRegion={result.isRegion}
    >
      {result.label}
    </AutocompleteResult>
  ));

  return (
    <PlasmicAutocompleteTextInput
      root={{ ref }}
      {...props}
      textInput={{
        value,
        onChange,
        onFocus: () => setIsOpen(results.length > 0),
        onBlur: () => {
          setIsOpen(false);
          setSelectedDropdownResult(null);
        },
        onKeyDown: handleDropdownNavigation,
        ref: textInputRef,
      }}
      dropdown={{ onMouseDown: (e) => e.preventDefault() }}
      results={autocompleteResults}
      isOpen={isOpen}
    />
  );
}

const AutocompleteTextInput = React.forwardRef(AutocompleteTextInput_);
export default AutocompleteTextInput;
