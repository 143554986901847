import React, { cloneElement, HTMLAttributes, ReactElement } from "react";
import {
  DefaultSingleSelectButtonGroupProps,
  PlasmicSingleSelectButtonGroup
} from "./plasmic/imbas_23_fpre/PlasmicSingleSelectButtonGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import PlasmicButton, { DefaultButtonProps } from "./plasmic/imbas_23_fpre/PlasmicButton";
import { getValOfPlasmicComponent } from "../utils";
import { useDynamicDropdownValues } from "../context/DynamicDropdownValuesContext";
import { ButtonProps } from "./Button";
import SelectButton from "./SelectButton";
import { useTranslation } from "react-i18next";
import { useDataStore } from "../store/DataStoreProvider";
import { get } from "lodash";

export interface SingleSelectButtonGroupProps extends Omit<DefaultSingleSelectButtonGroupProps, "children"> {
  children?: Iterable<ReactElement<DefaultButtonProps, typeof PlasmicButton>>;
  name?: string;
  value: number | null;
  onChange?: (value: SingleSelectButtonGroupProps["value"]) => void;
}

function SingleSelectButtonGroup_(
  { children, value, onChange, name, ...props }: SingleSelectButtonGroupProps,
  ref: HTMLElementRefOf<"div">
) {
  const { t } = useTranslation();

  const getButtonProps = (buttonValue: number, key: string | number): ButtonProps => ({
    onClick: () => onChange?.(value === buttonValue ? null : buttonValue),
    buttonType: value === buttonValue ? "solid" : "soft",
    value: buttonValue.toString(),
    key,
    type: "button"
  });

  const { dataStore } = useDataStore();
  const algoValue = props.algoName && dataStore 
    ? get(dataStore, props.algoName ?? undefined)
    : undefined;

  const dynamicButtonGroup = useDynamicDropdownValues(name, (item, idx) => (
    <SelectButton 
      isAlgo={algoValue === item.Value} 
      {...getButtonProps(Number(item.Value), idx)} 
      text={t(item.Name, { ns: "plasmic" })}
    />
  ));

  // Override default Plasmic children when dynamic dropdown values are available
  const buttonGroup = dynamicButtonGroup ?? Array.from(children ?? []).map((child, idx) => {
    const buttonValue = Number(getValOfPlasmicComponent(child));
    return cloneElement(child, { ...getButtonProps(buttonValue, idx) });
  });

  return (
    <PlasmicSingleSelectButtonGroup
      root={{ ref }}
      {...props}
      {...({ name } as unknown as HTMLAttributes<HTMLDivElement>)}
    >
      {buttonGroup}
    </PlasmicSingleSelectButtonGroup>
  );
}

const SingleSelectButtonGroup = React.forwardRef(SingleSelectButtonGroup_);
export default SingleSelectButtonGroup;
