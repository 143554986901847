import React from "react";
import {
  DefaultAutocompleteResultProps,
  PlasmicAutocompleteResult
} from "./plasmic/imbas_23_fpre/PlasmicAutocompleteResult";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface AutocompleteResultProps extends DefaultAutocompleteResultProps {
  onClick?: () => void;
  onMouseMove?: () => void;
}

function AutocompleteResult_(
  props: AutocompleteResultProps,
  ref: HTMLElementRefOf<"div">
) {
  return <PlasmicAutocompleteResult root={{ ref }} {...props} />;
}

const AutocompleteResult = React.forwardRef(AutocompleteResult_);
export default AutocompleteResult;
