// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type HousePricingIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function HousePricingIcon(props: HousePricingIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M7.293.293a.999.999 0 011.414 0l7 7A1 1 0 0115 9h-1v6a1 1 0 01-1 1H3a1 1 0 01-1-1V9H1a.999.999 0 01-.707-1.707l7-7zM12 10.667L10.805 9.47 8 12.276l-1.333-1.333-2 2L3.724 12l2.943-2.943L8 10.391l1.862-1.862-1.195-1.196H12v3.334z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default HousePricingIcon;
/* prettier-ignore-end */
