import React, { useEffect, useState } from "react";
import { DefaultProcessTopNavProps, PlasmicProcessTopNav } from "./plasmic/imbas_23_fpre/PlasmicProcessTopNav";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useObjectInfoContext } from "../context/ObjectInfoContext";
import { parseLocationString } from "../utils";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { useRegionContext } from "../context/RegionContext";
import { useDynamicDropdownValue } from "../context/DynamicDropdownValuesContext";

export interface ProcessTopNavProps extends DefaultProcessTopNavProps {
}

function ProcessTopNav_(
  props: ProcessTopNavProps,
  ref: HTMLElementRefOf<"div">
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { objectInfo } = useObjectInfoContext();
  const { setLocation, setCountry } = useRegionContext();
  const [address, setAddress] = useState<string>();

  useEffect(() => {
    setAddress(parseLocationString(objectInfo?.Location));
    if (objectInfo && objectInfo.Location && objectInfo.Location.LAND !== undefined) {
      setLocation(objectInfo.Location);
      setCountry(objectInfo.Location.LAND);
    }
  }, [objectInfo]);

  const dynamicDropdownValue = useDynamicDropdownValue("NUTZ", objectInfo?.NUTZ ?? 0)?.Name;
  return (
    <PlasmicProcessTopNav
      root={{ ref }}
      {...props}
      nutz={t(dynamicDropdownValue ?? "", { ns: "plasmic" })}
      address={address}
      backButton={{
        onClick: () => navigate(ApplicationRoutes.getPath("root"))
      }}
      showAddressEditButton={location.pathname.includes("erfassung")}
    />
  );
}

const ProcessTopNav = React.forwardRef(ProcessTopNav_);
export default ProcessTopNav;
