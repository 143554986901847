import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

const DebugContext = createContext<{
    isDebugMode : boolean,
    setIsDebugMode: Dispatch<SetStateAction<boolean>>,
  }>({
    isDebugMode: false,
    setIsDebugMode: () => undefined
  });

export const useDebugContext = () => useContext(DebugContext);

const DebugProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isDebugMode, setIsDebugMode] = useState(false);

    return (
      <DebugContext.Provider value={{ isDebugMode, setIsDebugMode }}>
        {children}
      </DebugContext.Provider>
    );
  };
  
  export default DebugProvider;