import { useCallback, useState } from "react";

export enum State {
  Idle = "idle",
  Pending = "pending",
  Error = "error",
  Finished = "finished",
}

export default function useLoadingState<T>() {
  const [state, setState] = useState<State>(State.Idle);
  const [error, setError] = useState<Error | null>(null);
  const [value, setValue] = useState<T | null>(null);

  const startFetching = useCallback((fetcher: () => Promise<T>) => {
    setState(State.Pending);
    fetcher()
      .then((data) => {
        setValue(data);
        setState(State.Finished);
      })
      .catch(reason => {
        setError(reason);
        setState(State.Error);
      });
  }, []);

  return { state, error, value, startFetching };
}
