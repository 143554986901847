// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type BusIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function BusIcon(props: BusIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M14 0H2a2.006 2.006 0 00-2 2v11a.945.945 0 00.887 1A1.065 1.065 0 001 14v1a.945.945 0 00.887 1A1.065 1.065 0 002 16h2a.945.945 0 001-.887A1.065 1.065 0 005 15v-1h6v1a.945.945 0 00.887 1A1.065 1.065 0 0012 16h2a.945.945 0 001-.887A1.065 1.065 0 0015 15v-1a.945.945 0 001-.887A1.065 1.065 0 0016 13V2a2.006 2.006 0 00-2-2zM4.5 12h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm9 0h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm0-4h-11a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v5a.5.5 0 01-.5.5z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default BusIcon;
/* prettier-ignore-end */
