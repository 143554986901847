import * as React from "react";
import {
  PlasmicAddressSuggestor,
  DefaultAddressSuggestorProps
} from "./plasmic/imbas_23_fpre/PlasmicAddressSuggestor";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface AddressSuggestorProps extends DefaultAddressSuggestorProps {}

function AddressSuggestor_(
  props: AddressSuggestorProps,
  ref: HTMLElementRefOf<"div">
) {
  return <PlasmicAddressSuggestor root={{ ref }} {...props} />;
}

const AddressSuggestor = React.forwardRef(AddressSuggestor_);
export default AddressSuggestor;
