import React, { createElement, useCallback, useMemo, useState } from "react";
import {
  DefaultEwg030BerichtProps,
  PlasmicEwg030Bericht,
  PlasmicEwg030Bericht__OverridesType
} from "./plasmic/imbas_23_fpre/PlasmicEwg030Bericht";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { AutoSavingFormWrapper } from "./custom/AutoSavingFormWrapper";
import { EWGParameters, withCurrentEWGParameters } from "../withCurrentEWGParameters";
import { flow } from "lodash";
import withInitialResourceLoad from "../store/withInitialResourceLoad";
import { DataStoreName, useDataStore, withDataStoreProvider } from "../store/DataStoreProvider";
import { withAutosaveProvider } from "../store/AutosaveProvider";
import { FormWrapperProps } from "./custom/FormWrapper";
import { FieldValues } from "react-hook-form";
import {
  getReportBlob,
  getReportInput,
  getReportMod,
  ReportInput,
  saveReportInput,
  saveReportInputDraft
} from "../api/endpoints/report";
import { downloadBlob, getFileNameFromContentDisposition } from "../utils";
import { isErrorResponse, processErrorResponse } from "../api/apiClient";
import { UnterlagenTypGebaeude, UnterlagenTypGrundstueck } from "./DynamicDocumentList";
import { Language, useRegionContext } from "../context/RegionContext";
import { useTranslation } from "react-i18next";
import { useDebugContext } from "../context/DebugContext";

type ApiErrors = FormWrapperProps<ReportInput>["apiErrors"];

export interface Ewg030BerichtProps extends DefaultEwg030BerichtProps, EWGParameters {
}

const downloadReport =  (
                          objectId: string, 
                          sprache: Language, 
                          isDebug: boolean,
                          setIsLoading: React.Dispatch<React.SetStateAction<boolean>> 
                        ) => {
  return getReportBlob(objectId, sprache, false, isDebug).then(({ data, headers }) => {
    const fileName = getFileNameFromContentDisposition(headers["content-disposition"]);
    downloadBlob(data, fileName, setIsLoading);
  });
};

function Ewg030Bericht_(
  { objectId, ...props }: Ewg030BerichtProps,
  ref: HTMLElementRefOf<"div">
) {
  const { dataStore: { reportInput } } = useDataStore();
  const [apiErrors, setApiErrors] = useState<ApiErrors>();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const { i18n } = useTranslation();
  const { language } = useRegionContext();
  const { isDebugMode, setIsDebugMode } = useDebugContext();
  
  const onSubmit = useCallback(async (data: FieldValues) => {
    try {
      setIsLoadingReport(true);
      await saveReportInput(objectId, data, language);
      await downloadReport(objectId, language, isDebugMode, setIsLoadingReport);
    } catch (err) {
      if (isErrorResponse(err)) {
        setApiErrors(processErrorResponse(err.response.data, i18n.language));
      }
    } finally {
      setIsLoadingReport(false);
      setIsDebugMode(false);
    }
  }, [objectId, isDebugMode]); 

  const specificComponentOverrides: PlasmicEwg030Bericht__OverridesType = {
    unterlagenverzeichnisGrundstueck: {
      type: "Grundstueck",
      documentFactory: (value) => ({
        UnterlagenTypGrundstueck: value as UnterlagenTypGrundstueck,
        UnterlagenGrundstueckVermittler: null,
        UnterlagenGrundstueckVerfasser: null,
        UnterlagenGrundstueckDatum: null,
      })
    },
    unterlagenverzeichnisGebaeude: {
      type: "Gebaeude",
      documentFactory: (value) => ({
        UnterlagenTypGebaeude: value as UnterlagenTypGebaeude,
        UnterlagenGebaeudeVermittler: null,
        UnterlagenGebaeudeVerfasser: null,
        UnterlagenGebaeudeDatum: null,
      })
    },
    submitAndDownloadButton: { 
      isLoading:isLoadingReport,
      isDisabled:isLoadingReport
    }
  };

  return (
    <AutoSavingFormWrapper
      className={""}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      defaultValues={reportInput}
    >
      <PlasmicEwg030Bericht
        root={{ ref }}
        {...props}
        {...specificComponentOverrides}
      />
    </AutoSavingFormWrapper>
  );
}

const Ewg030Bericht = React.forwardRef(Ewg030Bericht_);

export default withCurrentEWGParameters((props) => {
  const { objectId } = props;
  const { language } = useRegionContext();
  
  const composedComponent = useMemo(() => {
    return flow([
      withInitialResourceLoad([
        { apiCall: () => getReportInput(objectId,language), dataStoreName: DataStoreName.reportInput },
        { apiCall: () => getReportMod(objectId, language), dataStoreName: DataStoreName.reportMod }
      ]),
      withAutosaveProvider({
        apiCall: (data) => saveReportInputDraft(objectId, data, language),
        userInputDataStore: DataStoreName.reportInput,
      }),
      withDataStoreProvider({}),
    ])(Ewg030Bericht)
  }, [objectId, language]);

  return createElement(composedComponent, props);
});
