import * as React from "react";
import {
  PlasmicTextInput,
  DefaultTextInputProps
} from "./plasmic/imbas_23_fpre/PlasmicTextInput";
import { TextInputRef } from "@plasmicapp/react-web";
import { isEmptyUndefinedOrNull } from "../utils";

export interface TextInputProps extends Omit<DefaultTextInputProps, "onChange" | "value"> {
  onChange: (value: string | null) => void;
  value: string | null;
}

function TextInput_<T extends string | null>(
  { onChange, value, ...props }: Partial<TextInputProps>,
  ref: TextInputRef
) {
  const { plasmicProps } = PlasmicTextInput.useBehavior<DefaultTextInputProps>(
    props,
    ref
  );
  
  const inputProps = {
    value: isEmptyUndefinedOrNull(value) ? '' : value || '',
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = e.target.value;
      if(props.inputType === 'number' && value.match(/[^\d]/)) {
        value = value.replace(/[^-?\d]/, '');
      }  
      onChange && onChange(value as T);
    },
    // How to Disable Scrolling on HTML Number Input in React
    // https://medium.com/modernnerd-code/how-to-disabled-scrolling-on-html-number-input-in-react-6548841166fb
    onWheel: (e: React.UIEvent<HTMLElement>)  => {
      if(props.inputType === 'number' || props.inputType === 'decimal' ){
        (e.target as HTMLElement).blur();//The blur event fires when an element has lost focus. 
        e.stopPropagation();//further propagation of the current event
        setTimeout(() => {
          (e.target as HTMLElement).focus()
        }, 0)
      }
    }
  };    
  
  

  return <PlasmicTextInput
    {...plasmicProps}
    input={inputProps}
    textarea={{ ...inputProps, readOnly: props.isDisabled, style: { resize: "vertical" } }}
  />;
}

const TextInput = React.forwardRef(TextInput_);

export default Object.assign(TextInput, {
  __plumeType: "text-input"
});
