// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type BuildingIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function BuildingIcon(props: BuildingIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M20.351 3.063l-8-3a.993.993 0 00-.7 0l-8 3A1 1 0 003 4v19a1 1 0 001 1h6v-4.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V24h6a1 1 0 001-1V4a1 1 0 00-.649-.937zM7.5 16h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm0-6h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm5 6h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm0-6h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm5 6h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm0-6h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 01-.5.5z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default BuildingIcon;
/* prettier-ignore-end */
