import React, { ReactNode } from "react";
import {
  DefaultDynamicDocumentListItemProps,
  PlasmicDynamicDocumentListItem
} from "./plasmic/imbas_23_fpre/PlasmicDynamicDocumentListItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface DynamicDocumentListItemProps extends DefaultDynamicDocumentListItemProps {
  type: "Gebaeude" | "Grundstueck";
  index: number;
  documentTypeSelectOptions?: ReactNode;
}

function DynamicDocumentListItem_(
  { type, index, documentTypeSelectOptions, ...props }: DynamicDocumentListItemProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicDynamicDocumentListItem
      root={{ ref }}
      {...props}
      select={documentTypeSelectOptions ? { children: documentTypeSelectOptions } : {}}
      documentType={{ name: `UnterlagenVerzeichnis${type}.[${index}].UnterlagenTyp${type}` }}
      documentMediator={{
        name: `UnterlagenVerzeichnis${type}.[${index}].Unterlagen${type}Vermittler`,
      }}
      documentAuthor={{
        name: `UnterlagenVerzeichnis${type}.[${index}].Unterlagen${type}Verfasser`,
      }}
      documentDate={{ name: `UnterlagenVerzeichnis${type}.[${index}].Unterlagen${type}Datum` }}
      documentItemDeleteButton={{ onClick: props.onDelete }}
    />
  );
}

const DynamicDocumentListItem = React.forwardRef(DynamicDocumentListItem_);
export default DynamicDocumentListItem;
