import React, { ReactElement, useEffect, useState } from "react";
import {
  DefaultNavProfileDropdownProps,
  PlasmicNavProfileDropdown
} from "./plasmic/imbas_23_fpre/PlasmicNavProfileDropdown";
import { Language, allowedLanguages, useRegionContext } from "../context/RegionContext";
import { SelectProps } from "./Select";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { useIdTokenClaims } from "../auth/authUtils";
import { useTranslation } from "react-i18next";
import { useDynamicDropdownValues } from "../context/DynamicDropdownValuesContext";
import Select__Option from "./Select__Option";

export interface NavProfileDropdownProps extends DefaultNavProfileDropdownProps {
}


function NavProfileDropdown(props: NavProfileDropdownProps) {
  const { country, language, setLanguage } = useRegionContext();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [initials, setInitials] = useState<string>();
  const { fpre_userData } = useIdTokenClaims() ?? {};
  


  useEffect(() => {
    if(fpre_userData){
      setInitials(fpre_userData.Vorname.charAt(0) + fpre_userData.Name.charAt(0));
    }
    else{
      instance.logoutRedirect();
    }

  }, [fpre_userData]);

  
  const { t } = useTranslation();
  const dynamicDropdownItems = useDynamicDropdownValues("SPRACHE", (item, idx)  => 
    ( 
    <Select__Option key={idx} value={String(item.Value)}>
      {t(item.Name, { ns: "plasmic" })}
    </Select__Option>
    
    )
  )
  ?.filter((selectOption: ReactElement) => 
      allowedLanguages.find((element) => element.key === country)!.value.includes(+selectOption.props.value))
  ;

  const handleChange = (value: SelectProps["value"]): void => {
    if (value === "userSettings") {
      navigate(ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "usersettings" }));
    } else if (value === "logout") {
      instance.logoutRedirect();
    } else if (typeof value === "number" && Language[value] !== undefined) {
      setLanguage(value);
    }
  };
  
 return (
    <PlasmicNavProfileDropdown
      {...props}
      onChange={handleChange}
      value={language}
      navProfile={{ initials }}
      languageSelect={{
        render: (props, Component) => (
          <Component {...props}> 
            { dynamicDropdownItems } 
          </Component>
        )
      }}
    />
  );
}

export default NavProfileDropdown;
