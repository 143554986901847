// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowDotLeftIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowDotLeftIcon(props: ArrowDotLeftIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M6 12.864a1 1 0 01-1.64.769L.912 10.759a1 1 0 01-.008-1.53l3.448-2.936A1 1 0 016 7.055V9h5.236a1 1 0 110 2H6v1.864zM18 12a2 2 0 100-4 2 2 0 000 4z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowDotLeftIcon;
/* prettier-ignore-end */
