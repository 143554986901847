import React from "react";
import {
  DefaultDynamicDocumentListProps,
  PlasmicDynamicDocumentList
} from "./plasmic/imbas_23_fpre/PlasmicDynamicDocumentList";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFieldArray, useFormContext } from "react-hook-form";
import DynamicDocumentListItem from "./DynamicDocumentListItem";
import { components } from "../api/types";

export type UnterlagenGebaeude = components["schemas"]["UnterlagenGebaeude"];
export type UnterlagenTypGebaeude = components["schemas"]["UnterlagenTypGebaeude"];
export type UnterlagenGrundstueck = components["schemas"]["UnterlagenGrundstueck"];
export type UnterlagenTypGrundstueck = components["schemas"]["UnterlagenTypGrundstueck"];

export interface DynamicDocumentListProps extends DefaultDynamicDocumentListProps {
  type: "Gebaeude" | "Grundstueck";
  documentFactory: (
    value: UnterlagenTypGebaeude | UnterlagenTypGrundstueck
  ) => UnterlagenGebaeude | UnterlagenGrundstueck;
}

function DynamicDocumentList_(
  { type, documentFactory, ...props }: DynamicDocumentListProps,
  ref: HTMLElementRefOf<"div">
) {
  const MAX_ITEMS = 10;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ 
                                                      control, 
                                                      name: `UnterlagenVerzeichnis${type}` 
                                                    });

  return (
    <PlasmicDynamicDocumentList
      root={{ ref }}
      {...props}
      // select={newDocumentTypeSelect ?? props.select}
      items={fields.map((field, index) => (
        <DynamicDocumentListItem
          key={field.id}
          type={type}
          index={index}
          // documentTypeSelectOptions={newDocumentTypeSelect?.props.children}
          onDelete={() => remove(index)}
        />
      ))}
      itemsCounter={{
        counterText: `${fields.length}/${MAX_ITEMS}`,
        maxItemsReached: fields.length > MAX_ITEMS,
      }}
      isEmpty={fields.length === 0}
      
      addDocument={{
        name: `UnterlagenTyp${type}`,
        isDisabled: fields.length >= MAX_ITEMS,
        onChange: ( value ) => {    
          const newRenovation = documentFactory(value as UnterlagenTypGebaeude | UnterlagenTypGrundstueck); 
          append(newRenovation);        
        }
      }}
    />
  );
}

const DynamicDocumentList = React.forwardRef(DynamicDocumentList_);
export default DynamicDocumentList;
