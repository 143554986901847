import React from "react";
import {
  DefaultZustandProcessCardProps,
  PlasmicZustandProcessCard
} from "./plasmic/imbas_23_fpre/PlasmicZustandProcessCard";
import { RenderControlContext } from "../utils";
import { RenovationCategory } from "./DynamicRenovationList";
import { GEBREN, GEBRENA, REN, RENA } from "./DynamicRenovationListItem";

export interface ZustandProcessCardProps extends DefaultZustandProcessCardProps {
}

function ZustandProcessCard(props: ZustandProcessCardProps) {
  return (
    <PlasmicZustandProcessCard
      {...props}
      optionalFieldsGebaeude={{
        wrap: (Component) => {
          return <RenderControlContext.Consumer>{({ hidden }) => !hidden && Component}</RenderControlContext.Consumer>;
        }
      }}
      optionalFieldsWohnung={{
        wrap: (Component) => {
          return <RenderControlContext.Consumer>{({ hidden }) => !hidden && Component}</RenderControlContext.Consumer>;
        }
      }}
      zustandGebauedeRenovationenen={{
        renovationCategory: "GEBREN" as RenovationCategory,
        renovationFactory: (value: number) => {
          const newRenovation: GEBREN = {
            GEBRENA: value as GEBRENA,
            GEBRENB: null,
            GEBRENJ: null
          };
          return newRenovation;
        }
      }}
      zustandWohnungRenovationenen={{
        renovationCategory: "REN" as RenovationCategory,
        renovationFactory: (value: number) => {
          const newRenovation: REN = {
            RENA: value as RENA,
            RENB: null,
            RENJ: null
          };
          return newRenovation;
        }
      }}
    />
  );
}

export default ZustandProcessCard;
