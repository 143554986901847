import React from "react";
import {
  DefaultZustandHerleitungProps,
  PlasmicZustandHerleitung
} from "./plasmic/imbas_23_fpre/PlasmicZustandHerleitung";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import useApplyAlgoValues, { ApplyAlgoValuesContext } from "../hooks/useApplyAlgoValues";

export interface ZustandHerleitungProps extends DefaultZustandHerleitungProps {
}

function ZustandHerleitung_(
  props: ZustandHerleitungProps,
  ref: HTMLElementRefOf<"div">
) {
  const { algoMappingRef, applyAlgoValues } = useApplyAlgoValues();

  return (
    <PlasmicZustandHerleitung
      root={{ ref }}
      {...props}
      applyAlgoValuesButton={{ onClick: applyAlgoValues }}
      sliders={{
        wrapChildren: (children) => (
          <ApplyAlgoValuesContext.Provider value={{ algoMappingRef }}>
            {children}
          </ApplyAlgoValuesContext.Provider>
        )
      }}
    />
  );
}

const ZustandHerleitung = React.forwardRef(ZustandHerleitung_);
export default ZustandHerleitung;
