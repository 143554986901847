import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { useRegionContext } from "../context/RegionContext";
import isEqual from "lodash/isEqual";
import { AddressPrediction, AddressSearchQuery, getAddressPredictions } from "../api/endpoints/location";

export const useAddressPrediction = () => {
  const { language, country } = useRegionContext();
  const [searchQuery, setSearchQuery] = useState<AddressSearchQuery>({
    addressPrefix: "",
    land: country,
    sprache: language,
  });
  const [debouncedSearchQuery] = useDebounce<AddressSearchQuery>(searchQuery, 250);
  const [addressPredictions, setAddressPredictions] = useState<Array<AddressPrediction>>([]);

  const search = useCallback((addressPrefix: string) => {
    setSearchQuery({ addressPrefix, land: country, sprache: language });
  }, [country, language]);

  useEffect(() => {
    const { addressPrefix, land, sprache } = debouncedSearchQuery;

    if (addressPrefix.length < 1 || !land || !sprache) {
      setAddressPredictions((prevState) => (prevState.length < 1 ? prevState : []));
      return;
    }

    getAddressPredictions(debouncedSearchQuery).then(({ data }) => {
      setAddressPredictions((prevState) => isEqual(data, prevState) ? prevState : data);
    });
  }, [debouncedSearchQuery]);

  return { search, addressPredictions };
};
