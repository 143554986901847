// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ParkTreeBenchIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ParkTreeBenchIcon(props: ParkTreeBenchIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M16 8.25a4.2 4.2 0 00-.961-2.736A4.484 4.484 0 0015.5 3.5C15.5 1.567 14.381 0 13 0s-2.5 1.567-2.5 3.5a4.484 4.484 0 00.461 2.014A4.2 4.2 0 0010 8.25a3.776 3.776 0 002 3.52V16h2v-4.23a3.776 3.776 0 002-3.52z"
          }
        ></path>

        <path
          fill={"currentColor"}
          d={
            "M10 12H0v2h1v2h2v-2h4v2h2v-2h1v-2zm-1-1H1V8a1 1 0 011-1h6a1 1 0 011 1z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default ParkTreeBenchIcon;
/* prettier-ignore-end */
