import React from "react";
import {
  DefaultProcessPageActionsProps,
  PlasmicProcessPageActions
} from "./plasmic/imbas_23_fpre/PlasmicProcessPageActions";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useProcessCardsCtx } from "../context/ProcessCardsContext";

export interface ProcessPageActionsProps extends DefaultProcessPageActionsProps {
}

function ProcessPageActions_(
  props: ProcessPageActionsProps,
  ref: HTMLElementRefOf<"div">
) {
  const { dispatch } = useProcessCardsCtx();

  return <PlasmicProcessPageActions
    root={{ ref }}
    {...props}
    showOptionalFieldsToggleOn={{
      onClick: () => dispatch({
        type: "toggle_all_cards",
        payload: { isOptionalClosed: false }
      }),
    }}
    showOptionalFieldsToggleOff={{
      onClick: () => dispatch({
        type: "toggle_all_cards",
        payload: { isOptionalClosed: true }
      }),
    }}
  />;
}

const ProcessPageActions = React.forwardRef(ProcessPageActions_);
export default ProcessPageActions;
