import React from "react";
import { DefaultSymbolCounterProps, PlasmicSymbolCounter } from "./plasmic/imbas_23_fpre/PlasmicSymbolCounter";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useWatch } from "react-hook-form";

export interface SymbolCounterProps extends DefaultSymbolCounterProps {
  name: string;
}

function SymbolCounter_(
  { name, ...rest }: SymbolCounterProps,
  ref: HTMLElementRefOf<"div">
) {
  const value = useWatch({ name })?.length || 0;
  const maxValue = rest.maxValue || 0;

  return (
    <PlasmicSymbolCounter
      root={{ ref }}
      {...rest}
      value={String(value)}
      maximumValue={String(maxValue)}
      warning={value > maxValue}
    />
  );
}

const SymbolCounter = React.forwardRef(SymbolCounter_);
export default SymbolCounter;
