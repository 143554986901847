import React, { useEffect, useMemo } from "react";
import ImbasIframe from "../components/custom/ImbasIframe";
import Navigation from "../components/Navigation";
import { useTranslation } from "react-i18next";
import { Country, useRegionContext } from "../context/RegionContext";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { isWebpackRequest } from "../utils";

// This is a whitelist of iframe paths that do not require a `location` to be set.
const noLocationRequiredPaths = ["I23_MonregRedirect", "usersettings", "objekte", "methodenberichte", "pfa"];

const ImbasLegacy: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { imbasPath } = useParams();
  const { i18n } = useTranslation();
  const { location } = useRegionContext();
  const { setLocation } = useRegionContext();
  const { setCountry } = useRegionContext();
  const { instance } = useMsal();

  useEffect(() => {
    if (!location && !noLocationRequiredPaths.includes(imbasPath ?? "")) {
      navigate(ApplicationRoutes.getPath("root"));
      toast.error(t("toast.location_required"));
    }
  }, [location, navigate, noLocationRequiredPaths]);

    useEffect(() => {
    const onMessage = (e: MessageEvent): void => {
      if (e.data === "logout") {
        instance.logoutRedirect().catch(console.error);
      }
      else {
        try {
          if(e.data && !isWebpackRequest(e))
          {
            const data = JSON.parse(e.data);
            if (data.location) {
              const country = Country[data.location.LAND as keyof typeof Country];
              data.location.LAND = country;
              setLocation(data.location)
              setCountry(country)
            }
            if (data.nutz) {        
              navigate(ApplicationRoutes.getPath("imbasLegacyErfassung", {
                imbasPath: data.nutz
              }))
            }
          }
        }
        catch (ex) {
          console.warn(ex);
          console.log(JSON.stringify(e.data));
        }
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [instance]);

  const urlSearchParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams();
    if (i18n.resolvedLanguage) urlSearchParams.set("lng", i18n.resolvedLanguage);

    if (location) {
      const { LAND, FpreIsAccurate, ...locationData } = location;
      urlSearchParams.set("LAND", String(LAND))
      Object.entries(locationData).forEach(([key, value]) => {
        urlSearchParams.set(key, String(value))
      });
    }
    
    urlSearchParams.set("redirect", process.env.REACT_APP_IMBASLEGACY_REDIRECTURL!)

    return urlSearchParams;
  }, [location, i18n.resolvedLanguage]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Navigation />
      <ImbasIframe 
        imbasPath={imbasPath ? `/${imbasPath}.aspx` : undefined} 
        urlSearchParams={urlSearchParams} 
      />

    </div>
  )
};

export default ImbasLegacy;
