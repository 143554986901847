import * as React from "react";
import {
  PlasmicItemsCounter,
  DefaultItemsCounterProps
} from "./plasmic/imbas_23_fpre/PlasmicItemsCounter";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ItemsCounterProps extends DefaultItemsCounterProps {
  counterText: string
}

function ItemsCounter_({ counterText, ...rest }: ItemsCounterProps, ref: HTMLElementRefOf<"div">) {
  return (
    <PlasmicItemsCounter 
      root={{ ref }} 
      {...rest} 
      counter={{
        children: counterText
      }}
    />
  );
}

const ItemsCounter = React.forwardRef(ItemsCounter_);
export default ItemsCounter;
