// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicComparablesModal,
  DefaultComparablesModalProps,
  PlasmicComparablesModal__OverridesType
} from "./plasmic/imbas_23_fpre/PlasmicComparablesModal";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useModalContext } from "../context/ModalContext";

export interface ComparablesModalProps extends DefaultComparablesModalProps {}

function ComparablesModal_(
  props: ComparablesModalProps,
  ref: HTMLElementRefOf<"div">
) {
  const { setShowComparablesModal } = useModalContext();
  
  const specificComponentAdjustments: PlasmicComparablesModal__OverridesType = {
    closeButton: {
      onClick: () => setShowComparablesModal(false),
    }
  }

  return  (
      <PlasmicComparablesModal 
        root={{ ref }} 
        {...specificComponentAdjustments} 
        {...props} 
      />
  );

}

const ComparablesModal = React.forwardRef(ComparablesModal_);
export default ComparablesModal;
