// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DashboardIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DashboardIcon(props: DashboardIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M6.666 9.333H1.333a.667.667 0 01-.667-.666v-8C.666.299.965 0 1.333 0h5.333c.368 0 .667.299.667.667v8a.667.667 0 01-.667.666zm0 6.667H1.333a.667.667 0 01-.667-.667v-4c0-.368.299-.667.667-.667h5.333c.368 0 .667.3.667.667v4a.667.667 0 01-.667.667zm8-10.667H9.333a.667.667 0 01-.667-.666v-4c0-.368.299-.667.667-.667h5.333c.368 0 .667.299.667.667v4a.667.667 0 01-.667.666zm0 10.667H9.333a.667.667 0 01-.667-.667v-8c0-.368.299-.667.667-.667h5.333c.368 0 .667.3.667.667v8a.667.667 0 01-.667.667z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default DashboardIcon;
/* prettier-ignore-end */
