import React from "react";
import { DefaultFooterProps, PlasmicFooter } from "./plasmic/imbas_23_fpre/PlasmicFooter";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface FooterProps extends DefaultFooterProps {
}

function Footer_(props: FooterProps, ref: HTMLElementRefOf<"div">) {
  return (
    <PlasmicFooter
      root={{ ref }}
      {...props}
      dateRange={`2006-${new Date().getFullYear()}`}
    />
  );
}

const Footer = React.forwardRef(Footer_);
export default Footer;
