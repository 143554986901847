import React from "react";
import {
  DefaultDynamicAttachmentListItemProps,
  PlasmicDynamicAttachmentListItem
} from "./plasmic/imbas_23_fpre/PlasmicDynamicAttachmentListItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface DynamicAttachmentListItemProps extends DefaultDynamicAttachmentListItemProps {
  index: number;
  attachmentTitleName: string;
  attachmentFileName: string
}

function DynamicAttachmentListItem_(
  { index, attachmentTitleName, attachmentFileName, ...props }: DynamicAttachmentListItemProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicDynamicAttachmentListItem
      root={{ ref }}
      {...props}
      attachmentIndex={String(index + 1)}
      attachmentTitle={{ name: attachmentTitleName }}
      attachmentFile={{ name: attachmentFileName }}
      deleteButton={{ onClick: props.onDelete }}
    />
  );
}

const DynamicAttachmentListItem = React.forwardRef(DynamicAttachmentListItem_);
export default DynamicAttachmentListItem;
