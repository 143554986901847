import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";
import { ErfassungMod, EwgInput, } from "../api/endpoints/ewg";
import { createHOC } from "../utils";
import { MarktwertCalc, MarktwertInput, MarktwertMod } from "../api/endpoints/marktwert";
import { ReportInput, ReportMod } from "../api/endpoints/report";
import { EfhInput } from "../api/endpoints/efh";

export type PrimitiveTypes = string | number | boolean | null | undefined;
export type JSONSerializable = { [key: string]: PrimitiveTypes | JSONSerializable | Array<JSONSerializable> };

export type DataStoreKey = keyof typeof DataStoreName;
type TypeConstraint<T extends { [key in DataStoreKey]-?: JSONSerializable }> = T;

export enum DataStoreName {
  ewgInput = "ewgInput",
  erfassungMod = "erfassungMod",
  efhInput = "efhInput",
  marktwertInput = "marktwertInput",
  marktwertMod = "marktwertMod",
  marktwertCalc = "marktwertCalc",
  reportInput = "reportInput",
  reportMod = "reportMod",
}

export type DataStoreType = TypeConstraint<{
  ewgInput: Partial<EwgInput>;
  erfassungMod: ErfassungMod;
  efhInput: Partial<EfhInput>;
  marktwertInput: Partial<MarktwertInput>;
  marktwertMod: MarktwertMod;
  marktwertCalc: MarktwertCalc;
  reportInput: Partial<ReportInput>;
  reportMod: ReportMod;
}>;

interface DataStoreProviderProps {
  initialDataStore?: Partial<DataStoreType>;
}

export const DataStoreContext = createContext<{
  dataStore: Partial<DataStoreType>
  setDataStore: Dispatch<SetStateAction<Partial<DataStoreType>>>;
}>({
  dataStore: {},
  setDataStore: () => null,
});

export const useDataStore = () => useContext(DataStoreContext);

const DataStoreProvider: React.FC<PropsWithChildren<DataStoreProviderProps>> = (
  { initialDataStore = {}, children }
) => {
  const [dataStore, setDataStore] = useState<Partial<DataStoreType>>(initialDataStore);

  return (
    <DataStoreContext.Provider value={{ dataStore, setDataStore }}>
      {children}
    </DataStoreContext.Provider>
  );
};

export const withDataStoreProvider = createHOC(DataStoreProvider);

export default DataStoreProvider;
