import React, 
       {
          Dispatch, 
          PropsWithChildren, 
          SetStateAction, 
          createContext, 
          useContext, 
          useEffect, 
          useState } from "react";       
import { useRegionContext } from "./RegionContext";
import { ComparablesConfig, ComparablesData, getComparables, getComparablesConfig } from "../api/endpoints/comparables";
import { useParams } from "react-router-dom";
import { isErrorResponse } from "../api/apiClient";

export enum ComparableType {
  T = 1,
  I = 2,
  B = 3
}

const ComparablesContext = createContext<{
    currentComparableType : ComparableType | undefined,
    isloading: boolean,
    comparablesConfig: ComparablesConfig | undefined,
    comparablesData: ComparablesData | undefined,
    setCurrentComparableType: Dispatch<SetStateAction<ComparableType| undefined>> 
  }>({
    currentComparableType: undefined,
    isloading: false,
    comparablesConfig: undefined,
    comparablesData: undefined,
    setCurrentComparableType: () => undefined
  });

export const useComparablesContext = () => useContext(ComparablesContext);

const ComparableTypeProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { objectId = "plasmic" } = useParams();  
    const { language } = useRegionContext();
    const [comparablesConfig, setComparablesConfig] = useState<ComparablesConfig | undefined>(undefined);
    const [comparablesData, setComparablesData] = useState<ComparablesData | undefined>(undefined);
    const [isloading, setIsLoading] = useState<boolean>(true);
    const [currentComparableType, setCurrentComparableType] = 
          useState<ComparableType | undefined>(undefined);
    
    useEffect(() => {
      setComparablesData(undefined);
      try {
        if(objectId){
          getComparablesConfig(objectId)
            .then(({ data } ) => {
              setComparablesConfig(data); 
              setCurrentComparableType(data.InitialComparablesType ?? undefined);
            })
            .catch(error => {
              setIsLoading(false);
            });
        }
      } catch (err) { 
          if (isErrorResponse(err)) {    
            setIsLoading(false);   
            setComparablesData(undefined);
          }
      } 
    }, [objectId]);
    
    useEffect(() => {
      setComparablesData(undefined);
      try {
        if(currentComparableType){
          setIsLoading(true);
          getComparables(objectId, language, currentComparableType)
            .then(({ data } ) => {
              setComparablesData(data); 
              setIsLoading(false);
            })
            .catch(error => {
              setIsLoading(false);
            });
        }
      } catch (err) { 
          if (isErrorResponse(err)) {    
            setIsLoading(false);   
            setComparablesData(undefined);
          }
      } 
    }, [currentComparableType]);
        
    return (
      <ComparablesContext.Provider 
        value={{ 
          currentComparableType, 
          isloading,
          comparablesConfig,
          comparablesData,
          setCurrentComparableType
        }}>
        {children}
      </ComparablesContext.Provider>
    );
  };
  
  export default ComparableTypeProvider;