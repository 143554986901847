import React, { PropsWithChildren, useEffect } from "react";
import { DefaultValues, FieldValues, FormProvider, Path, SubmitHandler, useForm } from "react-hook-form";
import { registerComponent } from "@plasmicapp/host";
import { forIn } from "lodash";
import { t } from "i18next";
import { useProcessCardsCtx } from "../../context/ProcessCardsContext";

export interface FormWrapperProps<T extends FieldValues> {
  className: string,
  defaultValues?: DefaultValues<T>,
  onSubmit?: SubmitHandler<T>,
  apiErrors?: Partial<Record<Path<T>, string>>,
}

const SetFocusOnFirstError = (firstErrorKey: string) => {
  const elements = document.getElementsByName(firstErrorKey);
  elements[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
};

const GetProcessCardId = (firstError: string): string | undefined => {
  const elements = document.getElementsByName(firstError!);
  const processCardDiv = elements[0]?.closest('div[class*="PlasmicProcessCard_root"]');
  return processCardDiv?.id;
}

function FormWrapper<T extends FieldValues>(
  { className, children, defaultValues, apiErrors, ...props }: PropsWithChildren<FormWrapperProps<T>>
) {
  const formMethods = useForm<FieldValues>({ defaultValues });
  const { setError, handleSubmit, formState: { errors } } = formMethods;
  const onSubmit: FormWrapperProps<FieldValues>["onSubmit"] = props.onSubmit || console.log;
  const { processCardContainer, dispatch } = useProcessCardsCtx();

  const scrollToFirstError = (firstError: string) => {
    const id = GetProcessCardId(firstError);
    if (id) {
      const processCard = processCardContainer.processCards &&
        processCardContainer.processCards.find((processCard) => processCard.id === id);

      if (processCard?.isClosed || processCard?.isOptionalClosed)
        dispatch({
          type: "toggle_card",
          payload: { id: id, isClosed: false, isOptionalClosed: false }
        });
    }
    setTimeout(() => SetFocusOnFirstError(firstError), 5);
  };

  const onInvalid = () => {
    const firstErrorKey = errors && Object.keys(errors).find((key) => errors[key]);
    if (firstErrorKey) scrollToFirstError(firstErrorKey);
  }

  useEffect(() => {
    if(apiErrors){
      apiErrors && forIn(apiErrors, (message, key) => {
        const path = key as unknown as Path<T>;
        if (path)
          setError(path, { type: "manual", message: message ?? t("validation.default") ?? "error" });
      });
      const firstErrorKey = apiErrors && Object.keys(apiErrors).find((key) => errors[key]);
      if (firstErrorKey) scrollToFirstError(firstErrorKey);
    }
  }, [apiErrors]);

  const AutoSaveLayer: React.FunctionComponent<PropsWithChildren> = (props) => {
    const { children } = props;
    return <>{children}</>;
  };

  return (
    <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onInvalid)} className={className}>
            <AutoSaveLayer />
              {children}
          </form>
    </FormProvider> 
  );
}

export default FormWrapper;

export const registerFormWrapperComponent = () =>
  registerComponent(FormWrapper, {
    name: "FormWrapper",
    importPath: "./src/components/custom/FormWrapper",
    isDefaultExport: true,
    props: {
      children: {
        type: "slot",
        hidePlaceholder: true,
      },
      defaultValues: {
        type: "object"
      }
    }
  });

