// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type MailIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function MailIcon(props: MailIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path d={"M14 1H2a2 2 0 00-2 2v.4l8 4.5 8-4.4V3a2 2 0 00-2-2z"}></path>

        <path
          d={
            "M7.5 9.9L0 5.7V13a2 2 0 002 2h12a2 2 0 002-2V5.7L8.5 9.9a1.243 1.243 0 01-1 0z"
          }
          fill={"currentColor"}
        ></path>
      </g>
    </svg>
  );
}

export default MailIcon;
/* prettier-ignore-end */
