// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowsContractIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowsContractIcon(props: ArrowsContractIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.793 14a.5.5 0 00.353-.854l-2.792-2.792a.5.5 0 00-.708 0l-2.792 2.792a.5.5 0 00.353.854H7v1a1 1 0 102 0v-1h1.793zM15 8a.5.5 0 00-.5-.5h-13a.5.5 0 000 1h13A.5.5 0 0015 8zM8 0a1 1 0 011 1v1h1.793a.5.5 0 01.353.854L8.354 5.646a.5.5 0 01-.708 0L4.854 2.854A.5.5 0 015.207 2H7V1a1 1 0 011-1z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowsContractIcon;
/* prettier-ignore-end */
