// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type EcoHomeIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function EcoHomeIcon(props: EcoHomeIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.657 10.245l-.024-.019-11-9a1 1 0 00-1.266 0l-11 9a1 1 0 001.266 1.548L3 10.656V22a1 1 0 001 1h16a1 1 0 001-1V10.656l1.367 1.118a1 1 0 001.29-1.529zm-9.624 7.414c-2.293 2.293-5.735 1.456-6.295 1.3l4.762-5.334-5.086 2.011a4.435 4.435 0 011.514-3.083C11.072 10.408 16.586 10 16.586 10s-.204 5.31-2.553 7.659z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default EcoHomeIcon;
/* prettier-ignore-end */
