// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicComparableSwitcher,
  DefaultComparableSwitcherProps,
  PlasmicComparableSwitcher__OverridesType
} from "./plasmic/imbas_23_fpre/PlasmicComparableSwitcher";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ComparableType, useComparablesContext } from "../context/ComparablesContext";
import { useRegionContext } from "../context/RegionContext";
import { useParams } from "react-router-dom";
import { getComparablesDebugOutput, getComparablesReport } from "../api/endpoints/comparables";
import { downloadBlob, getFileNameFromContentDisposition } from "../utils";
import { useCallback, useEffect, useState } from "react";
import { useIdTokenClaims } from "../auth/authUtils";
import { useModalContext } from "../context/ModalContext";
import { useDebugContext } from "../context/DebugContext";

export interface ComparableSwitcherProps
  extends DefaultComparableSwitcherProps {}

function ComparableSwitcher_(
  props: ComparableSwitcherProps,
  ref: HTMLElementRefOf<"div">
) {
  const { currentComparableType, 
          setCurrentComparableType, 
          comparablesConfig,
          isloading } = useComparablesContext();  
  const { setShowComparablesModal } = useModalContext();
  const { isDebugMode, setIsDebugMode } = useDebugContext();
  const { language } = useRegionContext();
  const { objectId = "plasmic" } = useParams();  
  const [hideTransaction, setHideTransactions] = useState(true);
  const [hideReportButton, setHideReportButton] = useState(true);
  const [downloadDebugOutputIsLoading, setdownloadDebugOutputIsLoading] = useState(false);
  const [reportIsLoading, setReportIsLoading] = useState(false);

  useEffect(() => {
    setHideTransactions(comparablesConfig?.HideTransaction ?? hideTransaction)
    setHideReportButton(!comparablesConfig?.CanAccessReport ?? hideReportButton)
  }, [comparablesConfig]);
  
  const downloadDebugOutput = useCallback(async () => {
    try {
      setdownloadDebugOutputIsLoading(true);
      return await getComparablesDebugOutput(objectId, language, currentComparableType!).then(({ data, headers }) => {
        const fileName = getFileNameFromContentDisposition(headers["content-disposition"]);
        downloadBlob(data, fileName);
      });
    } catch (err) { 
      console.log(err);
    } finally {      
      setdownloadDebugOutputIsLoading(false);
    }
  }, [currentComparableType, isDebugMode]); 
  const downloadReport = useCallback(async () => {
    try {
      setReportIsLoading(true);
      return await getComparablesReport(objectId, 
                                        language, 
                                        currentComparableType!, 
                                        isDebugMode
                                      ).then(({ data, headers }) => {
        const fileName = getFileNameFromContentDisposition(headers["content-disposition"]);
        downloadBlob(data, fileName);
      });
    } catch (err) {
      console.log(err);
    } finally {      
      setReportIsLoading(false);
      setIsDebugMode(false);
    }
  }, [currentComparableType, isDebugMode]); 

  const allowedRoles = useIdTokenClaims()?.fpre_allowedRoles?.RoleInfo || [];
  const isAdminComparables = allowedRoles.some(
    ({ RoleName, Land }) => RoleName === "AdminComparables"
  );
  const specificComponentAdjustments: PlasmicComparableSwitcher__OverridesType = {
    
    transaktionenButton: {
      onClick: () => { setCurrentComparableType(ComparableType.T );},
      color: currentComparableType === 1? "grey" : "clear",
      isLoading: isloading && currentComparableType === ComparableType.T 
    },
    inserateButton: {
      onClick: () => { setCurrentComparableType(2); },
      color: currentComparableType === ComparableType.I ? "grey" : "clear",
      isLoading: isloading && currentComparableType === ComparableType.I 
    },
    exportButton: {
      onClick: () => { downloadReport(); },
      isLoading: reportIsLoading,
      isDisabled: reportIsLoading
    },
    downloadDebugButton: {
      render: (props, Component) => (
        process.env.REACT_APP_ENV !== "production" && isAdminComparables ? 
        <Component 
              {...props} 
              onClick={ () => downloadDebugOutput() } 
              isLoading={downloadDebugOutputIsLoading}
              isDisabled={downloadDebugOutputIsLoading}
            />
          : null
      )
    },
    openModalButton: {
      onClick: () => { setShowComparablesModal(true) }
    },
  }

  return (
    <PlasmicComparableSwitcher 
      root={{ ref }} 
      {...specificComponentAdjustments}
      {...props} 
      hideTransaction={hideTransaction}
      hideExportButton={hideReportButton}
    />);
}

const ComparableSwitcher = React.forwardRef(ComparableSwitcher_);
export default ComparableSwitcher;


