import { components } from "../types";
import apiClient from "../apiClient";

export type GemcInput = {
  wgs84Lng: number | null | undefined;
  wgs84Lat: number | null | undefined;
  sprache: components["schemas"]["SPRACHE"];
  land: components["schemas"]["LAND"];
  format: components["schemas"]["Format"];
  gemcType: components["schemas"]["GemcType"];
};

export type StaoInput = {
  wgs84Lng: number | null | undefined;
  wgs84Lat: number | null | undefined;
  sprache: components["schemas"]["SPRACHE"];
  land: components["schemas"]["LAND"];
  format: components["schemas"]["Format"];
  staoProductCode: components["schemas"]["StaoProductCode"];
  street: string | null | undefined;
  number: string | null | undefined;
  zipCode: string | null | undefined;
  city: string | null | undefined;
};
const BASE_PATH = "/Download";

export const getGemc = (query: GemcInput) => {
  return  apiClient.get<Blob>(`${BASE_PATH}/gemc`, {
    params: { ...query },
    responseType: "blob"
  });
};

export const getStao = (query: StaoInput) => {
  return  apiClient.get<Blob>(`${BASE_PATH}/stao`, {
    params: { ...query },
    responseType: "blob",
  });
};
