import React from "react";
import { DefaultNavigationProps, PlasmicNavigation, PlasmicNavigation__OverridesType, }
  from "./plasmic/imbas_23_fpre/PlasmicNavigation";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { ButtonProps } from "./Button";
import { useIdTokenClaims } from "../auth/authUtils";

export interface NavigationProps extends DefaultNavigationProps {
}

function Navigation_(
  props: NavigationProps,
  ref: HTMLElementRefOf<"div">
) {
  const navigate = useNavigate();
  const location = useLocation();
  const routes = {
    root: ApplicationRoutes.getPath("root"),
    archive: ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "objekte" }),
    training: ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "methodenberichte" }),
    admin: ApplicationRoutes.getPath("admin"),
  };
  
  const allowedRoles = useIdTokenClaims()?.fpre_allowedRoles?.RoleInfo || [];
  const isAdminLabels = allowedRoles.some(
    ({ RoleName, Land }) => RoleName === "AdminLabels"
  );

  const specificComponentAdjustments: PlasmicNavigation__OverridesType = {
    navigationButtonAdmin: {
      render: (props, Component) => (
        isAdminLabels ? 
        <Component 
              {...props} 
              onClick={ () => navigate(routes.admin) } 
              color={ matchPath(routes.admin, location.pathname) ? "grey" : "clear" }
            />
          : null
      )
    }
  }

  const getNavigationButtonProps = (path: string): ButtonProps => ({
    onClick: () => navigate(path),
    color: matchPath(path, location.pathname) ? "grey" : "clear",
  });

  return (
    <PlasmicNavigation
      root={{ ref }}
      {...specificComponentAdjustments}
      {...props}
      navigationButtonModule={getNavigationButtonProps(routes.root)}
      navigationButtonArchive={getNavigationButtonProps(routes.archive)}
      navigationButtonTraining={getNavigationButtonProps(routes.training)}
    />
  );
}

const Navigation = React.forwardRef(Navigation_);
export default Navigation;
