import React, { ChangeEvent, DragEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { DefaultFileUploadFieldProps, PlasmicFileUploadField } from "./plasmic/imbas_23_fpre/PlasmicFileUploadField";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { base64ToObjectUrl, getImageDimensions, readableFileSize } from "../utils";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { deleteReportFile, getReportFilePreview, ReportFileInfo, uploadReportFile } from "../api/endpoints/report";
import axios, { AxiosProgressEvent, CancelTokenSource } from "axios";
import { isErrorResponse } from "../api/apiClient";

type FilePreview = { fileName: string, fileSize: number, objectURL?: string };

export interface FileUploadFieldProps extends DefaultFileUploadFieldProps {
  value: ReportFileInfo | null;
  onChange: (value: FileUploadFieldProps["value"]) => void;
}

const ACCEPTED_MIME_TYPES_PDF = ["application/pdf"];
const ACCEPTED_MIME_TYPES_IMAGES = ["image/png", "image/jpeg"];

function FileUploadField_(
  { value, onChange, ...props }: FileUploadFieldProps,
  ref: HTMLElementRefOf<"div">
) {
  const { objectId = "plasmic" } = useParams();
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dragCounter = useRef<number>(0); // to prevent dragleave event from firing when dragging over children
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null); // null = not uploading, 0-100 = uploading
  const [filePreview, setFilePreview] = useState<FilePreview | null>(null);
  const [error, setError] = useState<string | null>(null);

  const acceptedMimeTypes = [
    ...(props.acceptPdf ? ACCEPTED_MIME_TYPES_PDF : []),
    ...(props.acceptImages ? ACCEPTED_MIME_TYPES_IMAGES : []),
  ];
  const hasFile = filePreview !== null;
  const isUploading = uploadProgress !== null;
  const isDisabled = hasFile || isUploading;

  useEffect(() => {
    return () => {
      if (filePreview?.objectURL) {
        URL.revokeObjectURL(filePreview.objectURL);
      }
      cancelTokenRef.current?.cancel();
    }
  }, []);

  useEffect(() => {
    if (!value?.Identifier) {
      setFilePreview(null);
      return;
    }

    getReportFilePreview(objectId, value.Identifier)
      .then(({ data }) => {
        setFilePreview({
          fileName: value.OrigFileName ?? "unknown",
          fileSize: data.Size ?? 0,
          objectURL: data.Content ? base64ToObjectUrl(data.Content) : undefined,
        });
      })
      .catch(console.error)
      .finally(() => {
        setUploadProgress(null);
      });
  }, [value]);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    fileInputRef.current?.click();
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) await uploadFile(file);
  };

  const handleDrag = (event: DragEvent<HTMLDivElement>) => {
    if (isDisabled) return;
    event.preventDefault();
    event.stopPropagation();

    if (event.type === "dragenter") {
      dragCounter.current += 1;
      setIsDragOver(true);
    } else if (event.type === "dragleave") {
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        setIsDragOver(false);
      }
    }
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    if (isDisabled) return;
    event.preventDefault();
    event.stopPropagation();

    setIsDragOver(false);
    dragCounter.current = 0;

    if (event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];
      event.dataTransfer.clearData();
      await uploadFile(file);
    }
  };

  // TODO: APIs are not ready, but consider this feature a "nice-to-have"
  const handleFileDownload = (): void => {
    console.log("Not implemented")
  };

  const handleFileDelete = async () => {
    if (!value) return;
    setIsDeleting(true);

    deleteReportFile(objectId, value.Identifier)
      .then(() => {
        onChange(null);
      })
      .catch(console.error)
      .finally(() => {
        setIsDeleting(false)
      });
  };

  const validateFile = async (file: File) => {
    setError(null);
    const fileType = file.type || "unknown";

    if (acceptedMimeTypes.length > 0 && !acceptedMimeTypes.includes(fileType)) {
      setError(t("validation.file_type", { fileType }));
      return false;
    }

    if (props.maxFileSizeInBytes && file.size > props.maxFileSizeInBytes) {
      setError(t("validation.file_size", {
        size: readableFileSize(file.size),
        maxSize: readableFileSize(props.maxFileSizeInBytes)
      }));
      return false;
    }

    if (fileType.startsWith("image/") && ((props.minWidth && props.minHeight) || (props.maxWidth && props.maxHeight))) {
      const { width, height } = await getImageDimensions(file);

      if ((props.minWidth && width < props.minWidth) || (props.minHeight && height < props.minHeight)) {
        setError(t("validation.file_resolution_too_small", {
          minWidth: props.minWidth,
          minHeight: props.minHeight
        }));
        return false;
      }
      if ((props.maxWidth && width > props.maxWidth) || (props.maxHeight && height > props.maxHeight)) {
        setError(t("validation.file_resolution_too_large", {
          maxWidth: props.maxWidth,
          maxHeight: props.maxHeight
        }));
        return false;
      }
    }

    return true;
  };

  const uploadFile = async (file: File) => {
    const isValid = await validateFile(file);
    if (!isValid) return;

    const formData = new FormData();
    formData.append("file", file);
    cancelTokenRef.current = axios.CancelToken.source();

    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      const { loaded, total } = progressEvent;
      if (total !== undefined) {
        setUploadProgress(Math.round((loaded / total) * 100));
      }
    };

    uploadReportFile(objectId, formData, cancelTokenRef.current.token, onUploadProgress)
      .then(({ data }) => {
        onChange({ Identifier: data.Identifier!, OrigFileName: file.name });
      })
      .catch((err) => {
        if (!axios.isCancel(err) && isErrorResponse(err)) {
          setError(err.response.data.Errors.map((error) => `${error.Detail}`).join("\n"));
        } else if (!axios.isCancel(err)) {
          console.error(err);
        }
        setUploadProgress(null);
      })
      .finally(() => {
        if (fileInputRef.current?.value) {
          fileInputRef.current.value = "";
        }
        cancelTokenRef.current = null;
      });
  };

  return (
    <>
      <PlasmicFileUploadField
        root={{ ref }}
        {...props}
        onClick={handleClick}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        hasError={!!error}
        errorMessage={error}
        isActive={isDragOver}
        isLoading={isUploading}
        progressBar={{ style: { width: `${uploadProgress}%` }, "aria-valuenow": uploadProgress ?? 0 }}
        cancelButton={{
          onClick: () => {
            cancelTokenRef.current?.cancel();
          }
        }}
        hasFile={hasFile}
        fileName={filePreview?.fileName}
        fileSize={readableFileSize(filePreview?.fileSize)}
        filePreview={
          filePreview?.objectURL
            ? <img
              src={filePreview.objectURL}
              alt={filePreview.fileName}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
            : undefined
        }
        downloadButton={{ onClick: handleFileDownload }}
        deleteButton={{ onClick: handleFileDelete, isLoading: isDeleting }}
      />
      <input
        ref={fileInputRef}
        type="file"
        accept={acceptedMimeTypes.join(",")}
        hidden
        onChange={handleChange}
        disabled={isDisabled}
      />
    </>
  );
}

const FileUploadField = React.forwardRef(FileUploadField_);
export default FileUploadField;
