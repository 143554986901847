// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type VolumeSoundIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function VolumeSoundIcon(props: VolumeSoundIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M7 11.646a1 1 0 01-.75-1.662 3 3 0 00.001-3.967 1 1 0 011.5-1.324A4.993 4.993 0 019 8a4.994 4.994 0 01-1.25 3.307 1 1 0 01-.75.339z"
          }
        ></path>

        <path
          fill={"currentColor"}
          d={
            "M10.001 15.707a1 1 0 01-.667-1.745A8.014 8.014 0 0012 8a8.014 8.014 0 00-2.666-5.962 1 1 0 011.334-1.49A10.014 10.014 0 0114 8c0 2.841-1.215 5.557-3.332 7.453-.191.17-.43.254-.667.254z"
          }
        ></path>

        <circle fill={"currentColor"} cx={"3"} cy={"8"} r={"1"}></circle>
      </g>
    </svg>
  );
}

export default VolumeSoundIcon;
/* prettier-ignore-end */
