import React, { useContext, useEffect, useRef } from "react";
import { Chart, ChartData } from "chart.js";
import { PlasmicCanvasContext, registerComponent } from "@plasmicapp/host";
import { useTranslation } from "react-i18next";
import { BoxPlotDataPoint } from "@sgratzl/chartjs-chart-boxplot";

// Used to display mock data in the Plasmic editor
const mockData: BoxPlotChartData = {
  labels: ["Sample1", "Sample2", "Sample3", "Sample4", "Sample5"],
  datasets: [{
    label: "Mock Data",
    data: [
      [10, 20, 30, 40, 50],
      [15, 25, 35, 45, 55],
      [20, 30, 40, 50, 60],
      [25, 35, 45, 55, 65],
      [30, 40, 50, 60, 70],
    ]
  }]
};

// custom type for the data since the chart.js types are not correct (all properties are required)
export type BoxPlotChartData = ChartData<"boxplot", Partial<BoxPlotDataPoint>[]>;

type Props = {
  className?: string;
  data?: BoxPlotChartData;
  title?: string; // The `title` should be a local translation key
  enableTooltip?: boolean;
  enableLegend?: boolean;
};

const BoxPlotChart: React.FC<Props> = ({ className, data, title = "", enableTooltip = true, enableLegend = true }) => {
  const inEditor = useContext(PlasmicCanvasContext);
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>();

  const chartData = (inEditor ? mockData : data) as ChartData | undefined;

  useEffect(() => {
    if (!chartData || !canvasRef.current) {
      chartInstanceRef.current?.destroy();
      chartInstanceRef.current = null;
      return;
    }

    if (!chartInstanceRef.current) {
      chartInstanceRef.current = new Chart(canvasRef.current, {
        type: "boxplot",
        data: chartData,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: Boolean(title),
              position: "top",
              text: t(title) ?? undefined,
            },
            legend: {
              display: enableLegend,
              position: "top",
            },
            tooltip: {
              enabled: enableTooltip,
            },
          }
        }
      });
    } else {
      chartInstanceRef.current.data = chartData;
      chartInstanceRef.current.update();
    }
  }, [chartData]);

  useEffect(() => {
    return () => {
      chartInstanceRef.current?.destroy();
    };
  }, []);

  return (
    <div className={className}>
      {!chartData && <div>{t("validations.no_data", { defaultValue: "No data to display" })}</div>}
      <canvas ref={canvasRef}/>
    </div>
  );
};

export default BoxPlotChart;

export const registerBoxPlotChartComponent = () =>
  registerComponent(BoxPlotChart, {
    name: "BoxPlotChart",
    importPath: "./src/components/custom/BoxPlotChart",
    isDefaultExport: true,
    props: {
      data: {
        type: "object",
        displayName: "Data",
      },
      title: {
        type: "string",
        displayName: "Title",
      },
      enableTooltip: {
        type: "boolean",
        displayName: "Enable Tooltips",
        defaultValue: true,
      },
      enableLegend: {
        type: "boolean",
        displayName: "Enable Legend",
        defaultValue: true,
      }
    },
    defaultStyles: {
      width: "100%",
      height: "auto"
    }
  });
