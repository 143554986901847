import { components } from "../types";
import apiClient from "../apiClient";
import { ErfassungMod } from "./ewg";
import { Language } from "../../context/RegionContext";

export type EfhInput = components["schemas"]["ImbasEfhInput"];

const BASE_PATH = (objectId: string) => `/Efh/${objectId}`;

export const getEfhInput = (objectId: string) => {
  return apiClient.get<EfhInput>(`${BASE_PATH(objectId)}/EfhInput`);
};

export const getEfhMod = (objectId: string, sprache: Language) => {
  return apiClient.get<ErfassungMod>(`${BASE_PATH(objectId)}/EfhMod`, { params: { sprache } });
};

export const saveEfhInputDraft = (objectId: string, sprache: Language, body: EfhInput) => {
  return apiClient.put<void>(`${BASE_PATH(objectId)}/EfhInputDraft`, body, { params: { sprache } });
};

export const saveEfhInput = (objectId: string, body: EfhInput) => {
  return apiClient.put<void>(`${BASE_PATH(objectId)}/EfhInput`, body);
};
