import * as React from "react";
import {
  PlasmicScrollNavGroup,
  DefaultScrollNavGroupProps
} from "./plasmic/imbas_23_fpre/PlasmicScrollNavGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import NavButton from "./NavButton";
import { useProcessCardsCtx } from "../context/ProcessCardsContext";
import { useState, useEffect } from "react";

export interface ScrollNavGroupProps extends DefaultScrollNavGroupProps { }

function ScrollNavGroup_(
  props: ScrollNavGroupProps,
  ref: HTMLElementRefOf<"div">
) {

  const [processCards, setProcessCards] = useState([{ group: '', isHidden: false, id: '', title: '' }]);
  const { processCardContainer, dispatch } = useProcessCardsCtx();

  useEffect(()=>{
    setProcessCards(processCardContainer.processCards);
  },[dispatch]);

  return (
    <PlasmicScrollNavGroup
      root={{ ref }}
      {...props}
      navItems={processCards
        .filter(p => props.group === p.group && !p.isHidden)
        .map((processCard) => (
          <NavButton
            key={processCard.id}
            text={processCard.title}
            scrollTo={processCard.id}
          />
        ))}
    />
  );
}

const ScrollNavGroup = React.forwardRef(ScrollNavGroup_);
export default ScrollNavGroup;
