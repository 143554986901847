import { useFormContext } from "react-hook-form";
import { useDataStore } from "../store/DataStoreProvider";
import React, { createContext, useCallback, useRef } from "react";
import { get } from "lodash";

type AlgoMappings = Array<{ name: string; algoName: string }>;

export const ApplyAlgoValuesContext = createContext<{
  algoMappingRef?: React.MutableRefObject<AlgoMappings>
}>({});

const useApplyAlgoValues = () => {
  const { setValue } = useFormContext();
  const { dataStore } = useDataStore();
  const algoMappingRef = useRef<AlgoMappings>([]);

  const applyAlgoValues = useCallback(() => {
    algoMappingRef.current.forEach(({ name, algoName }) => {
      const algoValue = get(dataStore, algoName);
      if (algoValue !== undefined) {
        setValue(name, algoValue);
      }
    });
  }, [dataStore, setValue]);

  return { algoMappingRef, applyAlgoValues };
};

export default useApplyAlgoValues;
