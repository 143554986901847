import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

export const ImbasLegacyContext = createContext<{
  isNewLegacyObject: boolean,
  isIframeLoading: boolean,
  nutzString?: string | undefined,
  objId?: string | undefined,
  isLoadingFromQuickAccess?: boolean;
  setIsNewLegacyObject: Dispatch<SetStateAction<boolean>>
  setIsIframeLoading: Dispatch<SetStateAction<boolean>>
  setNutzString: Dispatch<SetStateAction<string|undefined>>
  setObjId: Dispatch<SetStateAction<string|undefined>>
  setIsLoadingFromQuickAccess: Dispatch<SetStateAction<boolean>>
}>({
  isNewLegacyObject: false,
  nutzString: undefined,
  isIframeLoading: false,
  objId: undefined,
  isLoadingFromQuickAccess: false,
  setIsNewLegacyObject: () => undefined,
  setIsIframeLoading: () => undefined,
  setNutzString: () => undefined,
  setObjId: () => undefined,
  setIsLoadingFromQuickAccess: () => undefined
});

export const ImbasLegacyProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isNewLegacyObject, setIsNewLegacyObject] = useState(false)
  const [nutzString, setNutzString] = useState<string|undefined>(undefined)
  const [objId, setObjId] = useState<string|undefined>(undefined)
  const [isIframeLoading, setIsIframeLoading] = useState(false)
  const [isLoadingFromQuickAccess, setIsLoadingFromQuickAccess] = useState(false)

  useEffect(() => {
    setIsIframeLoading(true);
  }, [isNewLegacyObject]);

  return (
    <ImbasLegacyContext.Provider
      value={{
        isNewLegacyObject,
        isIframeLoading,
        nutzString,
        objId,
        isLoadingFromQuickAccess,
        setIsNewLegacyObject,
        setIsIframeLoading,
        setNutzString,
        setObjId,
        setIsLoadingFromQuickAccess
      }}>
      {children}
    </ImbasLegacyContext.Provider>
  );
}

export const useImbasLegacyContext = () => {
  const context = useContext(ImbasLegacyContext)
  return context
}