import React, { RefObject, useEffect, useState } from "react";
import { useIdTokenClaims } from "../../auth/authUtils";
import BeatLoader from "react-spinners/BeatLoader";
import { useImbasLegacyContext } from "../../context/ImbasLegacyContext";

type Props = {
  imbasPath?: string;
  urlSearchParams?: URLSearchParams;
  title?: string;
  iframeRef?: RefObject<HTMLIFrameElement>;
};

const ImbasIframe: React.FC<Props> = ({ imbasPath, urlSearchParams, title, iframeRef }) => {
  const [src, setSrc] = useState<string>("about:blank");
  const sessionId = useIdTokenClaims()?.fpre_sessionId;
  const { isIframeLoading, objId, setIsIframeLoading } = useImbasLegacyContext();

  useEffect(() => {

    const iframeUrl = new URL(process.env.REACT_APP_IMBAS_LEGACY_URL + (imbasPath ?? ""));
    sessionId && iframeUrl.searchParams.set("sid", sessionId);
    objId && iframeUrl.searchParams.set("objId", objId);

    urlSearchParams?.forEach((value, key) => {
      iframeUrl.searchParams.set(key, value);
    });

    setSrc(iframeUrl.toString());
  }, [imbasPath, urlSearchParams]);

  return (
    <div style={{ flexGrow: 1, position: "relative" }}>
      {isIframeLoading && (
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <BeatLoader
            color="#2E72E0"
          />
         </div>
      )}
      <iframe
        title={title || "IMBAS Legacy"}
        ref={iframeRef}
        src={src}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          opacity: isIframeLoading ? 0 : 1,
        }}
        onLoad={() => setIsIframeLoading(false)}
      />
    </div>
  );
};

export default ImbasIframe;
