// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LoaderLinesIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LoaderLinesIcon(props: LoaderLinesIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g className={"nc-loop-bars-16-icon-f"} fill={"currentColor"}>
        <path
          d={"M0 2h2v12H0z"}
          fill={"currentColor"}
          style={{
            animationDuration: "0.8s",
            transformOrigin: "8px 8px",
            animation: 'nc-loop-bars-anim var(--animation-duration) infinite"',
          }}
        ></path>

        <path
          d={"M7 2h2v12H7z"}
          style={{
            animationDuration: "0.8s",
            transformOrigin: "8px 8px",
            animation: 'nc-loop-bars-anim var(--animation-duration) infinite"',
          }}
        ></path>

        <path
          d={"M14 2h2v12h-2z"}
          fill={"currentColor"}
          style={{
            animationDuration: "0.8s",
            transformOrigin: "8px 8px",
            animation: 'nc-loop-bars-anim var(--animation-duration) infinite"',
          }}
        ></path>
      </g>
    </svg>
  );
}

export default LoaderLinesIcon;
/* prettier-ignore-end */
