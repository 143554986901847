import { CancelToken } from "axios";
import apiClient from "../apiClient";
import { components } from "../types";

export type Label = components["schemas"]["Label"];

const BASE_PATH = "/admin/labels";

export const getLabels = () => {
  return apiClient.get<Label[]>(`${BASE_PATH}`);
};

export const updateLabel = (label: Label) => {
  return apiClient.put<Label>(`${BASE_PATH}`, label);
};

export const publishLabels = () => {
  return apiClient.post(`${BASE_PATH}`);
};

export const uploadPlasmicFile = (
  body: FormData,
  cancelToken?: CancelToken
) => {
  return apiClient.post(`${BASE_PATH}/plasmic`, body, {
    cancelToken,
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
};

export const uploadDropdownFile = (
  body: FormData,
  cancelToken?: CancelToken
) => {
  return apiClient.post(`${BASE_PATH}/dropdown`, body, {
    cancelToken,
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
};