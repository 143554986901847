import React, { useEffect, useMemo } from "react";
import ImbasIframe from "../components/custom/ImbasIframe";
import { useTranslation } from "react-i18next";
import { Country, useRegionContext } from "../context/RegionContext";
import { useImbasLegacyContext } from "../context/ImbasLegacyContext";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { useNavigate, useParams } from "react-router-dom";
import { isWebpackRequest, parseLocationString } from "../utils";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import PlasmicProcessTopNav from "../components/plasmic/imbas_23_fpre/PlasmicProcessTopNav";
import { useDynamicDropdownValue } from "../context/DynamicDropdownValuesContext";

// This is a whitelist of iframe paths that do not require a `location` to be set.
const noLocationRequiredPaths = ["usersettings", "objekte", "methodenberichte", "pfa"];

const ImbasLegacyErfassung: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { imbasPath } = useParams();
  const { i18n } = useTranslation();
  const { location, setLocation, setCountry } = useRegionContext();
  const {
    isNewLegacyObject,
    setIsNewLegacyObject,
    nutzString,
    setNutzString,
    objId,
    isLoadingFromQuickAccess,
    setObjId,
    setIsLoadingFromQuickAccess
  } = useImbasLegacyContext();
  const { instance } = useMsal();

  useEffect(() => {
    if (!location && !noLocationRequiredPaths.includes(imbasPath ?? "")) {
      navigate(ApplicationRoutes.getPath("root"));
      toast.error(t("toast.location_required"));
    }
  }, [location, navigate, noLocationRequiredPaths]);

  const parseNutzString = (nutzStr?: string) => {
    switch (nutzStr) {
      case ("ren"):
        return 1;
      case ("dcf"):
        return 1;
      case "ewg":
        return 2;
      case "efh":
        return 3;
      case "mwg":
        return 4;
      case "bue":
        return 5;
      case "verk":
        return 6;
      case "poto":
        return 7;
    }
  }

  useEffect(() => {
    const onMessage = (e: MessageEvent): void => {
      if (e.data === "logout") {
        instance.logoutRedirect().catch(console.error);
      }
      else {
        try {
          if (e.data && !isWebpackRequest(e)) {
            const data = JSON.parse(e.data);

            if (data.nutz) {
              setNutzString(data.nutz)
            }
            if (data.location) {
              const country = Country[data.location.LAND as keyof typeof Country];
              data.location.LAND = country;
              setLocation(data.location)
              setCountry(country)
            }
          }
        }
        catch (ex) {
          console.warn(ex);
          console.log(JSON.stringify(e.data));
        }
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [instance]);

  const urlSearchParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams();
    if (i18n.resolvedLanguage) urlSearchParams.set("lng", i18n.resolvedLanguage);

    if (location) {
      const { LAND, FpreIsAccurate, ...locationData } = location;
      urlSearchParams.set("LAND", String(LAND))
      Object.entries(locationData).forEach(([key, value]) => {
        urlSearchParams.set(key, String(value))
      });
    }

    if (isNewLegacyObject) {
      urlSearchParams.set("isNewLegacyObject", "true")
      setIsNewLegacyObject(false)
    }
    urlSearchParams.set("Modul", nutzString === "dcf" ? "ren" : nutzString ?? "")


    if (isLoadingFromQuickAccess && objId) {
      console.log("objId set to " + objId)
      urlSearchParams.set("objId", objId)
      setObjId("");
      setIsLoadingFromQuickAccess(false)
    }

    return urlSearchParams;
  }, [location, i18n.resolvedLanguage]);

  const dynamicDropdownValue = useDynamicDropdownValue("NUTZ", parseNutzString(nutzString) ?? 0)?.Name;
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <PlasmicProcessTopNav
        nutz={t(dynamicDropdownValue ?? "", { ns: "plasmic" })}
        address={parseLocationString(location)}
        backButton={{
          onClick: () => navigate(ApplicationRoutes.getPath("root")),
        }}
        showAddressEditButton={true}
        editAddressClick={() => navigate(ApplicationRoutes.getPath("adressSelector", { objectId: "" }))}
      />
      <ImbasIframe
        imbasPath={imbasPath ? `/${imbasPath}.aspx` : undefined}
        urlSearchParams={urlSearchParams}
      />
    </div>
  )
};

export default ImbasLegacyErfassung;

