import * as React from "react";
import {
  DefaultDynamicRenovationListItemProps,
  PlasmicDynamicRenovationListItem
} from "./plasmic/imbas_23_fpre/PlasmicDynamicRenovationListItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { components } from "../api/types";

export type GEBREN = components["schemas"]["GEBREN"];
export type GEBRENA = components["schemas"]["GEBRENA"];
export type REN = components["schemas"]["REN"];
export type RENA = components["schemas"]["RENA"];

export interface DynamicRenovationListItemProps extends DefaultDynamicRenovationListItemProps {
  className?: string;
  renoType: string;
  index: number;
  onDelete: (index: number) => void;
}

function DynamicRenovationListItem_(
  { renoType, index, onDelete, ...rest }: DynamicRenovationListItemProps,
  ref: HTMLElementRefOf<"div">
) {

  return (
    <PlasmicDynamicRenovationListItem
      root={{ ref }}
      {...rest}
      renovationType={{
        name: `${renoType}s.[${index}].${renoType}A`
      }}
      renovationYear={{
        name: `${renoType}s.[${index}].${renoType}J`
      }}
      renovationAmount={{
        name: `${renoType}s.[${index}].${renoType}B`
      }}
      deleteButton={{
        "data-test-id": "deleteButton",
        onClick: () => onDelete(index)
      }}
    />
  );
}

const DynamicRenovationListItem = React.forwardRef(DynamicRenovationListItem_);
export default DynamicRenovationListItem;
