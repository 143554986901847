import React, { useEffect, useMemo, useState } from "react";
import { DefaultObjectPreviewProps, PlasmicObjectPreview } from "./plasmic/imbas_23_fpre/PlasmicObjectPreview";
import ObjectPreviewCard from "./ObjectPreviewCard";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { getQuickAccessItems, QuickAccessItem } from "../api/endpoints/objectInfo";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";

export interface ObjectPreviewProps extends DefaultObjectPreviewProps {
}

function ObjectPreview_(
  props: ObjectPreviewProps,
  ref: HTMLElementRefOf<"div">
) {
  const navigate = useNavigate();
  const [quickAccessItems, setQuickAccessItems] = useState<QuickAccessItem[]>([]);

  useEffect(() => {
    getQuickAccessItems().then(({ data }) => setQuickAccessItems(data));
  }, []);

  const objectPreviewCards = useMemo (() => quickAccessItems.map((quickAccessItem: QuickAccessItem) => (
    <ObjectPreviewCard
      key={quickAccessItem.ObjectId}
      quickAccessItem={quickAccessItem}
    />
  )), [quickAccessItems]);

  return (
    <PlasmicObjectPreview
      root={{ ref }}
      {...props}
      isEmpty={objectPreviewCards.length === 0}
      objectPreviewCards={objectPreviewCards}
      navigateButtonArchive={{
        onClick: () => {
          navigate(ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "objekte" }))
        }
      }}
    />
  );
}

const ObjectPreview = React.forwardRef(ObjectPreview_);
export default ObjectPreview;
