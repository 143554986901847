import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend, { HttpBackendOptions } from "i18next-http-backend";

const supportedLocales = ["de-CH", "de-DE", "en-US", "fr-CH", "it-CH", "fr-LU", "de-LU"];

// There's a good tutorial which explains the nuances of this configuration:
// https://phrase.com/blog/posts/localizing-react-apps-with-i18next/
i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    ns: ["plasmic", "local"],
    defaultNS: "local",
    fallbackLng: "de-CH",
    supportedLngs: supportedLocales,
    nonExplicitSupportedLngs: false, // Disallow to derive variants from main locales
    load: "currentOnly",
    debug: false,
    backend: {
      loadPath: process.env.REACT_APP_I18N_URL,
      crossDomain: true,
    },
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
    returnNull: false,
  });

// Add all the local translations from `./locales/*` to the i18n instance. We use the `local` namespace for these.
supportedLocales.forEach(async (lng) => {
  const translations = await import(`./locales/${lng}.json`);
  i18n.addResourceBundle(lng, "local", translations);
});

export default i18n;
