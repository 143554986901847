import React from "react";
import { DefaultFormInputTableCellProps, PlasmicFormInputTableCell } 
        from "./plasmic/imbas_23_fpre/PlasmicFormInputTableCell";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
// import { useDataStore } from "../store/DataStoreProvider";
// import { get } from "lodash";

export interface FormInputTableCellProps extends DefaultFormInputTableCellProps {
  onLinkButtonClick?: (event: MouseEvent) => void;
}

function FormInputTableCell_(
  { onLinkButtonClick, ...props }: FormInputTableCellProps,
  ref: HTMLElementRefOf<"div">
) {  
  return (
    <PlasmicFormInputTableCell
      root={{ ref }}
      {...props}
      formSuggestionGroup={{ onLinkButtonClick }}
    />
  );
}

const FormInputTableCell = React.forwardRef(FormInputTableCell_);
export default FormInputTableCell;
