import React, { ReactElement, useContext } from "react";
import { PlasmicCanvasContext, registerComponent } from "@plasmicapp/host";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { get, isEmpty } from "lodash";
import { useDataStore } from "../../store/DataStoreProvider";
import { isEmptyUndefinedOrNull, isNumber } from "../../utils";

export enum DynamicTextType {
  Text = "text",
  Number = "number",
  Currency = "currency",
}

export interface DynamicTextProps {
  className: string;
  name: string;
  type: DynamicTextType;
  children?: React.ReactNode;
  studioPlaceholder?: string;
  suffix?: React.ReactNode;
}

export function formatText(t: TFunction, type: DynamicTextType, dynamicText?: string) {
  switch (type) {
    case DynamicTextType.Text:
      return dynamicText;
    case DynamicTextType.Number:
      return !isNumber(dynamicText) || isEmptyUndefinedOrNull(dynamicText) ? 
              "-" : 
              t("{{value, number}}", { value: dynamicText });
    case DynamicTextType.Currency:
      return !isNumber(dynamicText) || isEmptyUndefinedOrNull(dynamicText) ? 
              "-" : 
                t("{{value, currency(CHF)}}", {
                    value: dynamicText,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    formatParams: {
                      value: {
                        currencyDisplay: "code",
                      }
                    }
                  }).replace(/\s*(?:CHF|EUR)\s*/, "");
  }
}

const StudioDynamicText: React.FC<DynamicTextProps> = (props) => {
  if (isEmpty(props.name)) {
    return <>[name missing]({props.type})</>
  }

  if (!React.isValidElement(props.children)) {
    return <>{`${props.name}(${props.type})`}</>;
  }

  return props.children;
};

const RemotableDynamicText: React.FC<DynamicTextProps> = (props: DynamicTextProps) => {
  const { i18n: { t } } = useTranslation();
  const { dataStore } = useDataStore();

  if (!props.name || !dataStore) {
    return <>{props.children}</>;
  }

  const dynamicText = get(dataStore, props.name);

  if (React.isValidElement(props.children)) {
    const formattedText = 
    formatText(t, props.type, dynamicText);
    return React.cloneElement(props.children as ReactElement, { children: formattedText });
  } else {
    return <>{dynamicText}</>;
  }
}

export const DynamicText: React.FunctionComponent<DynamicTextProps> = (props) => {
  const inPlasmicEditor = Boolean(useContext(PlasmicCanvasContext));

  return (
    <div className={props.className}>
      {inPlasmicEditor ? <StudioDynamicText {...props}/> : <RemotableDynamicText {...props}/>}
      {props.suffix}
    </div>
  );
};

export default DynamicText;

export const registerDynamicTextComponent = () =>
  registerComponent(DynamicText, {
    name: "DynamicText",
    importPath: "./src/components/custom/DynamicText",
    isDefaultExport: true,
    displayName: "DynamicText",
    props: {
      children: {
        type: "slot",
        defaultValue: [
          {
            type: "text",
            value: "Dynamic Value"
          }
        ],
      },
      type: {
        type: "choice",
        displayName: "Type",
        options: Object.values(DynamicTextType),
      },
      name: {
        type: "string",
        displayName: "Name",
      },
      suffix: {
        type: "slot",
        hidePlaceholder: true,
        allowedComponents: ["text"],
      }
    }
  });
