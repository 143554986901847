import * as React from "react";
import {
  PlasmicSupport,
  DefaultSupportProps,
  PlasmicSupport__OverridesType
} from "./plasmic/imbas_23_fpre/PlasmicSupport";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useState } from "react";

export interface SupportProps extends DefaultSupportProps {}

function Support_(props: SupportProps, ref: HTMLElementRefOf<"div">) {
  const [isShown, setIsShown] = useState(false);
    
  const specificComponentAdjustments: PlasmicSupport__OverridesType = {
    supportButton: {
      onClick: () => setIsShown(true),
    },
    closeButton: {
      onClick: () => setIsShown(false),
    }
  }

  return  (
      <PlasmicSupport 
        root={{ ref }} 
        {...specificComponentAdjustments} 
        {...props} 
        showDetail={isShown} 
      />
  );
}

const Support = React.forwardRef(Support_);
export default Support;
