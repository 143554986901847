import { matchPath, useLocation } from "react-router-dom";
import { useMemo } from "react";

const useMatchAny = (paths: string[]) => {
  const location = useLocation();

  return useMemo(() => {
    for (const path of paths) {
      const match = matchPath(path, location.pathname);
      if (match) {
        return match;
      }
    }
    return null;
  }, [location.pathname, paths]);
}

export default useMatchAny;
