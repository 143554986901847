import React from "react";
import {
  DefaultDynamicImpressionListItemProps,
  PlasmicDynamicImpressionListItem
} from "./plasmic/imbas_23_fpre/PlasmicDynamicImpressionListItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface DynamicImpressionListItemProps extends DefaultDynamicImpressionListItemProps {
  index: number;
  imageTitleName: string;
  imageFileName: string;
}

function DynamicImpressionListItem_(
  { index, imageTitleName, imageFileName, ...props }: DynamicImpressionListItemProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicDynamicImpressionListItem
      root={{ ref }}
      {...props}
      index={String(index + 1)}
      deleteButton={{ onClick: props.onDelete }}
      imageTitle={{ name: imageTitleName }}
      imageFile={{ name: imageFileName }}
    />
  );
}

const DynamicImpressionListItem = React.forwardRef(DynamicImpressionListItem_);
export default DynamicImpressionListItem;
