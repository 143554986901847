// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type WarningSignIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function WarningSignIcon(props: WarningSignIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.8 12.526L9.483.88A1.668 1.668 0 008.8.2a1.693 1.693 0 00-2.284.68L.2 12.526A1.678 1.678 0 001.686 15h12.628a1.7 1.7 0 00.8-.2 1.673 1.673 0 00.687-2.274zM8 13a1 1 0 111-1 1 1 0 01-1 1zm1-3.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default WarningSignIcon;
/* prettier-ignore-end */
