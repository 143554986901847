import React from "react";
import { DefaultCheckboxProps, PlasmicCheckbox } from "./plasmic/imbas_23_fpre/PlasmicCheckbox";
import { CheckboxRef } from "@plasmicapp/react-web";

interface CheckboxProps extends Omit<DefaultCheckboxProps, "onChange" | "value"> {
  onChange: (value: CheckboxProps["value"]) => void;
  value: boolean;
}

function Checkbox_(
  { onChange, value, ...props }: CheckboxProps,
  ref: CheckboxRef
) {
  const { plasmicProps } = PlasmicCheckbox.useBehavior<DefaultCheckboxProps>({
    ...props,
    onChange: () => onChange(!value),
    isChecked: value,
  }, ref);

  return (
    <PlasmicCheckbox
      {...plasmicProps}
    />
  );
}

const Checkbox = React.forwardRef(Checkbox_);

export default Object.assign(Checkbox, {
  __plumeType: "checkbox"
});
