// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DocumentCopyIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DocumentCopyIcon(props: DocumentCopyIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          fill={"currentColor"}
          d={
            "M10 4H2c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h8c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1z"
          }
        ></path>

        <path d={"M14 0H4v2h9v11h2V1c0-.6-.4-1-1-1z"}></path>
      </g>
    </svg>
  );
}

export default DocumentCopyIcon;
/* prettier-ignore-end */
