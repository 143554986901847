// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicModuleSubCard,
  DefaultModuleSubCardProps
} from "./plasmic/imbas_23_fpre/PlasmicModuleSubCard";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ModuleSubCardProps extends DefaultModuleSubCardProps {
  pdfButtonIsLoading?: boolean,
  pdfButtonOnClick?: () => void,
  excelButtonIsLoading?: boolean,
  excelButtonOnClick?: () => void,
  wordButtonIsLoading?: boolean,
  wordButtonOnClick?: () => void,
  startButtonIsLoading?: boolean,
  startButtonOnClick?: () => void
}

function ModuleSubCard_(
  { pdfButtonIsLoading, pdfButtonOnClick,
    excelButtonIsLoading, excelButtonOnClick,
    wordButtonIsLoading, wordButtonOnClick,
    startButtonIsLoading, startButtonOnClick,

    ...props }: ModuleSubCardProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicModuleSubCard
      root={{ ref }}
      {...props}
      pdfButton={{
        onClick: pdfButtonIsLoading ? undefined : pdfButtonOnClick,
        isLoading: pdfButtonIsLoading,
        loaderColor: "#475669"
      }}
      excelButton={{
        onClick: excelButtonIsLoading ? undefined : excelButtonOnClick,
        isLoading: excelButtonIsLoading,
        loaderColor: "#475669"
      }}
      wordButton={{
        onClick: wordButtonIsLoading ? undefined : wordButtonOnClick,
        isLoading: wordButtonIsLoading,
        loaderColor: "#475669"
      }}
      startButton={{
        onClick: startButtonIsLoading ? undefined : startButtonOnClick,
        isLoading: startButtonIsLoading,
        loaderColor: "#475669"
      }}
    />
  );
}

const ModuleSubCard = React.forwardRef(ModuleSubCard_);
export default ModuleSubCard;
