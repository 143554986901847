import React, { useState } from "react";
import { DefaultCardProps, PlasmicCard } from "./plasmic/imbas_23_fpre/PlasmicCard";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useDataStore } from "../store/DataStoreProvider";
import { getStateForRetrievedValue } from "../utils";

export interface CardProps extends DefaultCardProps {
}

function Card_(
  props: CardProps,
  ref: HTMLElementRefOf<"div">
) {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(props.isOpen));
  const { dataStore } = useDataStore();
  const datAmpel = dataStore.marktwertMod?.DAT_AMPEL;
  const cardState: "neutral" | "success" | "warning" | "error" = 
    datAmpel ? getStateForRetrievedValue(datAmpel) : "neutral";
  return (
    <PlasmicCard
      root={{ ref }}
      {...props}
      toggleButton={{ onClick: () => setIsOpen(!isOpen) }}
      isOpen={isOpen}
      cardState={cardState}
    />
  );
}

const Card = React.forwardRef(Card_);
export default Card;
