import React, { useEffect } from "react";
import { DefaultErrorPageProps, PlasmicErrorPage } from "./plasmic/imbas_23_fpre/PlasmicErrorPage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";

export interface ErrorPageProps extends DefaultErrorPageProps {
}

function ErrorPage_(
  props: ErrorPageProps,
  ref: HTMLElementRefOf<"div">
) {
  const navigate = useNavigate();
  const errorId = useLocation().state?.errorId;

  useEffect(() => {
    if (!errorId) {
      navigate(ApplicationRoutes.getPath("root"));
    }
  }, [errorId]);

  return (
    <PlasmicErrorPage
      root={{ ref }}
      {...props}
      errorId={String(errorId)}
      backButton={{
        onClick: () => navigate(ApplicationRoutes.getPath("root")),
      }}
    />
  );
}

const ErrorPage = React.forwardRef(ErrorPage_);
export default ErrorPage;
