import React from "react";
import {
  DefaultMikrolageSubratingsProps,
  PlasmicMikrolageSubratings
} from "./plasmic/imbas_23_fpre/PlasmicMikrolageSubratings";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import useApplyAlgoValues, { ApplyAlgoValuesContext } from "../hooks/useApplyAlgoValues";

export interface MikrolageSubratingsProps extends DefaultMikrolageSubratingsProps {
}

function MikrolageSubratings_(
  props: MikrolageSubratingsProps,
  ref: HTMLElementRefOf<"div">
) {
  const { algoMappingRef, applyAlgoValues } = useApplyAlgoValues();
  return (
    <PlasmicMikrolageSubratings
      root={{ ref }}
      {...props}
      applyAlgoValuesButton={{ onClick: applyAlgoValues }}
      sliders={{
        wrapChildren: (children) => (
          <ApplyAlgoValuesContext.Provider value={{ algoMappingRef }}>
            {children}
          </ApplyAlgoValuesContext.Provider>
        )
      }}
    />
  );
}

const MikrolageSubratings = React.forwardRef(MikrolageSubratings_);
export default MikrolageSubratings;
