import * as React from "react";
import { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ApplicationRoutes } from "./ApplicationRoutes";
import { PlasmicCanvasContext } from "@plasmicapp/host";

export interface EWGParameters {
  objectId: string;
}

export function withCurrentEWGParameters<P extends EWGParameters>(Component: React.ComponentType<P>) {
  const EnhancedComponent = (props: Omit<P, keyof EWGParameters>, ref: unknown) => {
    const inPlasmicEditor = Boolean(useContext(PlasmicCanvasContext));
    let { objectId } = useParams();
    if (inPlasmicEditor) objectId ??= "plasmic";

    if (objectId) {
      const enhancedProps = { ...props, objectId };

      return <Component {...enhancedProps as P} ref={ref}/>;
    } else {
      const path = ApplicationRoutes.getPath("error")
      console.log(`objectId missing, redirecting to ${path}`)
      return <Navigate to={path} replace={true} state={{ errorId: 404 }}/>;
    }
  };

  EnhancedComponent.displayName = `withCurrentEWGParameters(${Component.displayName})`;

  return React.forwardRef(EnhancedComponent);
}
