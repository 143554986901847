import * as React from "react";
import {
  PlasmicValidationMessage,
  DefaultValidationMessageProps
} from "./plasmic/imbas_23_fpre/PlasmicValidationMessage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { Controller, FieldError, useFormContext } from "react-hook-form";

export interface ValidationMessageProps extends DefaultValidationMessageProps {
  name: string
}

function ValidationMessage_(
  { name, ...props }: ValidationMessageProps,
  ref: HTMLElementRefOf<"div">
) {
  const error = useFormContext()?.formState.errors[name] as FieldError | undefined;
  const formContext = useFormContext();

  return (    
    <Controller
      control={formContext?.control}
      name={name}
      render={({ field, fieldState: { invalid } }) => {
        return (
          <div hidden={!error} {...({ name }) as React.HTMLAttributes<HTMLDivElement>}>
            <PlasmicValidationMessage 
              root={{ ref }} 
              {...props} 
              errorMessage={error?.message}
            />
          </div>
        );
      }}
    />  
  );
}

const ValidationMessage = React.forwardRef(ValidationMessage_);
export default ValidationMessage;
