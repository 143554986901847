import * as React from "react";
import {
  PlasmicAlgoButton,
  DefaultAlgoButtonProps
} from "./plasmic/imbas_23_fpre/PlasmicAlgoButton";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface AlgoButtonProps extends DefaultAlgoButtonProps {
  
  onClick?: () => void;
}

function AlgoButton_(props: AlgoButtonProps, ref: HTMLElementRefOf<"div">) {

  return <PlasmicAlgoButton root={{ ref }} {...props} />;
}

const AlgoButton = React.forwardRef(AlgoButton_);
export default AlgoButton;
