// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type TimeClockIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function TimeClockIcon(props: TimeClockIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g className={"nc-icon-wrapper"} fill={"currentColor"}>
        <path
          d={
            "M8 0a8 8 0 108 8 8.024 8.024 0 00-8-8zm0 14a6 6 0 116-6 6.018 6.018 0 01-6 6z"
          }
          fill={"currentColor"}
        ></path>

        <path
          data-color={"color-2"}
          d={"M11.5 7H9V4.5a1 1 0 00-2 0V8a1 1 0 001 1h3.5a1 1 0 000-2z"}
        ></path>
      </g>
    </svg>
  );
}

export default TimeClockIcon;
/* prettier-ignore-end */
