import * as React from "react";
import {
  PlasmicSelectButton,
  DefaultSelectButtonProps
} from "./plasmic/imbas_23_fpre/PlasmicSelectButton";

export interface SelectButtonProps extends DefaultSelectButtonProps {}

function SelectButton(props: SelectButtonProps) {
  return <PlasmicSelectButton {...props} submitsForm={false} />;
}

export default SelectButton;
