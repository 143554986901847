import React from "react";
import {
  DefaultBooleanSelectButtonGroupProps,
  PlasmicBooleanSelectButtonGroup
} from "./plasmic/imbas_23_fpre/PlasmicBooleanSelectButtonGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface BooleanSelectButtonGroupProps extends DefaultBooleanSelectButtonGroupProps {
  value: boolean;
  onChange?: (value: BooleanSelectButtonGroupProps["value"]) => void;
}

function singleButtonGroupValue(value: BooleanSelectButtonGroupProps["value"]) {
  switch (value) {
    case true:
      return 1;
    case false:
      return 0;
    default:
      return undefined;
  }
}

function BooleanSelectButtonGroup_(
  { onChange, value, ...props }: BooleanSelectButtonGroupProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicBooleanSelectButtonGroup
      root={{ ref }}
      buttonGroup={{
        onChange: (newValue) => newValue !== null && onChange?.(newValue === 1),
        value: singleButtonGroupValue(value)
      }}
      {...props}
    />
  );
}

const BooleanSelectButtonGroup = React.forwardRef(BooleanSelectButtonGroup_);
export default BooleanSelectButtonGroup;
