import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from "axios";
import { components } from "./types";
import { retrieveAccessToken } from "../auth/authUtils";
import { toast } from "react-toastify";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import React, { useEffect } from "react";
import { cloneDeepWith, isObjectLike } from "lodash";
import { useMsal } from "@azure/msal-react";
import { t } from "i18next";
import { JSONSerializable } from "../store/DataStoreProvider";
import { IPublicClientApplication } from "@azure/msal-browser";

export type ErrorResponse = components["schemas"]["ErrorResponse"];
export type ApiCallFunction<T> = () => Promise<AxiosResponse<T>>;

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  },
});

// Interceptor setup needs to be done within the component tree, so that we can use hooks like `useNavigate`
export const AxiosInterceptors: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const requestInterceptor = apiClient.interceptors.request.use(
      async (config) => {
        return request(config, instance);
      },
      error => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        return errorResponseHandler(error, instance, navigate);
      }
    );

    return () => {
      apiClient.interceptors.request.eject(requestInterceptor);
      apiClient.interceptors.response.eject(responseInterceptor);
    };
  }, [instance]);

  return null;
};


const request = async (config: InternalAxiosRequestConfig, instance: IPublicClientApplication) => {
  const accessToken = await retrieveAccessToken(instance);
  if (!accessToken) {
    throw new axios.Cancel('Operation canceled since no valid token.');
  }
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  if (config.headers["Content-Type"] === "application/json" && isObjectLike(config.data)) {
    config.data = replaceEmptyAndUndefined(config.data);
  }
  return config;
}

const errorResponseHandler = async (
  error: AxiosError<ErrorResponse>,
  instance: IPublicClientApplication,
  navigate: NavigateFunction) => {
  if (error.response) {
    const { status } = error.response;
    if (status === 401) {
      await instance.logoutRedirect();
    } else if (status === 403 || status === 404 || (status >= 500 && status < 600)) {
      if (!error.config?.skipErrorRedirection) {
        const errorId = error.response.data.Errors?.[0].Identifier;
        navigate(ApplicationRoutes.getPath("error"), {
          state: { errorId: errorId ? `${status} | ${errorId}` : status }
        });
      }
    }
  } else {
    toast.error(t("toast.network_error"), { toastId: "network-error" });
  }
  return Promise.reject(error);
}

const replaceEmptyAndUndefined = <T extends JSONSerializable>(obj: T): T => {
  return cloneDeepWith(obj, (value) => {
    if (value === undefined || value === "") {
      return null;
    }
    return undefined;
  });
};

export const isErrorResponse = (error: unknown): error is AxiosError & { response: AxiosResponse<ErrorResponse> } => {
  return isAxiosError(error) &&
    error.response?.data.Errors !== undefined &&
    error.response.status >= 400 &&
    error.response.status < 500;
};

export const processErrorResponse = (response: ErrorResponse, isoLocale: string) => {
  const attributeErrorsOnly = response.Errors.filter(e => e.Source !== undefined);
  return Object.fromEntries(attributeErrorsOnly.map(e => 
    [e.Source, proccessErrorMessage(e.Detail, isoLocale) ]
  ));
}

declare module 'axios' {
  export interface AxiosRequestConfig {
    skipErrorRedirection?: boolean
  }
}

const proccessErrorMessage = (errorMessage: string | undefined, isoLocale: string): string => {
  if(errorMessage?.startsWith("Value must be between"))
  {
    const num = errorMessage.match(/\d+/g);
    if (num && num.length === 2) {
      const translate = errorMessage.replace(num[0],"{0}").replace(num[1], "{1}")
      if(translate === "Value must be between {0} and {1}.") {
        const min = parseInt( num[0] ).toLocaleString(isoLocale);
        const max = parseInt( num[1] ).toLocaleString(isoLocale);
        const translated = t(translate ?? "", { ns: "plasmic" });
        return translated.replace("{0}",min).replace("{1}", max);
      }
    }
  }
  return t(errorMessage ?? "", { ns: "plasmic" })  
}
export default apiClient;
