// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LoaderIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LoaderIcon(props: LoaderIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g
        style={{
          animationDuration: '1s"',
        }}
        fill={"currentColor"}
      >
        <circle
          cx={"8"}
          cy={"3"}
          fill={"currentColor"}
          r={"2"}
          style={{
            animation:
              'nc-loop-dots-5-anim var(--animation-duration) infinite"',
          }}
        ></circle>

        <circle
          cx={"13"}
          cy={"8"}
          r={"2"}
          style={{
            animation:
              'nc-loop-dots-5-anim var(--animation-duration) infinite"',
          }}
        ></circle>

        <circle
          cx={"8"}
          cy={"13"}
          fill={"currentColor"}
          r={"2"}
          style={{
            animation:
              'nc-loop-dots-5-anim var(--animation-duration) infinite"',
          }}
        ></circle>

        <circle
          cx={"3"}
          cy={"8"}
          r={"2"}
          style={{
            animation:
              'nc-loop-dots-5-anim var(--animation-duration) infinite"',
          }}
        ></circle>
      </g>
    </svg>
  );
}

export default LoaderIcon;
/* prettier-ignore-end */
