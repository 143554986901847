import * as React from "react";
import {
  PlasmicProcessBottomNav,
  DefaultProcessBottomNavProps
} from "./plasmic/imbas_23_fpre/PlasmicProcessBottomNav";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useParams } from "react-router-dom";
import { useDataStore } from "../store/DataStoreProvider";
import { useIdTokenClaims } from "../auth/authUtils";
import { useDebugContext } from "../context/DebugContext";

export interface ProcessBottomNavProps extends DefaultProcessBottomNavProps {

}

function ProcessBottomNav_(
  props: ProcessBottomNavProps,
  ref: HTMLElementRefOf<"div">
) {
  const { isDebugMode, setIsDebugMode } = useDebugContext();
  const { dataStore: { marktwertMod } } = useDataStore();  
  const { objectId = "plasmic" } = useParams();

  const allowedRoles = useIdTokenClaims()?.fpre_allowedRoles?.RoleInfo || [];
  const isDebugAdmin = allowedRoles.some(
    ({ RoleName, Land }) => 
        RoleName === "AdminPlatform" ||
        RoleName === "AdminComparables" ||
        RoleName === "AdminReport"
  );


  return (
    <PlasmicProcessBottomNav 
      root={{ ref }} 
      {...props}      
      currentObjectId={objectId}
      idCopyButton={{
        onClick: () => {
          navigator.clipboard.writeText(objectId)
        }
      }}
      hasDebugMode={ props.hasDebugMode && process.env.REACT_APP_ENV !== "production" && isDebugAdmin } 
      debugMode={{
        onChange: () => {
          setIsDebugMode(!isDebugMode);
        },
        value: isDebugMode
      }}      
      modvers={marktwertMod?.MODVERSOUT}
    />);
}

const ProcessBottomNav = React.forwardRef(ProcessBottomNav_);
export default ProcessBottomNav;
