import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from "react";

export interface ProcessCardContainer {
  processCards: ProcessCardState[],
  counter: number,
  lastGroup: string
}

export interface ProcessCardState {
  id: string,
  isOptionalClosed: boolean,
  isClosed: boolean,
  numbering: number,
  title: string,
  isHidden: boolean,
  group: string,
  cardGroup: string
}

interface ProcessCardsProviderProps {
  children: ReactNode,
  initialState?: ProcessCardContainer,
}

type ReducerAction =
  { type: "register_card", payload: ProcessCardState } |
  { type: "toggle_card", payload: { id: string, isOptionalClosed?: boolean, isClosed?: boolean } } |
  { type: "toggle_all_cards", payload: { isOptionalClosed?: boolean, isClosed?: boolean } };

const ProcessCardsCtx = createContext<{
  processCardContainer: ProcessCardContainer,
  dispatch: Dispatch<ReducerAction>
}>({
  processCardContainer: { processCards: [], counter: 0, lastGroup: '' },
  dispatch: () => null
});

export const useProcessCardsCtx = () => useContext(ProcessCardsCtx);
const processCardsReducer = (state: ProcessCardContainer, action: ReducerAction): ProcessCardContainer => {
  switch (action.type) {
    case "register_card":
      {
        if (action.payload.isHidden === false) {
          if (action.payload.cardGroup === state.lastGroup)
            state.counter++;
          else
            state.counter = 1;

          action.payload.numbering = state.counter;
        }
        state.processCards.push(action.payload);
        state.lastGroup = action.payload.cardGroup;
        return state;
      }
    case "toggle_card":
      return {
        ...state, processCards: state.processCards.map((processCard) => (
          processCard.id === action.payload.id ? {
            ...processCard,
            isOptionalClosed: action.payload.isOptionalClosed ?? processCard.isOptionalClosed,
            isClosed: action.payload.isClosed ?? processCard.isClosed,
          } : processCard
        ))
      };

    case "toggle_all_cards":
      return {
        ...state, processCards: state.processCards.map((processCard) => ({
          ...processCard,
          isOptionalClosed: action.payload.isOptionalClosed ?? processCard.isOptionalClosed,
          isClosed: action.payload.isClosed ?? processCard.isClosed,
        }))
      };

    default:
      throw Error("Unknown action type");
  }
};

const ProcessCardsProvider = (props: ProcessCardsProviderProps) => {

  const [processCardContainer, dispatch] =
    useReducer(processCardsReducer, props.initialState ?? { processCards: [], counter: 0, lastGroup: '' });

  return (
    <ProcessCardsCtx.Provider value={{ processCardContainer, dispatch }}>
      {props.children}
    </ProcessCardsCtx.Provider>
  );
};

export default ProcessCardsProvider;
