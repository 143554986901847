import React, { createContext, PropsWithChildren, ReactElement, useContext, useState } from "react";
import dropDownJSON from "../DropDown.json";

type DynamicDropdownValueItem = { Name: string, Value: number, SortOrder: number };

export type DynamicDropdownValue = { Name: string, Items: Array<DynamicDropdownValueItem> };

export const DynamicDropdownValuesContext = createContext<{
  dynamicDropdownValues: Array<DynamicDropdownValue>
}>({
  dynamicDropdownValues: [],
});

export const useDynamicDropdownValues = <T extends ReactElement>(
  name: string | undefined,
  mappingFn: (item: DynamicDropdownValueItem, idx: number) => T
): T[] | undefined => {
  const { dynamicDropdownValues } = useContext(DynamicDropdownValuesContext);

  return dynamicDropdownValues
    .find((dynamicDropdownValue) => dynamicDropdownValue.Name === name)?.Items
    .sort((a, b) => a.SortOrder - b.SortOrder)
    .map(mappingFn);
};

export const useDynamicDropdownValue = (
  name: string | undefined,
  value: number
): DynamicDropdownValueItem | undefined => {
  const { dynamicDropdownValues } = useContext(DynamicDropdownValuesContext);
  return dynamicDropdownValues
    .find((item) => item.Name === name)?.Items
    .find((option) => option.Value === value);
};

const DynamicDropdownValuesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [dynamicDropdownValues] = useState<DynamicDropdownValue[]>(dropDownJSON);

  return (
    <DynamicDropdownValuesContext.Provider value={{ dynamicDropdownValues }}>
      {children}
    </DynamicDropdownValuesContext.Provider>
  );
};

export default DynamicDropdownValuesProvider;
