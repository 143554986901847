import {
  DependencyList,
  EffectCallback,
  useEffect,
  useRef,
} from "react";

export const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList) => {
  // Usage of `useRef` instead of `useState` to avoid re-rendering
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      return effect();
    }

    isMounted.current = true;
    return undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
