import React from "react";
import {
  DefaultProcessPageContentProps,
  PlasmicProcessPageContent
} from "./plasmic/imbas_23_fpre/PlasmicProcessPageContent";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ProcessPageContentProps extends DefaultProcessPageContentProps {
}

function ProcessPageContent_(props: ProcessPageContentProps, ref: HTMLElementRefOf<"div">) {
  return (
    <PlasmicProcessPageContent root={{ ref }}{...props}/>
  )
}

const ProcessPageContent = React.forwardRef(ProcessPageContent_);
export default ProcessPageContent;
