import React, { createContext, Dispatch, SetStateAction, useLayoutEffect, useState } from "react";
import { DefaultScrollNavProps, PlasmicScrollNav } from "./plasmic/imbas_23_fpre/PlasmicScrollNav";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { scrollElementIntoView } from "../utils";

export const ScrollNavContext = createContext<{
  activeButton: string,
  setActiveButton: Dispatch<SetStateAction<string>>
}>({
  activeButton: "",
  setActiveButton: () => null
});

export interface ScrollNavProps extends DefaultScrollNavProps {
}

function ScrollNav_(props: ScrollNavProps, ref: HTMLElementRefOf<"div">) {
  const [activeButton, setActiveButton] = useState<string>(decodeURIComponent(window.location.hash.substring(1)));

  useLayoutEffect(() => {
    if (activeButton) {
      scrollElementIntoView(activeButton);
    }
  }, [activeButton]);

  return (
    <ScrollNavContext.Provider value={{ activeButton, setActiveButton }}>
      <PlasmicScrollNav root={{ ref }} {...props} />
    </ScrollNavContext.Provider>
  );
}

const ScrollNav = React.forwardRef(ScrollNav_);
export default ScrollNav;
