import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useObjectInfoContext } from "../../context/ObjectInfoContext";
import { registerComponent } from "@plasmicapp/host";
import { useRegionContext, Country } from "../../context/RegionContext";

type Props = {
  className?: string;
};

const ShinyAppIframe: React.FC<Props> = ({ className }) => {
  const { i18n } = useTranslation();
  const { objectInfo } = useObjectInfoContext();
  const NUTZ = objectInfo?.NUTZ;
  const MSReg = objectInfo?.MSReg;
  const KreisNr = objectInfo?.KreisNr;
  const { country } = useRegionContext();

  const iframeUrl = useMemo(() => {
    if (!i18n.resolvedLanguage || !process.env.REACT_APP_SHINY_APP_URL) return;

    const language = i18n.resolvedLanguage.split("-")[0].toUpperCase();
    
    let shinyNutz = "REN";
    if (NUTZ && [2,3].includes(NUTZ)){
      shinyNutz = "EIG";
    }

    // CH
    const url = new URL(process.env.REACT_APP_SHINY_APP_URL + Country[country] + '-' + shinyNutz);
    console.log("MSReg: " + MSReg);
    if (language) url.searchParams.set("lang", String(language));
    if (MSReg) url.searchParams.set("ms", String(MSReg));
    if (NUTZ) url.searchParams.set("nutz", String(NUTZ));

    // DE - kreis instead of ms, nutz instead of typ
    if (country === Country.DE) {
      url.searchParams.delete("ms");
      if (KreisNr) url.searchParams.set("kreis", String(KreisNr));
    }    

    return url;
  }, [i18n.resolvedLanguage, NUTZ, MSReg]);

  return iframeUrl ? (
    <iframe
      title="Shiny App Preisentwicklung"
      src={iframeUrl.toString()}
      className={className}
    />
  ) : (
    <div>No URL to display</div>
  );
};

export default ShinyAppIframe;

export const registerShinyAppIframeComponent = () =>
  registerComponent(ShinyAppIframe, {
    name: "ShinyAppIframe",
    importPath: "./src/components/custom/ShinyAppIframe",
    isDefaultExport: true,
    props: {},
    defaultStyles: {
      width: "100%",
      height: "725px",
      borderStyle: "none",
      borderWidth: 0,
    }
  });
