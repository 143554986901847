import React, { useEffect } from "react";
import useLoadingState from "../hooks/useLoadingState";
import { DataStoreName, useDataStore } from "./DataStoreProvider";
import { ApiCallFunction } from "../api/apiClient";
import BeatLoader from "react-spinners/BeatLoader";

const withInitialResourceLoad = (
  remoteResources: Array<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiCall: ApiCallFunction<any>;
    dataStoreName: DataStoreName;
  }>
) => <P extends object>(Component: React.ComponentType<P>) => {
    const WithInitialResourceLoad: React.FC<P> = (props) => {
    const { state, error, startFetching } = useLoadingState();
    const { setDataStore } = useDataStore();

    useEffect(() => {
      startFetching(async () => {
        for (const { apiCall, dataStoreName } of remoteResources) {
          const res = await apiCall();
          setDataStore((prev) => ({
            ...prev,
            [dataStoreName]: res.data,
          }));
        }
      });
    }, []);

    switch (state) {
      case "idle":
      case "pending":
        return <div title="loading" style={
         { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }
        }>
          <BeatLoader
            color="#2E72E0"
          />
         </div>;
      case "finished":
        return <Component {...props} />;
      default:
        return <div>Error: {error?.message}</div>;
    }
  };

  return WithInitialResourceLoad;
};

export default withInitialResourceLoad;
