import * as React from "react";
import {
  PlasmicComparablesTableHeaderCell,
  DefaultComparablesTableHeaderCellProps
} from "./plasmic/imbas_23_fpre/PlasmicComparablesTableHeaderCell";

export interface ComparablesTableHeaderCellProps
  extends DefaultComparablesTableHeaderCellProps {}

function ComparablesTableHeaderCell(props: ComparablesTableHeaderCellProps) {
  
  return <PlasmicComparablesTableHeaderCell {...props} />;
}

export default ComparablesTableHeaderCell;
