import * as React from "react";
import {
  PlasmicAdminLabels,
  DefaultAdminLabelsProps
} from "./plasmic/imbas_23_fpre/PlasmicAdminLabels";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useMemo, useRef } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_TableOptions,
} from 'material-react-table';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Label, getLabels, publishLabels, updateLabel, uploadDropdownFile, uploadPlasmicFile } 
  from "../api/endpoints/labels";
import { FileUploader } from "react-drag-drop-files";
import axios, { CancelTokenSource } from "axios";
import { isErrorResponse } from "../api/apiClient";

export interface AdminLabelsProps extends DefaultAdminLabelsProps {}

const LabelsGrid = () => {

  const columns = useMemo<MRT_ColumnDef<Label>[]>(
    () => [  
      {
        accessorKey: 'Key', 
        header: 'Key',    
        enableEditing: false,  
      },
      {
        accessorKey: 'deCH',
        header: 'de-CH',
        style: { 'display': 'inline-block' },
        // /* eslint-disable react/prop-types */ 
        // Cell: ({ cell }) => (
        //   <div style={{ 'word-break': 'break-all' }}>{cell.getValue<string>()}</div>
        // )
        
        /* eslint-disable react/prop-types */ 
        // Edit: ({ cell }) => {
        //   return <textarea style={{ 'border': 'none', 'resize': 'horizontal' }}>{cell.getValue<string>()}</textarea>;
        // }
      },
      {
        accessorKey: 'frCH',
        header: 'fr-CH',
      },
      {
        accessorKey: 'itCH',
        header: 'it-CH',
      },
      {
        accessorKey: 'enUS',
        header: 'en-US',
      },
      {
        accessorKey: 'deDE',
        header: 'de-DE',
      },     
      {
        accessorKey: 'frLU',
        header: 'fr-LU',
      },    
      {
        accessorKey: 'deLU',
        header: 'de-LU',
      },   
      {
        accessorKey: 'IsNew',
        header: 'New',
        enableEditing: false,  
        maxSize: 80,
        minSize: 40,
        size: 80, 
        Cell: ({ cell }) => (
          <div 
            style={{ 'color': cell.getValue<boolean>() ? 'green' : 'red' }}
          >
            {cell.getValue<boolean>().toString()}
          </div> 
        )
      },
      {
        accessorKey: 'LabelSources',
        header: 'Source',
        enableEditing: false,  
        maxSize: 80,
        minSize: 40,
        size: 80, 
      },
      {
        accessorKey: 'IsDeleted',
        header: 'Deleted',
        enableEditing: false,  
        maxSize: 80,
        minSize: 40,
        size: 80,  
        Cell: ({ cell }) => (
          <div 
            style={{ 'color': cell.getValue<boolean>() ? 'red' : 'green' }}
          >
            {cell.getValue<boolean>().toString()}
          </div> 
        )
      },
    ],
    [],
  );

  //call READ hook
  const {
    data: fetchedLabels = [],
    isError: isLoadingLabelsError,
    isFetching: isFetchingLabels,
    isLoading: isLoadingLabels,
  } = useGetLabels();
  
  //call UPDATE hook
  const { mutateAsync: updateCurrentLabel, isPending: isUpdatingLabel } =
    useUpdateLabel();
    
  //UPDATE action
  const handleSaveLabel: MRT_TableOptions<Label>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    await updateCurrentLabel(values);
    table.setEditingRow(null); //exit editing mode
  };
  
  const table = useMaterialReactTable({
    columns,
    data: fetchedLabels, 
    editDisplayMode: 'row',
    enableEditing: true,
    enableRowActions: true,
    enableHiding: true,
    enableDensityToggle: true,
    positionActionsColumn: 'first',   //customize the MRT components
    enableColumnResizing: true,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 5 }, 
    //optionally override the default column widths
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 200, 
    },
    muiPaginationProps: {
      rowsPerPageOptions: [20, 50, 500, 1000],
      variant: 'outlined',
    },
    initialState: { 
      columnVisibility: { LabelSources: false, IsDeleted: false },
      pagination: { pageIndex: 0, pageSize: 500 }, 
      density: 'compact',
    },     
    muiToolbarAlertBannerProps: isLoadingLabelsError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onEditingRowSave: handleSaveLabel,
    
    state: {
      isLoading: isLoadingLabels,
      isSaving: isUpdatingLabel,
      showAlertBanner: isLoadingLabelsError,
      showProgressBars: isFetchingLabels,
    },
  });

  //READ hook (get label from api)
  function fetchLabels(): Promise<Label[]> {
    return getLabels().then((labels) => labels.data);
  }

  function useGetLabels() {
    return useQuery<Label[]>({
      queryKey: ['labels'],
      queryFn: fetchLabels,
    });
  }

  // UPDATE hook (put label in api)
  function putLabel(currentLabel: Label): Promise<Label> {
    return updateLabel(currentLabel).then((label) => label.data);
  }

  function useUpdateLabel() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (updatedLabel: Label) => {
        const previousLabel = queryClient.getQueryData<Label[]>(['labels'])
          ?.find((label) => label.Key === updatedLabel.Key );
        
        updatedLabel.id = previousLabel?.id;
        updatedLabel._etag = previousLabel?._etag;
        updatedLabel.Application = previousLabel?.Application;
        updatedLabel.LabelSources = previousLabel?.LabelSources;
        updatedLabel.IsDeleted = previousLabel?.IsDeleted;
        await putLabel(updatedLabel);
      },
      //client side optimistic update
      onMutate: (newLabelInfo: Label) => {
        queryClient.setQueryData(
          ['labels'],
          (prevLabels: Label[]) =>
            prevLabels?.map((prevLabels: Label) =>
            prevLabels.id === newLabelInfo.id ? newLabelInfo : prevLabels,
            ),
        );
      },
      onSettled: () => {//refetch users after mutation, disabled for demo
        queryClient.invalidateQueries({ queryKey: ['labels']})
      }, 
      
    });
  }

  return <MaterialReactTable table={table} />
};

const queryClient = new QueryClient();

function AdminLabels_(props: AdminLabelsProps, ref: HTMLElementRefOf<"div">) {
  
  const [published, setPublished] = React.useState(false);
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  const isProduction = process.env.REACT_APP_ENV === "production";

  const handlePlasmicChange = (fileList: FileList) => {    
    const formData = new FormData();
    formData.append("file", fileList[0]);
    cancelTokenRef.current = axios.CancelToken.source();
    uploadPlasmicFile(formData, cancelTokenRef.current.token)
    .then(() => {
      queryClient.invalidateQueries({ queryKey: ['labels']})
    })
    .catch((err: unknown) => {
      if (!axios.isCancel(err) && isErrorResponse(err)) {
        alert(err);
      } else if (!axios.isCancel(err)) {
        alert(err);
      }
    })
  };

  const handleDropDownChange = (fileList: FileList) => {  
    const formData = new FormData();
    formData.append("file", fileList[0]);
    cancelTokenRef.current = axios.CancelToken.source();
    uploadDropdownFile(formData, cancelTokenRef.current.token)
    .then(() => {
      queryClient.invalidateQueries({ queryKey: ['labels']})
    })
    .catch((err: unknown) => {
      if (!axios.isCancel(err) && isErrorResponse(err)) {
        alert(err);
      } else if (!axios.isCancel(err)) {
        alert(err);
      }
    })
  };

  return (
    <PlasmicAdminLabels 
      isProduction={isProduction}
      adminContent={{ ref }} 
      {...props} 
      publishLabels={{
        onClick: () => {
          publishLabels();
          setPublished(true);
        },
        showEndIcon: published
      }}
      labelsGrid={
        isProduction ? null :
        (  
          <QueryClientProvider client={queryClient}>
            <LabelsGrid />
          </QueryClientProvider>        
        )
      }   
      plasmicJsonUpload={
        isProduction ? null :
        (  
          <FileUploader
            multiple={true}
            handleChange={handlePlasmicChange}
            name="file"
            types={["JSON"]}
          />
        )
      } 
      dropDownJsonUpload={
        isProduction ? null :
        ( 
          <FileUploader
            multiple={true}
            handleChange={handleDropDownChange}
            name="file"
            types={["JSON"]}
          />
        )
      }
    />
  );
}

const AdminLabels = React.forwardRef(AdminLabels_);
export default AdminLabels;

