// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type PhoneIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function PhoneIcon(props: PhoneIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.086 10.4l-3.137-1.392a1.542 1.542 0 00-1.824.442l-.947 1.185a12.427 12.427 0 01-3.813-3.812l1.184-.946a1.541 1.541 0 00.444-1.826L5.6.914A1.539 1.539 0 003.807.049L1.155.738a1.545 1.545 0 00-1.14 1.706 15.976 15.976 0 0013.542 13.541 1.522 1.522 0 00.212.015 1.544 1.544 0 001.493-1.154l.688-2.653a1.533 1.533 0 00-.864-1.793z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default PhoneIcon;
/* prettier-ignore-end */
