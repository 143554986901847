import { FPRETokenClaims } from "./jwt-claims";
import { 
  AccountInfo, 
  InteractionRequiredAuthError, 
  IPublicClientApplication } 
  from "@azure/msal-browser";
import { acquireTokenRequest } from "./msal";
import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";

export const retrieveAccessToken = async (instance: IPublicClientApplication) => {
  // We don't want to retrieve an access token in test mode since the requests are intercepted anyway
  if (process.env.REACT_APP_ENV === "test") return;

  try {
    const account = getActiveAccount(instance);
    if (account) 
    {
      const silentTokenResponse = await instance.acquireTokenSilent({ ...acquireTokenRequest, account });
      if(silentTokenResponse) {        
        const idTokenClaims = account.idTokenClaims;
        if(idTokenClaims){
          const claims =parseTokenClaims(idTokenClaims, [
            "fpre_userData",
            "fpre_allowedModules",
            "fpre_allowedRoles"
          ]);
          if(claims)
            return silentTokenResponse.accessToken;
        }
      }
    }
    await instance.acquireTokenRedirect(acquireTokenRequest);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.info("Interaction required, redirecting to login");
      //await instance.acquireTokenRedirect(acquireTokenRequest);
    } else {
      console.error("Token acquisition error:", error);
    }
    throw error;
  }
};

export const useIdTokenClaims = (): FPRETokenClaims | null => {
  const { instance } = useMsal();
  const idTokenClaims = getActiveAccount(instance)?.idTokenClaims;

  return useMemo(() => {
    if (!idTokenClaims) return null;

    return parseTokenClaims(idTokenClaims, [
      "fpre_userData",
      "fpre_allowedModules",
      "fpre_allowedRoles"
    ]);
  }, [idTokenClaims]);
};

const parseTokenClaims = (claims: FPRETokenClaims, keysToParse: Array<keyof FPRETokenClaims>) => {
  const parsedClaims = { ...claims };
  keysToParse.forEach((key) => {
    if (typeof parsedClaims[key] === "string") {
      try {
        parsedClaims[key] = JSON.parse(parsedClaims[key] as string);
      } catch (err) {
        console.warn(`Failed to parse ${key} from idTokenClaims`, err);
      }
    }
  });
  return parsedClaims;
};

const getActiveAccount = (instance: IPublicClientApplication): AccountInfo | null => {
  let account = instance.getActiveAccount();
  if (!account) {
    account = instance.getAllAccounts()[0];
    instance.setActiveAccount(account);
  }
  return account;
};
