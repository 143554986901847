// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowDotUpIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowDotUpIcon(props: ArrowDotUpIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.025 5.944a1 1 0 01-.768-1.64L9.13.856a1 1 0 011.53-.008l2.936 3.448a1 1 0 01-.761 1.648H10.89v5.237a1 1 0 11-2 0V5.945H7.025zm.865 12.001a2 2 0 104 0 2 2 0 00-4 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowDotUpIcon;
/* prettier-ignore-end */
