import React, { useCallback, useMemo } from "react";
import {
  DefaultDynamicAttachmentListProps,
  PlasmicDynamicAttachmentList
} from "./plasmic/imbas_23_fpre/PlasmicDynamicAttachmentList";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFieldArray, useFormContext } from "react-hook-form";
import DynamicAttachmentListItem from "./DynamicAttachmentListItem";
import { components } from "../api/types";
import { useParams } from "react-router-dom";
import { deleteReportFile } from "../api/endpoints/report";

export const MAX_ATTACHMENTS = 8;

export type Anhang = components["schemas"]["Anhang"];

export interface DynamicAttachmentListProps
  extends DefaultDynamicAttachmentListProps {
}

function DynamicAttachmentList_(
  props: DynamicAttachmentListProps,
  ref: HTMLElementRefOf<"div">
) {
  const { objectId = "plasmic" } = useParams();
  const name = props.name || "Anhang";
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const baseUrl = process.env.REACT_APP_API_URL + `/Report/${objectId}/File`;

  const handleDelete = useCallback((index: number, attachmentFileName: string) => {
    const fileUid = getValues(attachmentFileName)?.Identifier;
    if (!fileUid) {
      remove(index);
      return;
    }

    deleteReportFile(objectId, fileUid)
      .then(() => remove(index))
      .catch(console.error);
  }, [baseUrl, getValues, remove]);

  const items = useMemo(() => fields.map((field, index) => {
    const attachmentTitleName = `${name}.[${index}].AnhangTitel`;
    const attachmentFileName = `${name}.[${index}].ReportFileInfo`;

    return (
      <DynamicAttachmentListItem
        key={field.id}
        index={index}
        attachmentTitleName={attachmentTitleName}
        attachmentFileName={attachmentFileName}
        onDelete={() => handleDelete(index, attachmentFileName)}
      />
    );
  }), [fields, name, handleDelete]);

  return (
    <PlasmicDynamicAttachmentList
      root={{ ref }}
      {...props}
      addButton={{
        onClick: () => {
          append({ AnhangTitel: null, AnhangIdentifier: null } as Anhang)
        }
      }}
      items={items}
      itemsCounter={{
        counterText: `${fields.length}/${MAX_ATTACHMENTS}`,
        maxItemsReached: fields.length > MAX_ATTACHMENTS,
      }}
      isEmpty={fields.length === 0}
    />
  );
}

const DynamicAttachmentList = React.forwardRef(DynamicAttachmentList_);
export default DynamicAttachmentList;
