// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowsExpandIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowsExpandIcon(props: ArrowsExpandIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1.5 0a.5.5 0 000 1h13a.5.5 0 000-1h-13zm6.146 12.98a.5.5 0 00.707 0l2.126-2.127a.5.5 0 00-.353-.853h-1.46V6h1.46a.5.5 0 00.353-.854L8.353 3.02a.5.5 0 00-.707 0L5.52 5.146A.5.5 0 005.873 6h1.46v4h-1.46a.5.5 0 00-.353.854l2.126 2.126zM1 15.5a.5.5 0 01.5-.5h13a.5.5 0 010 1h-13a.5.5 0 01-.5-.5z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowsExpandIcon;
/* prettier-ignore-end */
