import React from "react";
import {
  DefaultObjectPreviewCardProps,
  PlasmicObjectPreviewCard
} from "./plasmic/imbas_23_fpre/PlasmicObjectPreviewCard";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { Language, useRegionContext } from "../context/RegionContext";
import { QuickAccessItem } from "../api/endpoints/objectInfo";
import { getErfassungPathByNUTZ, parseLocationString } from "../utils";
import imbasConstants from "../Constants";
import { useImbasLegacyContext } from "../context/ImbasLegacyContext";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/lb";
import { useIdTokenClaims } from "../auth/authUtils";
import { useNavigate } from "react-router-dom";
import { useDynamicDropdownValue } from "../context/DynamicDropdownValuesContext";

export interface ObjectPreviewCardProps extends DefaultObjectPreviewCardProps {
  onClick?: () => void;
  quickAccessItem: QuickAccessItem;
}

function ObjectPreviewCard_({ quickAccessItem, ...props }: ObjectPreviewCardProps, ref: HTMLElementRefOf<"div">
) {
  const { language } = useRegionContext();
  const navigate = useNavigate();
  const sessionId = useIdTokenClaims()?.fpre_sessionId;
  const { t } = useTranslation();
  moment.locale(Language[language].toLowerCase());
  
  const { setLocation } = useRegionContext();
  const { setIsIframeLoading, setObjId, setIsLoadingFromQuickAccess } = useImbasLegacyContext();
  const dynamicDropdownValue = useDynamicDropdownValue("NUTZ", quickAccessItem.NUTZ!)!.Name;
  

  return (
    <PlasmicObjectPreviewCard 
    {...props} 
    
    objectId={quickAccessItem.ObjectId?.toString()}
    type={t(dynamicDropdownValue, { ns: "plasmic" })}
    adress={parseLocationString(quickAccessItem.Location)}
    refNr={quickAccessItem.Ref_Nr}
    hasNoRefNr={!quickAccessItem.Ref_Nr}
    version={quickAccessItem.Version}
    hasNoVersion={!quickAccessItem.Version}
    date={moment.utc(quickAccessItem.UpdatedDate, "YYYYMMDD, HH:mm").fromNow() + " "
    + moment(quickAccessItem.UpdatedDate).format(imbasConstants.datetimeFormat)}
    onClick={() => {
      if (quickAccessItem.Location) {
        setLocation(quickAccessItem.Location)
      }
      if (quickAccessItem.NUTZ) {
        setIsIframeLoading(true);
        setObjId(quickAccessItem.ObjectId!);
        setIsLoadingFromQuickAccess(true);
        if (quickAccessItem.MustBeMigrated)
        {
          const migrationURL = new URL(process.env.REACT_APP_IMBAS_LEGACY_URL + '/I23_ObjectRedirect.aspx');
          migrationURL.searchParams.set("sid", sessionId!);
          migrationURL.searchParams.set("objektHash", quickAccessItem.ObjectId!);
          migrationURL.searchParams.set("redirect", process.env.REACT_APP_IMBASLEGACY_REDIRECTURL!);
          window.location.href = migrationURL.toString();
        }
        else {
          navigate(getErfassungPathByNUTZ(quickAccessItem.NUTZ, { objectId: quickAccessItem.ObjectId! }));
        }
      }
    }}/>
  );
}

const ObjectPreviewCard = React.forwardRef(ObjectPreviewCard_);
export default ObjectPreviewCard;
