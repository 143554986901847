import * as React from "react";
import {
  PlasmicAddressSelectorPage,
  DefaultAddressSelectorPageProps
} from "./plasmic/imbas_23_fpre/PlasmicAddressSelectorPage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AutocompleteResultDTO } from "./AutocompleteTextInput";
import { useAddressPrediction } from "../hooks/useAddressPrediction";
import { AddressPrediction, LocationDetail, getLocationDetail } from "../api/endpoints/location";
import { useRegionContext } from "../context/RegionContext";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { getErfassungPathByNUTZ } from "../utils";
import { useObjectInfoContext } from "../context/ObjectInfoContext";
import { getObjectInfo, putLocation } from "../api/endpoints/objectInfo";
import { useGoogleMapContext } from "../context/GoogleMapContext";
import { components } from "../api/types";

export interface AddressSelectorPageProps
  extends DefaultAddressSelectorPageProps { }

function AddressSelectorPage_(
  props: AddressSelectorPageProps,
  ref: HTMLElementRefOf<"div">
) {

  const navigate = useNavigate();
  const { objectInfo, setObjectInfo } = useObjectInfoContext();
  const NUTZ = objectInfo?.NUTZ;
  const { objectId = "plasmic" } = useParams();

  const { search, addressPredictions } = useAddressPrediction();
  const { location, country, language, setLocation } = useRegionContext();
  const {
    mapLocation,
    isCustomAddress,
    setMapLocation,
    setIsCustomAddress,
    setCenterOnLocationChange } = useGoogleMapContext();

  const [street, setStreet] = useState(location?.Street);
  const [number, setNumber] = useState(location?.Number);
  const [zipCode, setZipCode] = useState(location?.ZipCode);
  const [city, setCity] = useState(location?.City);
  const [wgs84Lat, setWgs84Lat] = useState(location?.WGS84Lat);
  const [wgs84Lng, setWgs84Lng] = useState(location?.WGS84Lng);
  const [precision, setPrecision] = useState(location?.Precision);
  const [fpreIsAccurate, setFpreIsAccurate] = useState(location?.FpreIsAccurate);
  const [searchInputValue, setSearchInputValue] = useState("");

  useEffect(() => {
    search(searchInputValue);
  }, [search, searchInputValue]);

  useEffect(() => {
    if (!isCustomAddress) {
      setStreet(mapLocation?.Street)
      setNumber(mapLocation?.Number)
      setZipCode(mapLocation?.ZipCode)
      setCity(mapLocation?.City)
    }
    setWgs84Lat(mapLocation?.WGS84Lat)
    setWgs84Lng(mapLocation?.WGS84Lng)
  }, [mapLocation])

  function setAddress(data:
    {
      Street?: string | null | undefined;
      Number?: string | null | undefined;
      ZipCode?: string | null | undefined;
      City?: string | null | undefined;
      LAND: components["schemas"]["LAND"];
      WGS84Lat?: number | null | undefined;
      WGS84Lng?: number | null | undefined;
      Precision?: components["schemas"]["Precision"];
      FpreIsAccurate?: boolean | undefined;
    }): void {
    setStreet(data.Street ?? "");
    setNumber(data.Number ?? "");
    setZipCode(data.ZipCode ?? "");
    setCity(data.City ?? "");
    setWgs84Lat(data.WGS84Lat ?? null);
    setWgs84Lng(data.WGS84Lng ?? null);
    setPrecision(data.Precision);
    setFpreIsAccurate(data.FpreIsAccurate);
    setSearchInputValue("");
    setMapLocation({
      Street: data.Street ?? "",
      Number: data.Number ?? "",
      ZipCode: data.ZipCode ?? "",
      City: data.City ?? "",
      LAND: data.LAND,
      WGS84Lat: data.WGS84Lat ?? null,
      WGS84Lng: data.WGS84Lng ?? null,
      Precision: 3,
      FpreIsAccurate: false
    })
    setCenterOnLocationChange(true)
  }

  const handleSearch = useCallback((value: string | null) => {
    setSearchInputValue(value ?? "");
  }, []);

  const handleDropdownSelection = useCallback(async (selectedAddress: AddressPrediction) => {
    const placeId = selectedAddress.PlacesID;
    if (!placeId) return;

    getLocationDetail(placeId, language)
      .then(({ data }) => setAddress(data))
      .catch((_err) => toast.error(_err, { toastId: "network-error" }));
  }, []);

  const autocompleteResults: Array<AutocompleteResultDTO> = useMemo(() => (
    addressPredictions.map((addressPrediction) => ({
      label: addressPrediction.Address ?? "",
      onClick: () => {
        handleDropdownSelection(addressPrediction);
      },
      isRegion: !addressPrediction.FpreIsAccurate,
    }))
  ), [addressPredictions, handleDropdownSelection]);

  const saveLocation = async (newLocation: LocationDetail) => {

    if (objectId && NUTZ) {
      await putLocation(objectId, language, newLocation)
        .catch((_err) => toast.error(_err, { toastId: "network-error" }));

      setLocation(newLocation);
      setMapLocation(newLocation);

      if (objectId)
        getObjectInfo(objectId).then(({ data }) => setObjectInfo(data));
      else
        setObjectInfo(undefined);

      navigate(getErfassungPathByNUTZ(NUTZ!, { objectId: objectId }));
    }
    else {
      setLocation(newLocation);
      setMapLocation(newLocation);
      navigate(-1);
    }
  };

  const getLocation = (): LocationDetail => {
    return {
      Street: street ?? "",
      Number: number ?? "",
      ZipCode: zipCode ?? "",
      City: city ?? "",
      LAND: country ?? "",
      WGS84Lat: wgs84Lat,
      WGS84Lng: wgs84Lng,
      Precision: precision,
      FpreIsAccurate: fpreIsAccurate
    };
  };

  return (
    <PlasmicAddressSelectorPage
      root={{ ref }}
      {...props}
      backButton={{
        onClick: () => {
          objectId && NUTZ ?
            navigate(getErfassungPathByNUTZ(NUTZ!, { objectId: objectId })) :
            navigate(-1);
        }
      }}
      searchInput={{
        value: searchInputValue,
        onChange: handleSearch,
        results: autocompleteResults,
      }}
      strasse={{
        value: street,
        onChange: (e) => setStreet(e),
        isDisabled: !isCustomAddress
      }}
      nummer={{
        value: number,
        onChange: (e) => setNumber(e),
        isDisabled: !isCustomAddress
      }}
      plz={{
        value: zipCode,
        onChange: (e) => setZipCode(e),
        isDisabled: !isCustomAddress
      }}
      ort={{
        value: city,
        onChange: (e) => setCity(e),
        isDisabled: !isCustomAddress
      }}
      wgs84Lat={{
        value: `${wgs84Lat}`,
        onChange: (e) => setWgs84Lat(Number(e))
      }}
      wgs84Lng={{
        value: `${wgs84Lng}`,
        onChange: (e) => setWgs84Lng(Number(e))
      }}
      saveAddress={{
        onClick: async () => {
          saveLocation(getLocation())
        }
      }}
      isCustomCoordinates={{
        isChecked: isCustomAddress,
        onChange(isChecked) {
          setIsCustomAddress(isChecked);
        }
      }}
    />
  );
}

const AddressSelectorPage = React.forwardRef(AddressSelectorPage_);

export default AddressSelectorPage;
