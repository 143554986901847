import * as React from "react";
import {
  PlasmicDynamicEnergyConsumptionList,
  DefaultDynamicEnergyConsumptionListProps
} from "./plasmic/imbas_23_fpre/PlasmicDynamicEnergyConsumptionList";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import DynamicEnergyConsumptionListItem from "./DynamicEnergyConsumptionListItem";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RenderControlContext } from "../utils";

export interface DynamicEnergyConsumptionListProps
  extends DefaultDynamicEnergyConsumptionListProps {
  energyConsumptionFactory: (value: number) => object
}

function DynamicEnergyConsumptionList_(
  { energyConsumptionFactory, ...rest }: DynamicEnergyConsumptionListProps,
  ref: HTMLElementRefOf<"div">
) {
  const maxItems = 3;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "VERBREFFs"
  });

  return rest.isShownInitially ? null : (
    <RenderControlContext.Consumer>
      {({ hidden }) => !hidden &&
        <PlasmicDynamicEnergyConsumptionList
          root={{ ref }}
          {...rest}
          energyConsumptionItems={{
            children: fields.map((item, index) => {
              const currentKey = index;
              return (
                <DynamicEnergyConsumptionListItem
                  key={item.id}
                  index={currentKey}
                  onDelete={() => remove(index)}
                />
              )
            })
          }}

          isEmpty={fields.length === 0}

          itemsCounter={{
            maxItemsReached: fields.length > maxItems,
            counterText: `${fields.length}/${maxItems}`
          }}

          addEnergyConsumption={{
            name: "VERBRTRAE",
            isDisabled: fields.length >= maxItems,
            onChange: (value) => {
              const newEnergyConsumption = energyConsumptionFactory(value as number);
              append(newEnergyConsumption);
            }
          }}
        />
      }
    </RenderControlContext.Consumer>
  );
}

const DynamicEnergyConsumptionList = React.forwardRef(
  DynamicEnergyConsumptionList_
);
export default DynamicEnergyConsumptionList;
