// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type QuestionMarkBoldIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function QuestionMarkBoldIcon(props: QuestionMarkBoldIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.001 15.667a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667zm-1-4v-1c0-1.945 1.293-3.009 2.334-3.863C10.375 5.95 11 5.38 11 4.334c0-1.215-1.046-2-2.666-2a5.06 5.06 0 00-2.739.804l-.8.584L3.609 2.13l.792-.595a7.01 7.01 0 013.934-1.2c2.748 0 4.666 1.645 4.666 4 0 2.044-1.329 3.138-2.4 4.015-1.023.841-1.6 1.363-1.6 2.318v1h-2z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default QuestionMarkBoldIcon;
/* prettier-ignore-end */
