import styled from "styled-components";
import ThumbWrapper from "./ThumbWrapper";
import SliderThumb from "../../SliderThumb";
import * as React from "react";

const StyledSliderRailWrapper = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export default function Rail(sliderTrack: React.ReactElement, algoValuePercentage?: number) {
  const AlgoRailComponent = () => {
    return (
      <>
        {algoValuePercentage !== undefined && (
          <ThumbWrapper style={{ left: `${algoValuePercentage * 100}%` }} zIndex={1}>
            <SliderThumb isAlgo />
          </ThumbWrapper>
        )}
        <StyledSliderRailWrapper>
          {sliderTrack}
        </StyledSliderRailWrapper>
      </>
    );
  };

  AlgoRailComponent.componentName = 'AlgoRailComponent';
  return AlgoRailComponent;
}
