import * as React from "react";
import {
  PlasmicDynamicEnergyConsumptionListItem,
  DefaultDynamicEnergyConsumptionListItemProps
} from "./plasmic/imbas_23_fpre/PlasmicDynamicEnergyConsumptionListItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { components } from "../api/types";

export type VERBREFF = components["schemas"]["VERBREFF"];
export type VERBRTRAE = components["schemas"]["VERBRTRAE"];
export type VERBREINH = components["schemas"]["VERBREINH"];

export interface DynamicEnergyConsumptionListItemProps
  extends DefaultDynamicEnergyConsumptionListItemProps {
  className?: string;
  index: number;
  onDelete: (index: number) => void;
}

function DynamicEnergyConsumptionListItem_(
  { index, onDelete, ...rest }: DynamicEnergyConsumptionListItemProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicDynamicEnergyConsumptionListItem
      root={{ ref }}
      {...rest}
      energyConsumptionType={{
        name: `VERBREFFs.[${index}].VERBRTRAE`
      }}
      energyConsumptionNumber={{
        name: `VERBREFFs.[${index}].VERBR`
      }}
      energyConsumptionUnit={{
        name: `VERBREFFs.[${index}].VERBREINH`
      }}
      deleteButton={{
        "data-test-id": "deleteButton",
        onClick: () => onDelete(index)
      }}
    />
  );
}

const DynamicEnergyConsumptionListItem = React.forwardRef(
  DynamicEnergyConsumptionListItem_
);
export default DynamicEnergyConsumptionListItem;
