import { components } from "../types";
import apiClient from "../apiClient";

export type AddressSearchQuery = {
  addressPrefix: string;
  land: components["schemas"]["LAND"];
  sprache: components["schemas"]["SPRACHE"]
};
export type AddressPrediction = components["schemas"]["AddressPredictionOutput"];
export type LocationDetail = components["schemas"]["LocationDetailOutput"];

const BASE_PATH = "/Location";

export const getAddressPredictions = (query: AddressSearchQuery) => {
  return apiClient.get<AddressPrediction[]>(`${BASE_PATH}/AddressPredictions`, { params: { ...query } });
};

export const getLocationDetail = (placeId: string, language: components["schemas"]["SPRACHE"]) => {
  return apiClient.get<LocationDetail>(`${BASE_PATH}/LocationDetail`, { params: { placeId, language } });
};
