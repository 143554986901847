import * as React from "react";
import {
  PlasmicProcessSection,
  DefaultProcessSectionProps
} from "./plasmic/imbas_23_fpre/PlasmicProcessSection";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import flattenChildren from "react-keyed-flatten-children"
import Card from "./Card";
import { isValidElement, ReactElement } from "react";
import { ProcessSectionHeaderProps } from "./ProcessSectionHeader";
import { ButtonProps } from "./Button";

export interface ProcessSectionProps extends DefaultProcessSectionProps {
}

function ProcessSection_(
  props: ProcessSectionProps,
  ref: HTMLElementRefOf<"div">
) {
  // TODO: There's currently no state-update for isAllClosed depending on the children.
  const [isAllClosed, setIsAllClosed] = React.useState(false);

  const closableCards = flattenChildren(props.cards || []).map((child) => {
    if (isValidElement(child) && child.type === Card) {
      return React.cloneElement(child as ReactElement, { isClosed: isAllClosed });
    } else {
      return child;
    }
  })

  return <PlasmicProcessSection {...props}
                                root={{ ref }}
                                sectionHeader={{
                                  sectionToggleButton: {
                                    onClick: () => {
                                      setIsAllClosed(!isAllClosed)
                                    }
                                  } as ButtonProps,
                                  isClosed: isAllClosed
                                } as ProcessSectionHeaderProps}
                                cards={closableCards}/>
}

const ProcessSection = React.forwardRef(ProcessSection_);
export default ProcessSection;
