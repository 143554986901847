import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { PlasmicRootProvider } from "@plasmicapp/react-web";
import "./i18n/i18n";
import IsDarkModeContext from "../src/components/plasmic/imbas_23_fpre/PlasmicGlobalVariant__IsDarkMode";
import GlobalContextsProvider from "./components/plasmic/imbas_23_fpre/PlasmicGlobalContextsProvider";
import usePlasmicTranslator from "./i18n/usePlasmicTranslator";
import msalInstance from "./auth/msal";
import { MsalProvider } from "@azure/msal-react";
import { CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { BoxAndWiskers, BoxPlotController } from "@sgratzl/chartjs-chart-boxplot";

// Setup Chart.js => make sure to register all the chart types & plugins you want to use here
Chart.register(BoxPlotController, BoxAndWiskers, LinearScale, CategoryScale, Tooltip, Legend, Title);

const Root: React.FC = () => {
  const translator = usePlasmicTranslator();

  return (
    <PlasmicRootProvider translator={translator}>
      <GlobalContextsProvider>
        <IsDarkModeContext.Provider value={undefined}>
          <BrowserRouter>
            <MsalProvider instance={msalInstance}>
              <App/>
            </MsalProvider>
          </BrowserRouter>
        </IsDarkModeContext.Provider>
      </GlobalContextsProvider>
    </PlasmicRootProvider>
  );
}

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Root element not found");
createRoot(rootElement).render(<Root/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
