import * as React from "react";
import {
  PlasmicToastConfirmOverwrite,
  DefaultToastConfirmOverwriteProps
} from "./plasmic/imbas_23_fpre/PlasmicToastConfirmOverwrite";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ToastConfirmOverwriteProps
  extends DefaultToastConfirmOverwriteProps {
    yesButtonClick: () => void;
    noButtonClick: () => void;
  }

function ToastConfirmOverwrite_(
  { yesButtonClick, noButtonClick, ...props }: ToastConfirmOverwriteProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicToastConfirmOverwrite
      root={{ ref }}
      {...props}      
      yesButton={{
        onClick:  yesButtonClick
      }}
      noButton={{
        onClick:  noButtonClick
      }}
    />
  );
}

const ToastConfirmOverwrite = React.forwardRef(ToastConfirmOverwrite_);
export default ToastConfirmOverwrite;
