import React from "react";
import {
  DefaultStateDisplayProps,
  PlasmicStateDisplay,
  PlasmicStateDisplay__VariantsArgs
} from "./plasmic/imbas_23_fpre/PlasmicStateDisplay";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { AutoSaveState, useAutosaveContext } from "../store/AutosaveProvider";


const getAutoSaveState = (state: AutoSaveState): PlasmicStateDisplay__VariantsArgs["state"] => {
  switch (state) {
    case AutoSaveState.SAVING:
      return "saving";
    case AutoSaveState.DIRTY:
      return "notSaved";
    case AutoSaveState.ERROR:
      return "error";
    default:
      return undefined;
  }
};

export interface StateDisplayProps extends DefaultStateDisplayProps {
}

function StateDisplay_(
  props: StateDisplayProps,
  ref: HTMLElementRefOf<"div">
) {
  const { state } = useAutosaveContext();

  return (
    <PlasmicStateDisplay
      root={{ ref }}
      {...props}
      state={getAutoSaveState(state)}
    />
  );
}

const StateDisplay = React.forwardRef(StateDisplay_);
export default StateDisplay;
