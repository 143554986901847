import * as React from "react";
import { useContext } from "react";
import { DefaultNavButtonProps, PlasmicNavButton } from "./plasmic/imbas_23_fpre/PlasmicNavButton";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ScrollNavContext } from "./ScrollNav";

export interface NavButtonProps extends DefaultNavButtonProps {
  onClick?: () => void;
}

function NavButton_(props: NavButtonProps, ref: HTMLElementRefOf<"div">) {
  const { activeButton, setActiveButton } = useContext(ScrollNavContext);

  return (
    <PlasmicNavButton
      root={{ ref }}
      {...props}
      {...(props.scrollTo && {
        onClick: () => setActiveButton(props.scrollTo ?? ""),
        isActive: activeButton === props.scrollTo,
      })}
    />
  );
}

const NavButton = React.forwardRef(NavButton_);
export default NavButton;
