import React, { Component, ErrorInfo, PropsWithChildren, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { ApplicationRoutes } from "./ApplicationRoutes";

type Props = PropsWithChildren<{
  fallback?: ReactNode;
}>;

type State = {
  hasError: boolean;
}

/*
 * This component can be used to render a replacement for erroneous components. If no fallback is provided, it will
 * redirect to the error page. This works like a try-catch block for components.
 *
 * For example:
 *
 * <ErrorBoundary fallbackChildren={<FallbackSelect {...delegatedProps} />}>
 *   <PlasmicSelect {...plasmicProps} />
 * </ErrorBoundary>
 */
class ErrorBoundary extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_error: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback ?? <Navigate
        to={ApplicationRoutes.getPath("error")}
        state={{ errorId: "application_error" }}
      />
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
