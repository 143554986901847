import * as React from "react";
import { SelectRef } from "@plasmicapp/react-web";
import { DefaultSelectProps, PlasmicSelect } from "./plasmic/imbas_23_fpre/PlasmicSelect";
import Option from "./Select__Option";
import Select__Option from "./Select__Option";
import OptionGroup from "./Select__OptionGroup";
import { useDynamicDropdownValues } from "../context/DynamicDropdownValuesContext";
import { useTranslation } from "react-i18next";

const tryParseInt = (input: string | null): SelectProps["value"] => {
  if (input === null) {
    return null;
  }
  const numberValue = parseInt(input, 10);
  return isNaN(numberValue) ? input : numberValue;
};

export interface SelectProps extends Omit<DefaultSelectProps, "value" | "onChange"> {
  value: string | number | null;
  onChange?: (value: SelectProps["value"]) => void;
}

function Select_(
  { value, onChange, ...props }: SelectProps,
  ref: SelectRef
) {
  let name = props.name;
  if (props.name?.includes(".") &&
    props.name.split('.').length === 3 &&
    (props.name?.endsWith("GEBRENA") ||
      props.name?.endsWith("RENA") ||
      props.name?.endsWith("VERBRTRAE") ||
      props.name?.endsWith("VERBREINH") ||
      props.name?.endsWith("UnterlagenTypGrundstueck") ||
      props.name?.endsWith("UnterlagenTypGebaeude")
    )
  ) {
    name = props.name.split('.')[2];
  }
  const { t } = useTranslation();
  const dynamicDropdownItems = useDynamicDropdownValues(name, (item, idx) => (
    <Select__Option key={idx} value={String(item.Value)}>
      {t(item.Name, { ns: "plasmic" })}
    </Select__Option>
  ));

  const { plasmicProps } = PlasmicSelect.useBehavior<DefaultSelectProps>({
    ...props,
    value: value !== null && value !== undefined ? String(value) : null,
    onChange: (value) => {
      onChange?.(tryParseInt(value))
    },
    children: dynamicDropdownItems ?? props.children,
  }, ref);

  return <PlasmicSelect {...plasmicProps} />
}

const Select = React.forwardRef(Select_);

export default Object.assign(Select, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
