// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */

import * as React from "react";
import { createUseScreenVariants } from "@plasmicapp/react-web";

export type IsDarkModeValue = "isDarkMode";
export const IsDarkModeContext = React.createContext<
  IsDarkModeValue | undefined
>("PLEASE_RENDER_INSIDE_PROVIDER" as any);

export function useIsDarkMode() {
  return React.useContext(IsDarkModeContext);
}

export default IsDarkModeContext;
/* prettier-ignore-end */
