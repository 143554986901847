import React, { cloneElement, isValidElement, ReactElement, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { registerComponent } from "@plasmicapp/host";
import { isPresent } from "../../utils";

type WiredFormFieldProps = {
  className: string,
  name: string,
  required?: boolean,
  children?: ReactNode;
};

const WiredFormField: React.FC<WiredFormFieldProps> = ({ name, required, children }) => {
  const formContext = useFormContext();

  return (
    <Controller
      control={formContext?.control}
      name={name}
      rules={{ validate: { ...(required && { present: isPresent }) } }}
      render={({ field, fieldState: { invalid } }) => {
        if (!isValidElement(children)) 
          return <></>;
        return cloneElement(children as ReactElement, {
          ...field, // includes value and onChange
          state: invalid ? "error" : undefined,
          "aria-invalid": invalid
        });
      }}
    />
  );
};

export const useWiredFormField = (name: string, component?: React.ReactElement, isRequired = false) => {
  if (!component || name === "") return <></>;

  return (
    <WiredFormField
      className={component.props.className}
      name={name}
      required={isRequired}
    >
      {component}
    </WiredFormField>
  );
};

export default WiredFormField;

export const registerWiredFormField = () =>
  registerComponent(WiredFormField, {
    name: "WiredFormField",
    displayName: "Wired FormField",
    importPath: "./src/components/custom/WiredFormField",
    isDefaultExport: true,
    props: {
      children: {
        type: "slot",
        hidePlaceholder: true,
      },
      name: {
        type: "string"
      },
      required: {
        type: "boolean",
        defaultValue: false,
        displayName: "Is Required?",
      },
    },
  });
