import { components } from "../types";
import apiClient from "../apiClient";
import { Language } from "../../context/RegionContext";

export type ObjectInfo = components["schemas"]["ObjectInfo"];
export type ObjectInfoId = components["schemas"]["ObjectInfoId"];
export type QuickAccessItem = components["schemas"]["QuickAccessItem"];
export type Location = components["schemas"]["Location"];

const BASE_PATH = "/ObjectInfo";

export const createObject = (sprache: Language, body: ObjectInfo) => {
  return apiClient.post<ObjectInfoId>(`${BASE_PATH}`, body, { params: { sprache } });
};

export const getObjectInfo = (objectId: string) => {
  return apiClient.get<ObjectInfo>(`${BASE_PATH}/${objectId}`);
};

export const getQuickAccessItems = () => {
  return apiClient.get<QuickAccessItem[]>(`${BASE_PATH}/QuickAccessItems`);
};

export const putLocation = (objectId: string, sprache: Language, location: Location) => {
  return apiClient.put<void>(`${BASE_PATH}/${objectId}/location`, location, { params: { sprache } });
}


