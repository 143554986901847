import React, { isValidElement, ReactElement, useRef } from "react";
import { DefaultReportPageSelectProps, PlasmicReportPageSelect } from "./plasmic/imbas_23_fpre/PlasmicReportPageSelect";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFormContext } from "react-hook-form";
import flattenChildren from "react-keyed-flatten-children";
import { useIdTokenClaims } from "../auth/authUtils";
import { Country, useRegionContext } from "../context/RegionContext";

export interface ReportPageSelectProps extends DefaultReportPageSelectProps {
}

function ReportPageSelect_(
  props: ReportPageSelectProps,
  ref: HTMLElementRefOf<"div">
) {
  const { setValue } = useFormContext();
  const checkboxNamesRef = useRef<string[]>([]);
  const { country } = useRegionContext();
  const allowedModules = useIdTokenClaims()?.fpre_allowedModules?.ModulInfo || [];
  const remmsModuleActive = allowedModules.some(
    ({ ModulName, Land }) => ModulName === "REMMS" && Country[Land] === country
  );

  return (
    <PlasmicReportPageSelect
      root={{ ref }}
      {...props}
      selectAllButton={{
        onClick: () => checkboxNamesRef.current.forEach((name) => setValue(name, true)),
      }}
      deselectAllButton={{
        onClick: () => checkboxNamesRef.current.forEach((name) => setValue(name, false)),
      }}
      checkboxes={{
        wrapChildren: (children) => {
          if (checkboxNamesRef.current.length === 0) {
            checkboxNamesRef.current = flattenChildren(children)
              .filter(isValidElement)
              .map((child: ReactElement) => child.props.name)
              .filter((name) => name);
          }
          return children;
        },
      }}
      formGroupSeitenauswahlNachhaltigkeitsbeurteilung={{
        isHidden: !remmsModuleActive
      }}
      
      formGroupSeitenauswahlComparables={{
        isHidden: country === Country.LI
      }}
    />
  );
}

const ReportPageSelect = React.forwardRef(ReportPageSelect_);
export default ReportPageSelect;
