import { NavigationClient } from "@azure/msal-browser";
import { NavigateFunction } from "react-router-dom";
import { NavigationOptions } from "@azure/msal-browser/dist/navigation/NavigationOptions";

export class CustomNavigationClient extends NavigationClient {
  constructor(private readonly navigate: NavigateFunction) {
    super();
    this.navigate = navigate;
  }

  public async navigateInternal(url: string, options: NavigationOptions): Promise<boolean> {
    const relativePath = url.replace(window.location.origin, "");
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}
