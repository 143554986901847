import React from "react";
import { DefaultStatusIndicatorProps, PlasmicStatusIndicator } from "./plasmic/imbas_23_fpre/PlasmicStatusIndicator";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useDataStore } from "../store/DataStoreProvider";
import { get } from "lodash";
import { getStateForRetrievedValue } from "../utils";

export interface StatusIndicatorProps extends DefaultStatusIndicatorProps {
}

function StatusIndicator_(
  { name, ...props }: StatusIndicatorProps, ref: HTMLElementRefOf<"div">
) {
  const { dataStore } = useDataStore();

  let statusIndicator: "neutral" | "success" | "warning" | "error" = "neutral";
  if (name) {
    const value = get(dataStore, name);
    statusIndicator = getStateForRetrievedValue(Number(value));
  }

  return <PlasmicStatusIndicator root={{ ref, "data-type": statusIndicator }} {...props} type={statusIndicator}/>;
}

export const StatusIndicator = React.forwardRef(StatusIndicator_);
export default StatusIndicator;
