import * as React from "react";
import FormWrapper, { FormWrapperProps } from "./FormWrapper";
import { PropsWithChildren } from "react";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import { FieldValues, useWatch } from "react-hook-form";
import { JSONSerializable } from "../../store/DataStoreProvider";
import { useAutosaveContext } from "../../store/AutosaveProvider";
import ProcessCardsProvider from "../../context/ProcessCardsContext";

const AutoSaveLayer: React.FunctionComponent<PropsWithChildren> = (props) => {
  const { children } = props;
  const { persist } = useAutosaveContext();
  const fields = useWatch();

  useUpdateEffect(() => {
    persist(fields as unknown as JSONSerializable);

    // Including `persist` in the dependency array causes an infinite loop
    // since persist is a function that changes on every render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, persist]);

  return <>{children}</>;
};

export const AutoSavingFormWrapper = <T extends FieldValues, >({
                                                                 children,
                                                                 ...props
                                                               }: PropsWithChildren<FormWrapperProps<T>>) => {
  return (
    <ProcessCardsProvider>
      <FormWrapper {...props}>
        <AutoSaveLayer>
          {children}
        </AutoSaveLayer>
      </FormWrapper>
    </ProcessCardsProvider>
  );
};
