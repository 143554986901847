import * as React from "react";
import { ReactElement } from "react";
import { 
  DefaultCountrySwitcherProps,        
  PlasmicCountrySwitcher } from "./plasmic/imbas_23_fpre/PlasmicCountrySwitcher";
import { Country, useRegionContext } from "../context/RegionContext";
import { useIdTokenClaims } from "../auth/authUtils";
import { uniq } from "lodash";
import { useTranslation } from "react-i18next";
import { useDynamicDropdownValues } from "../context/DynamicDropdownValuesContext";
import Select__Option from "./Select__Option";
import { SelectProps } from "./Select";
import { ensureGlobalVariants, hasVariant } from "@plasmicapp/react-web";
import {  useScreenVariants } from "./plasmic/imbas_23_fpre/PlasmicGlobalVariant__Screen"; 
import { useIsDarkMode } from "./plasmic/imbas_23_fpre/PlasmicGlobalVariant__IsDarkMode";


export interface CountrySwitcherProps extends DefaultCountrySwitcherProps {
}

function CountrySwitcher(props: CountrySwitcherProps) {
  const { country, setCountry } = useRegionContext();

  const allowedModules = useIdTokenClaims()?.fpre_allowedModules?.ModulInfo;
  const allowedModuleCountries = allowedModules
    ? uniq(allowedModules.map(({ Land }) => Country[Land]))
    : Object.values(Country); // Fallback to all countries if claims are not available
    
  const globalVariants = ensureGlobalVariants({
    screen: useScreenVariants(),
    isDarkMode: useIsDarkMode()
  });
  const dropDownName = hasVariant(globalVariants, "screen", "mobile")
  ? "LAND_short"
  : "LAND"

  const { t } = useTranslation();
  const dynamicDropdownItems = useDynamicDropdownValues(dropDownName, (item, idx) => (
    <Select__Option key={idx} value={String(item.Value)}>
      {t(item.Name, { ns: "plasmic" })}
    </Select__Option>
    ))?.filter((selectOption: ReactElement) => 
      allowedModuleCountries.includes(+selectOption.props.value));

  const handleChange = (value: SelectProps["value"]): void => {
    if (typeof value === "number" && Country[value] !== undefined) {
      setCountry(value);
    }
  };

  return (
    <PlasmicCountrySwitcher
      onChange={handleChange}
      value={country}
      {...props}
      select={{
        render: (props, Component) => (
          <Component {...props} isDisabled={dynamicDropdownItems && dynamicDropdownItems.length <= 1}>
            { dynamicDropdownItems }
          </Component>
        ),
      }}
    />
  );

}

export default CountrySwitcher;