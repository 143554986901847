import { components } from "../types";
import apiClient from "../apiClient";
import { Language } from "../../context/RegionContext";

export type MarktwertInput = components["schemas"]["ImbasMarktwertInput"];
export type MarktwertMod = components["schemas"]["MarktwertMod"];
export type MarktwertCalc = components["schemas"]["ImbasMarktwertCalc"];

const BASE_PATH = (objectId: string) => `/Marktwert/${objectId}`;

export const getMarktwertInput = (objectId: string) => {
  return apiClient.get<MarktwertInput>(`${BASE_PATH(objectId)}/MarktwertInput`);
};

export const getMarktwertMod = (objectId: string, sprache: Language) => {
  return apiClient.get<MarktwertMod>(`${BASE_PATH(objectId)}/MarktwertMod`, { params: { sprache } });
};

export const getMarktwertCalc = (objectId: string, sprache: Language) => {
  return apiClient.get<MarktwertCalc>(`${BASE_PATH(objectId)}/MarktwertCalc`, { params: { sprache } });
};

export const saveMarktwertInputDraft = (objectId: string, sprache: Language, body: MarktwertInput) => {
  return apiClient.put<MarktwertCalc>(`${BASE_PATH(objectId)}/MarktwertInputDraft`, body, { params: { sprache } });
};

export const saveMarktwertInput = (objectId: string, sprache: Language, body: MarktwertInput) => {
  return apiClient.put<void>(`${BASE_PATH(objectId)}/MarktwertInput`, body, { params: { sprache } });
};
