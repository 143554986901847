import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

const ModalContext = createContext<{
    showComparablesModal : boolean | undefined,
    setShowComparablesModal: Dispatch<SetStateAction<boolean>>,
  }>({
    showComparablesModal: undefined,
    setShowComparablesModal: () => undefined
  });

export const useModalContext = () => useContext(ModalContext);

const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [showComparablesModal, setShowComparablesModal] = useState<boolean>(false);

    return (
      <ModalContext.Provider value={{ showComparablesModal, setShowComparablesModal }}>
        {children}
      </ModalContext.Provider>
    );
  };
  
  export default ModalProvider;