// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SunIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function SunIcon(props: SunIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M7 0h2v2H7zm5.88 1.637l1.414 1.415-1.415 1.413-1.414-1.414zM14 7h2v2h-2zm-1.05 7.433l-1.415-1.414 1.414-1.414 1.415 1.413zM7 14h2v2H7zm-4.02.363L1.566 12.95l1.415-1.414 1.414 1.415zM0 7h2v2H0zm3.05-5.293L4.465 3.12 3.05 4.535 1.636 3.121z"
          }
        ></path>

        <path
          fill={"currentColor"}
          d={"M8 4C5.8 4 4 5.8 4 8s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"}
        ></path>
      </g>
    </svg>
  );
}

export default SunIcon;
/* prettier-ignore-end */
