import { components } from "../types";
import apiClient from "../apiClient";
import { Language } from "../../context/RegionContext";

export type EwgInput = components["schemas"]["ImbasEwgInput"];
export type ErfassungMod = components["schemas"]["ErfassungMod"];

const BASE_PATH = (objectId: string) => `/Ewg/${objectId}`;

export const getEwgInput = (objectId: string) => {
  return apiClient.get<EwgInput>(`${BASE_PATH(objectId)}/EwgInput`);
};

export const getEwgMod = (objectId: string, sprache: Language) => {
  return apiClient.get<ErfassungMod>(`${BASE_PATH(objectId)}/EwgMod`, { params: { sprache } });
};

export const saveEwgInputDraft = (objectId: string, sprache: Language, body: EwgInput) => {
  return apiClient.put<ErfassungMod>(`${BASE_PATH(objectId)}/EwgInputDraft`, body, { params: { sprache } });
};

export const saveEwgInput = (objectId: string, body: EwgInput) => {
  return apiClient.put<void>(`${BASE_PATH(objectId)}/EwgInput`, body);
};
