import React, { createElement, useCallback, useMemo, useState } from "react";
import {
  DefaultEwg010ErfassungFullProps,
  PlasmicEwg010ErfassungFull,
  PlasmicEwg010ErfassungFull__OverridesType
} from "./plasmic/imbas_23_fpre/PlasmicEwg010ErfassungFull";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { FormWrapperProps } from "./custom/FormWrapper";
import { useNavigate } from "react-router-dom";
import { useRegionContext } from "../context/RegionContext";
import { EWGParameters, withCurrentEWGParameters } from "../withCurrentEWGParameters";
import { flow } from "lodash";
import withInitialResourceLoad from "../store/withInitialResourceLoad";
import { DataStoreName, useDataStore, withDataStoreProvider } from "../store/DataStoreProvider";
import { withAutosaveProvider } from "../store/AutosaveProvider";
import { EwgInput, getEwgInput, getEwgMod, saveEwgInput, saveEwgInputDraft } from "../api/endpoints/ewg";
import { AutoSavingFormWrapper } from "./custom/AutoSavingFormWrapper";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { isErrorResponse, processErrorResponse } from "../api/apiClient";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useModalContext } from "../context/ModalContext";
import { VERBREFF, VERBRTRAE } from "./DynamicEnergyConsumptionListItem";
import { useTranslation } from "react-i18next";

type ApiErrors = FormWrapperProps<EwgInput>["apiErrors"];

export interface Ewg010ErfassungFullProps extends DefaultEwg010ErfassungFullProps, EWGParameters {
}

function Ewg010ErfassungFull_(
  { objectId, ...props }: Ewg010ErfassungFullProps,
  ref: HTMLElementRefOf<"div">
) {
  const { setShowComparablesModal } = useModalContext();
  const { dataStore: { ewgInput } } = useDataStore();
  const [apiErrors, setApiErrors] = useState<ApiErrors>();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(Boolean(false));
  const { i18n } = useTranslation();
  const { location } = useRegionContext();

  if(location?.WGS84Lat == null || location?.WGS84Lng == null)
  {
    toast.error(t("toast.missing_coordinates"), { toastId: "validation_error" });
  }

  const onSubmit = useCallback(async (data: EwgInput) => {
    try {
      setIsSubmitting(true);
      await saveEwgInput(objectId, data);
      navigate(ApplicationRoutes.getPath("ewg020Marktwert", { objectId }));
    } catch (err) {
      setIsSubmitting(false);
      if (isErrorResponse(err)) {
        setApiErrors(processErrorResponse(err.response.data, i18n.language));
        toast.error(t("toast.validation_error"), { toastId: "validation_error" });
      }
    } finally {
      setShowComparablesModal(false);
    }
  }, [navigate, objectId]);

  const specificComponentAdjustments: PlasmicEwg010ErfassungFull__OverridesType = {
    submitButton: {
      isLoading: isSubmitting,
    }
  };

  return (
    <AutoSavingFormWrapper
      className={""}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      defaultValues={ewgInput}
    >
      <PlasmicEwg010ErfassungFull
        root={{ ref }}
        {...specificComponentAdjustments}
        {...props}
        dynamicEnergyConsumptionList={{
          energyConsumptionFactory: (value: number) => {
            const newEnergyConsumption: VERBREFF = {
              VERBRTRAE: value as VERBRTRAE,
              VERBR: null,
              VERBREINH: undefined
            };
            return newEnergyConsumption;
          }
        }}
      />
    </AutoSavingFormWrapper>
  );
}

const Ewg010ErfassungFull = React.forwardRef(Ewg010ErfassungFull_);

export default withCurrentEWGParameters((props) => {
  const { objectId } = props;
  const { language } = useRegionContext();

  const composedComponent = useMemo(() => {
    return flow([
      withInitialResourceLoad([
        { apiCall: () => getEwgInput(objectId), dataStoreName: DataStoreName.ewgInput },
        { apiCall: () => getEwgMod(objectId, language), dataStoreName: DataStoreName.erfassungMod },
      ]),
      withAutosaveProvider({
        apiCall: (data) => saveEwgInputDraft(objectId, language, data as EwgInput),
        autosaveDataStore: DataStoreName.erfassungMod,
        userInputDataStore: DataStoreName.ewgInput,
      }),
      withDataStoreProvider({}),
    ])(Ewg010ErfassungFull);
  }, [objectId, language]);

  return createElement(composedComponent, props);
});
