import React, { useEffect, useId } from "react";
import { DefaultProcessCardProps, PlasmicProcessCard } from "./plasmic/imbas_23_fpre/PlasmicProcessCard";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useProcessCardsCtx } from "../context/ProcessCardsContext";
import { RenderControlContext } from "../utils";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface ProcessCardProps extends DefaultProcessCardProps {
}

function ProcessCard_(props: ProcessCardProps, ref: HTMLElementRefOf<"div">) {
  const location = useLocation();
  const { processCardContainer, dispatch } = useProcessCardsCtx();
  const { i18n } = useTranslation();
  const generatedId = useId();
  const id = props.id ?? generatedId;

  const { isClosed, isOptionalClosed, numbering, title, isHidden, group } =
  processCardContainer.processCards.find((card) => card.id === id) ?? {
      isOptionalClosed: !(id === location.state?.cardId && location.state?.open),
      isClosed: Boolean(props.isClosed),
      numbering: Number(props.numbering),
      title: String(props.title),
      isHidden: Boolean(props.isHidden),
      group: String(props.navGroup)
    };

  useEffect(() => {
    dispatch({
      type: "register_card",
      payload: { id, isClosed, isOptionalClosed, numbering, title, isHidden, group, cardGroup: id.split('/')[0] }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  return (
    <RenderControlContext.Provider value={{ hidden: isOptionalClosed }}>
      <PlasmicProcessCard
        root={{ ref }}
        {...props}
        numbering={numbering}
        toggleButton={{
          onClick: () => dispatch({
            type: "toggle_card",
            payload: { id, isClosed: !isClosed }
          }),
        }}
        toggleOptionalFieldsButton={{
          onClick: () => dispatch({
            type: "toggle_card",
            payload: { id, isOptionalClosed: !isOptionalClosed }
          })
        }}
        optionalContentVisible={Boolean(props.hasOptionalContent) && !isOptionalClosed}
        isClosed={isClosed}
        cardHelpLink={{
          onClick: () => {
            window.open(process.env.REACT_APP_MANUALS_URL + `${props.helperLink}_${i18n.resolvedLanguage}.pdf`
              , "_blank");
          },
        }}
      />
    </RenderControlContext.Provider>
  )
}

const ProcessCard = React.forwardRef(ProcessCard_);
export default ProcessCard;
