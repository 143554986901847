// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type MountainImageIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function MountainImageIcon(props: MountainImageIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          fill={"currentColor"}
          d={
            "M15.895 13.553l-4-8a.999.999 0 00-1.675-.178L6.792 9.659 4.555 8.168a1 1 0 00-1.412.317l-3 5A1.001 1.001 0 001 15h14a1.001 1.001 0 00.895-1.447z"
          }
        ></path>

        <circle cx={"6"} cy={"3"} r={"2"}></circle>
      </g>
    </svg>
  );
}

export default MountainImageIcon;
/* prettier-ignore-end */
