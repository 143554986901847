import { Language } from "../../context/RegionContext";
import apiClient from "../apiClient";
import { components } from "../types";

export type REMMSData  = components["schemas"]["REMMSData"];

const BASE_PATH = "/Remms";

export const getREMMS = (objectId: string, sprache: Language) => {
  return apiClient.get<REMMSData>(`${BASE_PATH}/${objectId}`, { params: { sprache }, skipErrorRedirection: true });
};
export const getReport = async (id: string | null | undefined) => {
  return await apiClient.get<Blob>(`${BASE_PATH}/report/${id}`, {
    params: {  },
    responseType: "blob",
    headers: {
      "Accept": "application/octet-stream",
    },
  });
};
