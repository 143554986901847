import * as React from "react";
import { Slider as MuiSlider } from "@mui/material";
import { registerComponent } from "@plasmicapp/host";
import Thumb from "./slider_components/Thumb";
import Rail from "./slider_components/Rail";
import Track from "./slider_components/Track";
import SliderThumb from "../SliderThumb";
import SliderTrack from "../SliderTrack";
import { clamp } from "lodash";

// Aliased type to group thumb position values and deltas
type SlideableNumber = number;

export type SliderBaseProps = Partial<{
  className: string;
  value?: SlideableNumber;
  algoValue: SlideableNumber;
  min: SlideableNumber;
  max: SlideableNumber;
  step?: SlideableNumber;
  marks: { value: SlideableNumber; label: string }[];
  thumb: React.ReactElement;
  sliderRail: React.ReactElement;
  sliderTrack: React.ReactElement;
  onChange?: (value: SlideableNumber) => void;
}>;

const SliderBase = (props: SliderBaseProps) => {
  const {
    className,
    value = undefined,
    min = 1,
    max = 5,
    step = 0.1,
    marks = [],
    algoValue,
    thumb = <SliderThumb />,
    sliderRail = <SliderTrack />,
    sliderTrack = <SliderTrack filled />,
    onChange,
  } = props;

  const algoValuePercentage = algoValue && (algoValue - min) / (max - min);
  const defaultValue = (min + max) / 2;

  return (
    <MuiSlider
      className={className}
      value={value ? clamp(value, min, max) : defaultValue }
      components={{
        Thumb: (props) => <Thumb {...props} element={thumb} />,
        Track: (props) => <Track {...props} element={sliderTrack} />,
        Rail: Rail(sliderRail, algoValuePercentage),
      }}
      min={min}
      max={max}
      step={step}
      onChange={(event, value) => onChange && onChange(value as SlideableNumber)}
      marks={Array.isArray(marks) && marks.length > 0 ? marks : undefined}
      color="primary"
      data-testid="slider-base"
    />
  );
};

export default SliderBase;

export function registerSliderComponent() {
  registerComponent(SliderBase, {
    name: "SliderBase",
    importPath: "./src/components/custom/SliderBase",
    isDefaultExport: true,
    displayName: "SliderBase",
    props: {
      min: {
        type: "number",
        displayName: "Minimum",
        defaultValue: 1,
      },
      max: {
        type: "number",
        displayName: "Maximum",
        defaultValue: 5,
      },
      algoValue: {
        type: "number",
        displayName: "Algo Value",
      },
      marks: {
        type: "object",
        displayName: "Marks",
        defaultValue: [],
        description: "Object in the format of [{value: 10, label: '10%'}, {value: 50, label: '50%'}, {...}]",
      },
      step: {
        type: "number",
        displayName: "Step",
        defaultValue: 0.1,
      }
    },
  });
}
