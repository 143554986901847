import React, { useCallback, useMemo } from "react";
import {
  DefaultDynamicImpressionListProps,
  PlasmicDynamicImpressionList
} from "./plasmic/imbas_23_fpre/PlasmicDynamicImpressionList";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFieldArray, useFormContext } from "react-hook-form";
import { components } from "../api/types";
import DynamicImpressionListItem from "./DynamicImpressionListItem";
import { useParams } from "react-router-dom";
import { deleteReportFile } from "../api/endpoints/report";

export const MAX_IMPRESSIONS = 18;

export type Impressionen = components["schemas"]["Impressionen"];

export interface DynamicImpressionListProps
  extends DefaultDynamicImpressionListProps {
}

function DynamicImpressionList_(
  props: DynamicImpressionListProps,
  ref: HTMLElementRefOf<"div">
) {
  const { objectId = "plasmic" } = useParams();
  const name = props.name || "Impressionen";
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const handleDelete = useCallback((index: number, attachmentFieldName: string) => {
    const fileUid = getValues(attachmentFieldName)?.Identifier;
    if (!fileUid) {
      remove(index)
      return;
    }

    deleteReportFile(objectId, fileUid)
      .then(() => remove(index))
      .catch(console.error);
  }, [getValues, remove]);

  const items = useMemo(() => fields.map((field, index) => {
    const imageTitleName = `${name}.[${index}].ImpressionenTitel`;
    const imageFileName = `${name}.[${index}].ReportFileInfo`;

    return (
      <DynamicImpressionListItem
        key={field.id}
        index={index}
        imageTitleName={imageTitleName}
        imageFileName={imageFileName}
        onDelete={() => handleDelete(index, imageFileName)}
      />
    );
  }), [fields, name, handleDelete]);

  return (
    <PlasmicDynamicImpressionList
      root={{ ref }}
      {...props}
      addButton={{
        onClick: () => {
          append({ ImpressionenTitel: null, ReportFileInfo: { Identifier: "", OrigFileName: null } } as Impressionen)
        }
      }}
      items={items}
      itemsCounter={{
        counterText: `${fields.length}/${MAX_IMPRESSIONS}`,
        maxItemsReached: fields.length > MAX_IMPRESSIONS,
      }}
      isEmpty={fields.length === 0}
    />
  );
}

const DynamicImpressionList = React.forwardRef(DynamicImpressionList_);
export default DynamicImpressionList;
