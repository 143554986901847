import React, { useCallback, useState } from "react";
import {
  DefaultReportDescriptionSuggestionGroupProps,
  PlasmicReportDescriptionSuggestionGroup
} from "./plasmic/imbas_23_fpre/PlasmicReportDescriptionSuggestionGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFormContext } from "react-hook-form";
import { t } from "i18next";
import { DataStoreName, useDataStore } from "../store/DataStoreProvider";
import { get } from "lodash";
import { useParams } from "react-router-dom";
import { useRegionContext } from "../context/RegionContext";
import { getReportMod } from "../api/endpoints/report";
import { isErrorResponse } from "../api/apiClient";
import { toast } from "react-toastify";
import ToastConfirmOverwrite from "./ToastConfirmOverwrite";

export interface ReportDescriptionSuggestionGroupProps extends DefaultReportDescriptionSuggestionGroupProps {
}

function ReportDescriptionSuggestionGroup_(
  props: ReportDescriptionSuggestionGroupProps,
  ref: HTMLElementRefOf<"div">
) {
  const { setValue } = useFormContext();
  const { dataStore, setDataStore } = useDataStore();
  const { objectId = "plasmic" } = useParams();  
  const { language } = useRegionContext();
  const [isLoading, setIsLoading] = useState<boolean>(Boolean(false));
  const options = { toastId: "toastMessage" };
  const { dataStore: { reportInput } } = useDataStore();
  const autoTextValue = props.autoTextName && reportInput 
    ? get(reportInput, props.autoTextName) ?? false
    : false;

  const handleAlgoButtonClick = useCallback(() => {

    toast.warning(<ToastConfirmOverwrite
      yesButtonClick={LoadText}
      noButtonClick={() =>toast.dismiss()} />, options   
    );

    function LoadText() {
      if (props.name && props.algoName) {
        try {
          setIsLoading(true);
          getReportMod(objectId, language)
            .then(({ data }) => {
              setDataStore((prevState) => ({
                ...prevState,
                ...({ [DataStoreName.reportMod]: data }),
              }));
              setValue(props.name!, get(data, props.algoName!, ""));
              setIsLoading(false);
            });            
        } catch (err) {
          setIsLoading(false);
          if (isErrorResponse(err)) {
            toast.error(t("toast.network_error"), { toastId: "network-error" });
          }
        }
      } else {
        console.error("Missing name or algoName prop");
      }
    }
  }, [dataStore, props.name, props.algoName, setValue]);

  return (
    <>
      <PlasmicReportDescriptionSuggestionGroup
        root={{ ref }}
        {...props}
        algoButton={{
          onClick: handleAlgoButtonClick,
          isLoading: isLoading
        }} 
        isAutoText={autoTextValue}
      />
    </>
  );
}

const ReportDescriptionSuggestionGroup = React.forwardRef(
  ReportDescriptionSuggestionGroup_
);
export default ReportDescriptionSuggestionGroup;
