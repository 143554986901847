import * as React from "react";
import {
  PlasmicSwitch,
  DefaultSwitchProps
} from "./plasmic/imbas_23_fpre/PlasmicSwitch";
import { SwitchRef } from "@plasmicapp/react-web";

interface SwitchProps extends DefaultSwitchProps {
  value: string | undefined;
}

function Switch_(
  { value, ...props } : Partial<SwitchProps>, 
  ref: SwitchRef) {

  const { plasmicProps } = PlasmicSwitch.useBehavior<DefaultSwitchProps>({
    ...props,
    isChecked: value !== null && value !== undefined ? Boolean(value) : undefined,
  }, ref);

  return (
    <PlasmicSwitch 
      {...plasmicProps}  
    />);
}

const Switch = React.forwardRef(Switch_);

export default Object.assign(Switch, {
  __plumeType: "switch"
});