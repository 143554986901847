import React, { createElement, ReactElement, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ApplicationRoutes, RouteConfig } from "./ApplicationRoutes";
import { RegionProvider } from "./context/RegionContext";
import { ObjectInfoProvider } from "./context/ObjectInfoContext";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { CustomNavigationClient } from "./auth/CustomNavigationClient";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest, msalEventCallback } from "./auth/msal";
import { AxiosInterceptors } from "./api/apiClient";
import ErrorBoundary from "./ErrorBoundary";
import { ImbasLegacyProvider } from "./context/ImbasLegacyContext";
import ModalProvider from "./context/ModalContext";
import GoogleMapProvider from "./context/GoogleMapContext";
import DynamicDropdownValuesProvider from "./context/DynamicDropdownValuesContext";
import DebugProvider from "./context/DebugContext";

const App: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  instance.setNavigationClient(new CustomNavigationClient(navigate));

  useEffect(() => {
    const callbackId = instance.addEventCallback(msalEventCallback);
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    }
  }, []);

  return (
    <>
      <AxiosInterceptors />
      <ToastContainer
        position={toast.POSITION.BOTTOM_LEFT}
        autoClose={5000}
        draggablePercent={60}
        limit={3}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <RegionProvider>
        <DynamicDropdownValuesProvider>
          <ObjectInfoProvider>
            <ImbasLegacyProvider>
              <DebugProvider>
                <ModalProvider>
                  <GoogleMapProvider>
                    <Pages />
                  </GoogleMapProvider>
                </ModalProvider>
              </DebugProvider>
            </ImbasLegacyProvider>
          </ObjectInfoProvider>
        </DynamicDropdownValuesProvider>
      </RegionProvider>
    </>
  );
};

export const Pages: React.FC = () => {
  return (
    <Routes>
      {ApplicationRoutes.map(renderRoute)}
      <Route path="*" element={<Navigate to={ApplicationRoutes.getPath("root")} />} />
    </Routes>
  );
};

const renderRoute = ({ path, component, isPublic }: RouteConfig): ReactElement => {
  const routeElement = getRouteElement(path, component);
  return (
    <Route key={path} path={path} element={
      isPublic
        ? routeElement
        : (
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            errorComponent={({ error }) => <div>{`An Error Occurred: ${error}`}</div>}
            loadingComponent={() => <div>You are being redirected...</div>}
          >
            {routeElement}
          </MsalAuthenticationTemplate>
        )
    } />
  );
};

const getRouteElement = (path: string, component: React.ComponentType): ReactElement => {
  const routeElement = createElement(component);

  // Wrap all routes except the error page itself in an error boundary to catch uncaught errors
  return path === ApplicationRoutes.getPath("error") ? routeElement : (
    <ErrorBoundary>
      {routeElement}
    </ErrorBoundary>
  );
};

export default App;
