import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react"
import { LocationDetail } from "../api/endpoints/location";
import { useRegionContext } from "../context/RegionContext";

const GoogleMapContext = createContext<{
    mapLocation: LocationDetail | undefined,
    isCustomAddress: boolean,
    centerOnLocationChange: boolean,
    setMapLocation: Dispatch<SetStateAction<LocationDetail | undefined>>,
    setIsCustomAddress: Dispatch<SetStateAction<boolean>>,
    setCenterOnLocationChange: Dispatch<SetStateAction<boolean>>
}>({
    mapLocation: undefined,
    isCustomAddress: false,
    centerOnLocationChange: false,
    setMapLocation: () => undefined,
    setIsCustomAddress: () => undefined,
    setCenterOnLocationChange: () => undefined
});

export const useGoogleMapContext = () => useContext(GoogleMapContext);

const GoogleMapProvider: React.FC<PropsWithChildren> = ({ children }) => {

    const { location } = useRegionContext();
    const [mapLocation, setMapLocation] = useState<LocationDetail | undefined>(location);
    const [isCustomAddress, setIsCustomAddress] = useState<boolean>(false);
    const [centerOnLocationChange, setCenterOnLocationChange] = useState<boolean>(false);

    useEffect(() => {
        setMapLocation(location);
    }, [location]);

    return (
        <GoogleMapContext.Provider value={{
            mapLocation,
            isCustomAddress,
            centerOnLocationChange,
            setMapLocation,
            setIsCustomAddress,
            setCenterOnLocationChange
        }}>
            {children}
        </GoogleMapContext.Provider>
    );
};

export default GoogleMapProvider;