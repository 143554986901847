// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type PinAreaIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function PinAreaIcon(props: PinAreaIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M18.352 16.539a39.05 39.05 0 01-1.387 1.795c2.968.429 4.746 1.175 5.021 1.665-.404.722-4.061 2-9.986 2-6.043 0-9.727-1.33-10.006-1.958.191-.489 1.984-1.267 5.039-1.708a39.765 39.765 0 01-1.386-1.795C2.601 17.095 0 18.147 0 20c0 3.158 7.543 4 12 4s12-.842 12-4c0-1.853-2.601-2.905-5.648-3.461z"
          }
        ></path>

        <path
          fill={"currentColor"}
          d={
            "M12 0C8.067 0 4 2.992 4 8c0 4.6 6.544 11.327 7.29 12.08a1 1 0 001.42 0C13.456 19.327 20 12.6 20 8c0-5.008-4.067-8-8-8zm0 10a2 2 0 11-.001-3.999A2 2 0 0112 10z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default PinAreaIcon;
/* prettier-ignore-end */
