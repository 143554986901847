// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowsFullscreenIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowsFullscreenIcon(props: ArrowsFullscreenIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M6.707 9.293a1 1 0 00-1.414 0l-2.147 2.146L.854 9.146a.506.506 0 00-.545-.108A.5.5 0 000 9.5v6a.5.5 0 00.5.5h6a.5.5 0 00.354-.854l-2.293-2.292 2.146-2.147a1 1 0 000-1.414z"
          }
        ></path>

        <path
          d={
            "M15.5 0h-6a.5.5 0 00-.354.854l2.293 2.292-2.146 2.147a1 1 0 101.414 1.414l2.147-2.146 2.292 2.293A.5.5 0 0015.5 7a.512.512 0 00.191-.038A.5.5 0 0016 6.5v-6a.5.5 0 00-.5-.5z"
          }
          fill={"currentColor"}
        ></path>
      </g>
    </svg>
  );
}

export default ArrowsFullscreenIcon;
/* prettier-ignore-end */
