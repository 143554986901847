import { components } from "../types";
import apiClient from "../apiClient";
import { AxiosProgressEvent, CancelToken } from "axios";
import { Language } from "../../context/RegionContext";

export type ReportInput = components["schemas"]["ImbasReportInput"];
export type ReportMod = components["schemas"]["ReportMod"];
export type FileOutput = components["schemas"]["FileOutput"];
export type ReportFileInfo = components["schemas"]["ReportFileInfo"];
export type UploadOutput = components["schemas"]["UploadOutput"];

const BASE_PATH = (objectId: string) => `/Report/${objectId}`;

export const getReportInput = (objectId: string, sprache: Language) => {
  return apiClient.get<ReportInput>(`${BASE_PATH(objectId)}/ReportInput`, { params: { sprache } });
};

export const getReportMod = (objectId: string, sprache: Language) => {
  return apiClient.get<ReportMod>(`${BASE_PATH(objectId)}/ReportMod`, { params: { sprache } });
};

export const saveReportInputDraft = (objectId: string, body: ReportInput, sprache: Language) => {
  return apiClient.put<void>(`${BASE_PATH(objectId)}/ReportInputDraft`, body, { params: { sprache } });
};

export const saveReportInput = (objectId: string, body: ReportInput, sprache: Language) => {
  return apiClient.put<void>(`${BASE_PATH(objectId)}/ReportInput`, body, { params: { sprache } });
};

export const getReportBlob = async (objectId: string, sprache: Language, isShortReport = false, isDebug = false) => {
  return await apiClient.get<Blob>(`${BASE_PATH(objectId)}/${isShortReport ? "ShortReport" : "Report"}`, {
    params: { sprache },
    responseType: "blob",
    headers: {
      "Accept": "application/octet-stream",
      "x-debug-output":  `${isDebug}`
    },
  });
};

export const getReportFilePreview = (objectId: string, fileUid: string) => {
  return apiClient.get<FileOutput>(`${BASE_PATH(objectId)}/File/${fileUid}/preview`);
};

export const uploadReportFile = (
  objectId: string,
  body: FormData,
  cancelToken?: CancelToken,
  onUploadProgress?: (event: AxiosProgressEvent) => void
) => {
  return apiClient.post<UploadOutput>(`${BASE_PATH(objectId)}/File`, body, {
    cancelToken,
    onUploadProgress,
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
};

export const deleteReportFile = (objectId: string, fileUid: string | null | undefined) => {
  return apiClient.delete<void>(`${BASE_PATH(objectId)}/File/${fileUid}`);
};
